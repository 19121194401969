import React from "react";
import PropTypes from "prop-types";
import style from "./Template.module.sass";
import logo from "../../../img/logo.svg";

const Template = ({ children, title, description }) => (
  <div className={style.wrapper}>
    <div className={style.container}>
      <img className={style.container__logo} src={logo} alt="logo" />
      {title && <span className={style.container__title}>{title}</span>}
      {description && (
        <span className={style.container__description}>{description}</span>
      )}
      {children}
    </div>
  </div>
);

Template.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string
};

export default Template;
