import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import logo from "img/logo-horizontal-white.svg";
import { HeaderPage, LoadingPage } from "../../../components";
import { testUrl } from "../../../actions/auth";
import style from "./Signup.module.sass";
import Url from "./Url";
import Form from "./Form";

const Signup = ({ dispatch, location, history, auth }) => {
  const [loading, setLoading] = useState(false);
  // get url params in url
  const params = new URLSearchParams(location.search);
  const url = params.get("url") || "";
  // remove all slash at the end of the path and check if equals to "/signup"
  const isPathSignup =
    location.pathname.replace(/^(.+?)\/*?$/, "$1") === "/signup";

  // redirect if login
  useEffect(() => {
    if (auth && auth.jsonWebToken) history.push("/");
  });

  useEffect(() => {
    // scroll to top on step changes
    document.querySelector("#signup-container").scrollIntoView();

    // /signup?url=www.google.com --> /signup/url?url=www.google.com
    if (url && isPathSignup) history.push(`/signup/url?url=${url.trim()}`);
    // /signup --> /signup/url
    else if (isPathSignup) history.push("/signup/url");
  }, [location]);

  // if user reload (with path other than /signup/url), recheck if url is still pingable
  useEffect(() => {
    // remove all slash at the end of the path and check if equals to "/signup/url"
    const isPathSignupUrl =
      location.pathname.replace(/^(.+?)\/*?$/, "$1") === "/signup/url";

    if (!isPathSignup && !isPathSignupUrl && url) {
      setLoading(true);
      dispatch(testUrl(url.trim()))
        .then(({ res }) => {
          if (!res) history.push("/signup/url");
          setLoading(false);
        })
        .catch(() => {
          history.push("/signup/url");
          setLoading(false);
        });
    }
  }, []);

  if (loading)
    return (
      <div className={style.loading} id="signup-container">
        <LoadingPage />
      </div>
    );
  return (
    <div className={style.container} id="signup-container">
      <HeaderPage
        type="sky"
        title="Inscrivez-vous pour propulser votre trafic !"
      />
      <div className={style.container__logo}>
        <img src={logo} alt="logo" />
      </div>
      <div className={style.free}>
        Inscription et utilisation <b>gratuites</b> !
      </div>
      <Route path="/signup/url" component={Url} />
      <Route path="/signup/form" component={Form} />
    </div>
  );
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps)(Signup);
