import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select, { components } from "react-select";
import "./styles.sass";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styles from "./SelectWebsites.module.sass";
import { setCurrentWebsite } from "../../../../actions/websites";
import Modal from "../../../Modal";
import NewAuditModal from "./NewAuditModal";
import Button from "../../../Button";
import SelectWebsitesContext from "./SelectWebsitesContext";

const Menu = props => {
  const { children, selectProps } = props;
  const { newAuditModal } = selectProps;

  return (
    <components.Menu {...props}>
      {children}
      <div className={styles.menu__top}>
        <Button
          cta
          type="add"
          onClick={() => newAuditModal.current.openModal()}
        >
          <FormattedMessage id="sidebar.dropdown.newWebsite" />
        </Button>
      </div>
    </components.Menu>
  );
};

const SelectWebsites = ({ websites, dispatch, history }) => {
  const { current: currentWebsite } = websites;
  const newAuditModal = useRef(null);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const { list } = websites;

    const formatedOptions = list.map(website => ({
      ...website,
      value: website.id,
      label: website.domain
      // isDisabled: !user.rights.audit.accessPreviousAudits
    }));

    setOptions(formatedOptions.reverse());

    // set value
    const defaultValue = formatedOptions.find(
      option => option.value === currentWebsite.id
    );
    setValue(defaultValue);
  }, [currentWebsite]);

  // adds new value to options array and selects it
  const addValueToDropdown = website => {
    const newWebsite = {
      ...website,
      value: website.id,
      label: website.domain
    };
    // push new element in first index of array
    const newOptions = [newWebsite].concat(options);

    setOptions(newOptions);
    setValue(newWebsite);
  };

  if (options.length)
    return (
      <SelectWebsitesContext.Provider value={{ addValueToDropdown }}>
        <Select
          noOptionsMessage={() => "Aucun résultat"}
          // defaultValue={options.find(
          //   option => option.value === currentWebsite.id
          // )}
          onChange={async website => {
            setValue(website);
            await dispatch(setCurrentWebsite(website));
            history.push("/");
          }}
          className="select-websites-container"
          classNamePrefix="select-websites"
          options={options}
          isSearchable={false}
          components={{ Menu }}
          newAuditModal={newAuditModal} // pass in props (can be found in selectProps) so modal can work correctly
          value={value} // manually handle value
        />
        <Modal ref={newAuditModal}>
          <NewAuditModal modal={newAuditModal} />
        </Modal>
      </SelectWebsitesContext.Provider>
    );

  return null;
};

const mapStateToProps = state => ({
  websites: state.websites
});

export default withRouter(connect(mapStateToProps)(SelectWebsites));
