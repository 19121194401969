import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Configuration.module.scss";
import { Table } from "../../../components";
import { urlWithoutProtocol } from "../../../utils";
import CampaignConfigurationModal from "./Modals/CampaignConfigurationModal";
import { getNetlinkingConfigurations } from "../../../actions/netlinking";
import NetlinkingContext from "./NetlinkingContext";
import useValidationModal from "./Modals/useValidationModal";

interface KeywordsProps {
  keywords: string[];
}

const Keywords: FC<KeywordsProps> = ({ keywords }) => (
  <div className={styles.keywords}>
    {keywords.map((keyword, i) => (
      <span className={styles.keywords__keyword} key={i}>
        {keyword}
      </span>
    ))}
  </div>
);

interface UrlProps {
  url: string;
}

const Url: FC<UrlProps> = ({ url }) => (
  <a
    className={styles.url}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    {urlWithoutProtocol(url)}
  </a>
);
const Configuration: FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch: any = useDispatch();
  const { currentCampaign }: any = useContext(NetlinkingContext);
  const {
    netlinking: { configurations },
    loading: { GET_NETLINKING_CONFIGURATIONS }
  } = useSelector((state: any) => state);
  const [
    campaignConfigurationWithValidationModal,
    campaignConfigurationModal
  ] = useValidationModal(CampaignConfigurationModal, {
    currentCampaign,
    configuration: configurations && configurations[0]
  });

  useEffect(() => {
    dispatch(getNetlinkingConfigurations(currentCampaign.id)).then(() => {
      setLoading(false);
    });
  }, [currentCampaign]);

  useEffect(() => {
    if (configurations) {
      setData(
        configurations.map(config => ({
          targetUrl: <Url url={config.targetUrl} />,
          ratio: `${(config.ratio * 100).toFixed(0)} %`,
          targetKeywords: (
            <Keywords keywords={config.targetKeywords.split(/;|,|\\n/)} />
          )
        }))
      );
    }
  }, [configurations]);

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <h2>Configuration de la campagne</h2>
        <button
          onClick={() => {
            campaignConfigurationModal.current.openModal();
          }}
        >
          Modifier la campagne
        </button>
      </div>
      <div className={styles.table}>
        <Table
          loading={loading || GET_NETLINKING_CONFIGURATIONS}
          columns={[
            { Header: "URL", accessor: "targetUrl" },
            { Header: "Répartition des articles", accessor: "ratio" },
            { Header: "Mots-clés", accessor: "targetKeywords" }
          ]}
          data={data}
          noDataText="Aucune configuration pour le moment"
        />
      </div>
      {campaignConfigurationWithValidationModal}
    </section>
  );
};

export default Configuration;
