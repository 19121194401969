import React from "react";
import { connect } from "react-redux";
// import numbro from "numbro";
import AddToCartButton from "../_addToCartButton";
import style from "./Price.module.sass";

// const Price = ({ module }) => (
const Price = () => (
  <div className={style.wrapper}>
    {/* TODO : Remettre s'il y a le prix */}

    {/* <span className={style.wrapper__title}> */}
    {/*  Estimation de l&apos;optimisation */}
    {/* </span> */}
    {/* <div className={style.container}> */}
    {/*  <span className={style.container__price}> */}
    {/*    {numbro(module.price / 100).formatCurrency({ */}
    {/*      thousandSeparated: true, */}
    {/*      optionalMantissa: true, */}
    {/*      mantissa: 2 */}
    {/*    })} */}
    {/*  </span> */}
    {/*  /!* Show "Add to cart" button when module is not in shopping cart *!/ */}
    {/*  <AddToCartButton /> */}
    {/* </div> */}
    <AddToCartButton />
  </div>
);

const mapStateToProps = state => ({
  module: state.audit.module
});

export default connect(mapStateToProps)(Price);
