import apollo from "../api/apollo";

export const SET_JWT = "SET_JWT";
export const setJwt = jsonWebToken => {
  localStorage.setItem("jwt", jsonWebToken);

  return {
    type: SET_JWT,
    jsonWebToken
  };
};

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const login = (email, password) => (dispatch, getState, axios) => {
  dispatch({ type: "LOGIN_REQUEST" });
  return axios
    .post("/sign-in", { email, password })
    .then(({ data: user }) => {
      localStorage.setItem("jwt", user.jsonWebToken);

      return dispatch({
        type: LOGIN_SUCCESS,
        user
      });
    })
    .catch(({ response: error }) => {
      dispatch({
        type: LOGIN_FAILURE,
        error
      });
      throw error;
    });
};

export const LOGOUT = "LOGOUT";
export const logout = () => dispatch => {
  localStorage.removeItem("jwt");
  // reset apollo cache
  apollo.resetStore();

  return Promise.resolve(dispatch({ type: LOGOUT }));
};

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const signup = (url, email, password, phone) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "SIGNUP_REQUEST" });
  return axios
    .post("/register", {
      url: url.trim().toLowerCase(),
      email: email.trim(),
      password: password.trim(),
      phone: phone.trim()
    })
    .then(({ data: user }) => {
      setJwt(user.jsonWebToken);
      dispatch({ type: SIGNUP_SUCCESS, user });
    })
    .catch(({ response: error }) => {
      dispatch({ type: SIGNUP_FAILURE, error });
      // Rethrow so returned Promise is rejected
      throw error;
    });
};

export const GOOGLE_SIGNIN_SUCCESS = "GOOGLE_SIGNIN_SUCCESS";
export const GOOGLE_SIGNIN_FAILURE = "GOOGLE_SIGNIN_FAILURE";
export const googleSignin = (url = null) => (dispatch, getState, axios) => {
  dispatch({ type: "GOOGLE_SIGNIN_REQUEST" });
  return axios
    .post("/gapi", { url: url && url.trim().toLowerCase() })
    .then(({ data: oauth2Url }) => {
      window.location = oauth2Url;
      return dispatch({ type: GOOGLE_SIGNIN_SUCCESS });
    })
    .catch(({ response: error }) => {
      dispatch({ type: GOOGLE_SIGNIN_FAILURE, error });
      throw error;
    });
};

export const SET_URL_TO_GOOGLE_ACCOUNT_SUCCESS =
  "SET_URL_TO_GOOGLE_ACCOUNT_SUCCESS";
export const SET_URL_TO_GOOGLE_ACCOUNT_FAILURE =
  "SET_URL_TO_GOOGLE_ACCOUNT_FAILURE";
export const setUrlToGoogleAccount = (accountId, gsub, url) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "SET_URL_TO_GOOGLE_ACCOUNT_REQUEST" });
  return axios
    .post(`/gapi/${accountId}/url`, { url: url.trim().toLowerCase(), gsub })
    .then(({ data: jwt }) =>
      dispatch({ type: SET_URL_TO_GOOGLE_ACCOUNT_SUCCESS, jwt })
    )
    .catch(({ response: error }) => {
      dispatch({ type: SET_URL_TO_GOOGLE_ACCOUNT_FAILURE, error });
      throw error;
    });
};

export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";
export const verifyEmail = token => (dispatch, getState, axios) => {
  dispatch({ type: "VERIFY_EMAIL_REQUEST" });
  return axios
    .post("/register/finalize", { token })
    .then(({ data: user }) => {
      setJwt(user.jsonWebToken);
      return dispatch({
        type: VERIFY_EMAIL_SUCCESS,
        user
      });
    })
    .catch(({ response: error }) =>
      dispatch({
        type: VERIFY_EMAIL_FAILURE,
        error
      })
    );
};

export const RESET_PASSWORD_BY_EMAIL_SUCCESS =
  "RESET_PASSWORD_BY_EMAIL_SUCCESS";
export const RESET_PASSWORD_BY_EMAIL_FAILURE =
  "RESET_PASSWORD_BY_EMAIL_FAILURE";
export const resetPasswordByEmail = email => (dispatch, getState, axios) => {
  dispatch({ type: "RESET_PASSWORD_BY_EMAIL_REQUEST" });
  return axios
    .get("/reset-password", { params: { email } })
    .then(() => {
      dispatch({ type: RESET_PASSWORD_BY_EMAIL_SUCCESS });
    })
    .catch(({ response: error }) => {
      dispatch({ type: RESET_PASSWORD_BY_EMAIL_FAILURE, error });
      // Rethrow so returned Promise is rejected
      throw error;
    });
};

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const resetPassword = (password, token) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "RESET_PASSWORD_REQUEST" });
  return axios
    .post("/reset-password", { password, token })
    .then(() => {
      dispatch({ type: RESET_PASSWORD_SUCCESS });
    })
    .catch(({ response: error }) => {
      dispatch({ type: RESET_PASSWORD_FAILURE, error });
      // Rethrow so returned Promise is rejected
      throw error;
    });
};

export const TEST_URL_SUCCESS = "TEST_URL_SUCCESS";
export const TEST_URL_FAILURE = "TEST_URL_FAILURE";
export const testUrl = url => (dispatch, getState, axios) => {
  dispatch({ type: "TEST_URL_REQUEST" });
  return axios
    .get("/test-url", { params: { url } })
    .then(({ data }) => dispatch({ type: TEST_URL_SUCCESS, res: data }))
    .catch(({ response: error }) => {
      dispatch({ type: TEST_URL_FAILURE, error });
      // Rethrow so returned Promise is rejected
      throw error;
    });
};
