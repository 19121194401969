import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

// Assets
import style from "./Breadcrumb.module.sass";

// Components
import Arrow from "./Arrow";

interface Props {
  returnTo?: string;
  title?: string;
}

const Breadcrumb: FunctionComponent<Props> = ({ returnTo, title }) => {
  const history = useHistory();

  return (
    <div className={style.container}>
      <div
        onClick={
          returnTo ? () => history.push(returnTo) : () => history.goBack()
        }
        className={
          title ? style["container__back--with-title"] : style.container__back
        }
      >
        <Arrow />
        Retour
      </div>
      {title && <span className={style.container__title}>{title}</span>}
    </div>
  );
};

export default Breadcrumb;
