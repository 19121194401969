import React, { FC } from "react";
import { Link } from "react-router-dom";
import Box from "./Box";
import style from "./Dashboard.module.sass";
import money from "../../img/illustrations/money.svg";

const SeaBox: FC = () => (
  <Box title="Obtenez du trafic instantanément">
    <div className={[style.main, style["main--sea"]].join(" ")}>
      <img className={style.main__illu} src={money} alt="money" />
      <p>
        En moyenne, <span>1€ investi</span> en campagnes publicitaires sur
        Google rapporte <span>2€ de revenus</span>.
      </p>
    </div>
    <Link
      to="/sea"
      className={[style.footer, style["footer--button"]].join(" ")}
    >
      Lancer une campagne publicitaire
    </Link>
  </Box>
);

export default SeaBox;
