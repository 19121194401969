export const TOGGLE_GUIDED_TOUR = "TOGGLE_GUIDED_TOUR";
export const toggleGuidedTour = () => ({
  type: TOGGLE_GUIDED_TOUR
});

export const TOGGLE_ARROW_CART = "TOGGLE_ARROW_CART";
export const toggleArrowCart = bool => ({
  type: TOGGLE_ARROW_CART,
  bool
});

export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const setRedirectUrl = url => ({
  type: SET_REDIRECT_URL,
  url
});

export const SET_INTL = "SET_INTL";
export const setIntl = intl => ({
  type: SET_INTL,
  intl
});

export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";
export const getSubscriptions = () => (dispatch, getState, axios) => {
  dispatch({ type: "GET_SUBSCRIPTIONS_REQUEST" });
  return axios
    .get("/subscriptions")
    .then(({ data: subscriptions }) =>
      dispatch({ type: GET_SUBSCRIPTIONS_SUCCESS, subscriptions })
    )
    .catch(error =>
      dispatch({
        type: GET_SUBSCRIPTIONS_FAILURE,
        error
      })
    );
};
