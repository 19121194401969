import React from "react";
import screenshot from "./screenshot2.png";
import Template from "../_stepTemplate";

const Step3 = () => (
  <Template
    img={screenshot}
    text="Une campagne de pub est un moyen très efficace d'acquérir rapidement
    du trafic sur votre site. Sélectionnez votre budget et laissez-vous guider
    par nos experts pour vous positionner sur les requêtes les plus pertinentes."
    title="Mes campagnes publicitaires"
  />
);

export default Step3;
