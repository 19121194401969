/**
 * Check if a url is allowed
 *
 * @param {string} url
 * @param {string[]} [moreUrls=null]
 * @returns {*}
 */
export default (url, moreUrls = null) => {
  let urlsNotAllowed = [
    "facebook.com",
    "youtube.com",
    "twitter.com",
    "instagram.com"
  ];

  urlsNotAllowed =
    moreUrls !== null ? urlsNotAllowed.concat(moreUrls) : urlsNotAllowed;
  const regex = new RegExp(urlsNotAllowed.join("|"), "g");

  return url.match(regex);
};
