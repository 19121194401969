import React, { useState, useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import QuestionCircle from "img/icons/QuestionCircle";
import style from "./OctoScore.module.sass";
import { getScoreLetter } from "../../../../../utils";

const Illustration = ({ letter }) => {
  const [illus, setIllus] = useState({});

  // create object with all illustrations path
  function importAll(r) {
    const allIllus = {};

    r.keys().forEach(key => {
      allIllus[key] = r(key);
    });

    setIllus(allIllus);
  }

  useEffect(() => {
    importAll(
      // get all modules icons
      require.context("./_illustrations/", true, /\.svg$/)
    );
  }, []);

  if (_.isEmpty(illus)) return null;
  return <img src={illus[`./${letter}.svg`]} alt="illustration" />;
};

const OctoScore = ({ score }) => {
  const [scoreLetter, setScoreLetter] = useState("");

  useEffect(() => {
    setScoreLetter(getScoreLetter(score));
  });

  return (
    <div className={style.wrapper}>
      <Illustration letter={scoreLetter} />
      <div className={style["wrapper__score-container"]}>
        <p className={style.wrapper__score}>{scoreLetter}</p>
        <p className={style.wrapper__range}>A B C D E</p>
        {/* React tooltip component in Results/index.jsx */}
        <div
          className={style.wrapper__tooltip}
          data-tip={`<p>Suite à l'analyse de votre site, Octopulse génère un score allant de <b>A</b> à <b>E</b>.</p><p>Vous pouvez améliorer ce score grâce aux optimisations listées ci-dessous.</p>`}
        >
          <QuestionCircle />
        </div>
      </div>
    </div>
  );
};

Illustration.propTypes = {
  letter: PropTypes.string.isRequired
};

OctoScore.propTypes = {
  score: PropTypes.number.isRequired
};

export default OctoScore;
