import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import style from "./Email.module.sass";
import "./select.sass";
import { EmailPeriodicity } from "../../../enum";
import {
  getUserEmailPreference,
  updateUserEmailPreference
} from "../../../actions/user";

const Email: FC = () => {
  const dispatch: any = useDispatch();
  const [emailPreference, setEmailPreference] = useState();
  const [values, setValues]: any = useState();
  const options = [
    {
      value: EmailPeriodicity.disable,
      label: "Désactivé"
    },
    {
      value: EmailPeriodicity.daily,
      label: "Tous les jours"
    },
    {
      value: EmailPeriodicity.weekly,
      label: "Toutes les semaines"
    },
    {
      value: EmailPeriodicity.monthly,
      label: "Tous les mois"
    }
  ];

  useEffect(() => {
    dispatch(getUserEmailPreference()).then(({ userEmailPreference }: any) => {
      // Default values
      const rankingReportingPeriodicity = options.find(
        option =>
          option.value === userEmailPreference.rankingReportingPeriodicity
      );

      setEmailPreference(userEmailPreference);
      setValues((prevState: any) => ({
        ...prevState,
        rankingReportingPeriodicity
      }));
    });
  }, []);

  const changeValue = (e: any, type: string) => {
    setValues((prevState: any) => ({ ...prevState, [type]: e }));
    dispatch(updateUserEmailPreference({ [type]: e.value }))
      .then(() => toast.success("Vos changements ont bien été pris en compte"))
      .catch(() => toast.error("Une erreur est survenue"));
  };

  return (
    <>
      <h3>Email</h3>
      <div className={style.container}>
        <div className={style.row}>
          <div>
            <h4>Rapport de position sur Google</h4>
            <p>Rapport du suivi de la position de vos mots-clés sur Google</p>
          </div>
          <Select
            value={values && values.rankingReportingPeriodicity}
            onChange={e => changeValue(e, "rankingReportingPeriodicity")}
            className="select-settings-container"
            classNamePrefix="select-settings"
            options={emailPreference ? options : []}
            isLoading={!emailPreference}
          />
        </div>
      </div>
    </>
  );
};

export default Email;
