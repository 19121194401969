import { combineReducers } from "redux";

import errors from "reducers/errors";
import loading from "reducers/loading";
import adsCampaigns from "reducers/adsCampaigns";
import audit from "reducers/audit";
import auth from "reducers/auth";
import user from "reducers/user";
import cart from "reducers/cart";
import ranking from "reducers/ranking";
import global from "reducers/global";
import competitors from "reducers/competitors";
import websites from "reducers/websites";
import netlinking from "./reducers/netlinking";

const appReducer = combineReducers({
  errors,
  loading,
  adsCampaigns,
  auth,
  audit,
  user,
  cart,
  ranking,
  global,
  competitors,
  websites,
  netlinking
});

/**
 * Clean state on logout and set redirectUrl to null to tell React Router not to set last url on logout
 * See more on file App.jsx in NotLoggedRedirect component
 */
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    const {
      global: { intl }
    } = state;

    state = {
      global: {
        redirectUrl: null,
        intl
      }
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
