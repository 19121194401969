import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "./RangeSlider.module.sass";

const RangeSlider = ({ onChange, min, max, defaultValue, className }) => {
  const [value, setValue] = useState(defaultValue || min);

  return (
    <input
      className={[style.slider, className].join(" ")}
      type="range"
      min={min}
      max={max}
      value={value}
      id="budgetRange"
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

RangeSlider.propTypes = {
  onChange: PropTypes.func,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default RangeSlider;
