import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import style from "./ProgressionIcon.module.sass";

const ProgressionIcon = ({ num, tooltip }) => {
  if (num > 0)
    return (
      <span
        className={[style.diff, style["diff--up"]].join(" ")}
        data-tip={
          tooltip
            ? `Ce mot-clé a gagné <b>${num} position${
                num > 1 ? "s" : ""
              }</b> sur cette période.`
            : null
        }
      >
        <span className={style.diff__num}>+{Math.round(num * 10) / 10}</span>
        <ReactTooltip type="dark" effect="solid" html />
      </span>
    );
  if (num < 0)
    return (
      <span
        className={[style.diff, style["diff--down"]].join(" ")}
        data-tip={
          tooltip
            ? `Ce mot-clé a perdu <b>${num * -1} position${
                num * -1 > 1 ? "s" : ""
              }</b> sur cette période.`
            : null
        }
      >
        <span className={style.diff__num}>{Math.round(num * 10) / 10}</span>
        <ReactTooltip type="dark" effect="solid" html />
      </span>
    );
  return null;
};

ProgressionIcon.propTypes = {
  num: PropTypes.number.isRequired,
  tooltip: PropTypes.bool
};

export default ProgressionIcon;
