import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import style from "./SideBar.module.sass";
import { ToggleContext } from "../index";
import {
  Seo,
  Advertising,
  MobileOpenMenu,
  MobileCloseMenu,
  Netlinking,
  SeoLocal,
  Dashboard,
  Quill
} from "./_icons";
import { Payment, Profile, ShoppingCart } from "../Header/_icons";
import Logo from "./_logo";
import { logout } from "../../../actions/auth";
import SelectWebsites from "./SelectWebsites";

const TABLET_PORTRAIT_WIDTH = 767;

const items = [
  {
    to: "/dashboard",
    name: "dashboard",
    icon: <Dashboard />
  },
  {
    to: "/audit",
    name: "myOptimisations",
    icon: <Seo />
  },
  {
    to: "/sea",
    name: "myAdsCampaigns",
    icon: <Advertising />
  },
  {
    to: "/netlinking",
    name: "myNetlinking",
    icon: <Netlinking />
  },
  // {
  //   to: "/ranking",
  //   name: "myRank",
  //   icon: <Trophy />,
  //   disableDate: moment("2022-11-04 10:00")
  // },
  // {
  //   to: "/competitors",
  //   name: "myCompetitors",
  //   icon: <Competitors />,
  //   disableDate: moment("2022-11-04 10:00")
  // },
  {
    to: "/local",
    name: "mySeoLocal",
    icon: <SeoLocal />
  },
  {
    to: "/content",
    title: "Mon contenu",
    icon: <Quill />,
    new: true
  }
  // {
  //   to: "/results",
  //   name: "costsVersusResults",
  //   icon: <MoneyBag />,
  //   disabled: true
  // }
];

const Item = ({ item, index, switchToggle }) => {
  if (item.disabled)
    return (
      <div className={style["menu__item--disabled"]}>
        {item.icon}
        {item.name ? (
          <FormattedMessage id={`sidebar.${item.name}`} />
        ) : (
          item.title
        )}
        <div className={style.item__badge}>Soon</div>
      </div>
    );

  return (
    <NavLink
      key={index}
      to={item.to}
      className={style.menu__item}
      activeClassName={style["menu__item--selected"]}
      onClick={() => switchToggle(false)}
    >
      {item.icon}
      {item.name ? (
        <FormattedMessage id={`sidebar.${item.name}`} />
      ) : (
        item.title
      )}
      {item.hasPromo && <div className={style.item__badge}>En promo !</div>}
      {item.new && <div className={style.item__badge}>nouveau</div>}
    </NavLink>
  );
};

const SideBar = ({ dispatch, websites, history }) => {
  const { domain } = websites.current;

  // ---------- Check if home button is enabled based on window's width ----------
  const [isHomeButtonEnabled, setIsHomeButtonEnabled] = useState(true);

  useEffect(() => {
    if (document.body.clientWidth <= TABLET_PORTRAIT_WIDTH)
      setIsHomeButtonEnabled(false);
  }, []);

  window.addEventListener("resize", () => {
    // called every time the window's width changes
    if (document.body.clientWidth <= TABLET_PORTRAIT_WIDTH)
      setIsHomeButtonEnabled(false);
    else setIsHomeButtonEnabled(true);
  });
  // -----------------------------------------------------------------------------

  return (
    <ToggleContext.Consumer>
      {({ toggleValue, switchToggle }) => (
        <div className={style.menu}>
          <div className={style.menu__top}>
            <div
              className={style.top__logo}
              onClick={() => isHomeButtonEnabled && history.push("/")}
            >
              {/* <img src="https://www.octopulse.io/img/logo.png" alt="logo" /> */}
              {domain.substring(0, 2)}
            </div>
            <SelectWebsites />
            <div className={style.top__icons}>
              <Link to="/cart" onClick={() => switchToggle(false)}>
                <ShoppingCart />
              </Link>
              {toggleValue ? (
                <MobileCloseMenu onClick={switchToggle} />
              ) : (
                <MobileOpenMenu onClick={switchToggle} />
              )}
            </div>
          </div>
          <div
            className={
              toggleValue
                ? style["menu__items--toggle-show"]
                : style.menu__items
            }
          >
            {items.map((item, index) => (
              <Item
                key={index}
                item={item}
                index={index}
                switchToggle={switchToggle}
              />
            ))}
            <div className={style["mobile-profile-buttons"]}>
              <Link
                to="/settings/profile"
                className={style["mobile-profile-buttons__item"]}
                onClick={() => switchToggle(false)}
              >
                <Profile />
                <FormattedMessage id="sidebar.myProfile" />
              </Link>
              <Link
                to="/settings/subscription"
                className={style["mobile-profile-buttons__item"]}
                onClick={() => switchToggle(false)}
              >
                <Payment />
                <FormattedMessage id="sidebar.mySubscription" />
              </Link>
              <div
                onClick={() => dispatch(logout())}
                className={style["mobile-profile-buttons__disconnect"]}
              >
                <FormattedMessage id="sidebar.logout" />
              </div>
            </div>
          </div>
          <div className={style.menu__logo}>
            <Logo />
          </div>
        </div>
      )}
    </ToggleContext.Consumer>
  );
};

const mapStateToProps = state => ({ websites: state.websites });

export default withRouter(connect(mapStateToProps)(SideBar));
