import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import style from "./ErrorBoundary.module.sass";
import Button from "../Button";
import { logout } from "../../actions/auth";
import { setRedirectUrl } from "../../actions/global";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: { error, info } });
    if (process.env.NODE_ENV === "production") {
      // send error info to sentry (error tracker)
      Sentry.withScope(scope => {
        Object.keys(info).forEach(key => {
          scope.setExtra(key, info[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  handleClick = () => {
    const { dispatch, history } = this.props;

    dispatch(logout()).then(() => {
      dispatch(setRedirectUrl(null));
      history.push("/login");
      window.location.reload();
    });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className={style.container}>
          <h2>Une erreur est survenue :&apos;(</h2>
          <h3>Veuillez vous reconnecter.</h3>
          <p>
            Si le problème persiste, n&apos;hésitez pas à{" "}
            <u
              style={{ cursor: "pointer" }}
              onClick={() => Sentry.showReportDialog()}
            >
              décrire votre problème
            </u>{" "}
            ou à nous contacter.
          </p>
          <Button onClick={() => this.handleClick()}>Se reconnecter</Button>
        </div>
      );
    }
    return children;
  }
}

export default withRouter(connect()(ErrorBoundary));
