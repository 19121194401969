import moment from "moment";

export const CHANGE_DATES = "CHANGE_DATES";
export const changeDates = (startDate, endDate) => ({
  type: CHANGE_DATES,
  startDate: moment(startDate),
  endDate: moment(endDate)
});

export const CHANGE_DEVICE = "CHANGE_DEVICE";
export const changeDevice = device => ({ type: CHANGE_DEVICE, device });

export const CHANGE_FROM = "CHANGE_FROM";
export const changeFrom = from => ({ type: CHANGE_FROM, from });

export const CHANGE_SIZE = "CHANGE_SIZE";
export const changeSize = size => ({ type: CHANGE_SIZE, size });

export const CHANGE_SORT = "CHANGE_SORT";
export const changeSort = sort => ({ type: CHANGE_SORT, sort });

export const GET_RANKINGS_SUCCESS = "GET_RANKINGS_SUCCESS";
export const GET_RANKINGS_FAILURE = "GET_RANKINGS_FAILURE";
export const getRankings = () => (dispatch, getState, axios) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "GET_RANKINGS_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/rankings`)
    .then(({ data: rankings }) =>
      dispatch({ type: GET_RANKINGS_SUCCESS, rankings })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GET_RANKINGS_FAILURE, error });
      throw error;
    });
};

export const GET_RANKING_BY_ID_SUCCESS = "GET_RANKING_BY_ID_SUCCESS";
export const GET_RANKING_BY_ID_FAILURE = "GET_RANKING_BY_ID_FAILURE";
export const getRankingById = (id, startDate, endDate, device) => (
  dispatch,
  getState,
  axios
) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "GET_RANKING_BY_ID_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/rankings/${id}`, {
      params: {
        date_from: moment(startDate).toISOString(),
        date_to: moment(endDate).toISOString(),
        platform: device
      }
    })
    .then(({ data: project }) =>
      dispatch({ type: GET_RANKING_BY_ID_SUCCESS, project })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GET_RANKING_BY_ID_FAILURE, error });
      throw error;
    });
};

export const LAUNCH_RANKING_SUCCESS = "LAUNCH_RANKING_SUCCESS";
export const LAUNCH_RANKING_FAILURE = "LAUNCH_RANKING_FAILURE";
export const launchRanking = (keywords, country, language) => (
  dispatch,
  getState,
  axios
) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "LAUNCH_RANKING_REQUEST" });
  return axios
    .post(`/websites/${websiteId}/rankings`, {
      country,
      language,
      websiteId,
      keywords
    })
    .then(({ data: project }) =>
      dispatch({ type: LAUNCH_RANKING_SUCCESS, project })
    )
    .catch(({ response: error }) => {
      dispatch({ type: LAUNCH_RANKING_FAILURE, error });
      throw error;
    });
};

export const GET_KEYWORDS_SUCCESS = "GET_KEYWORDS_SUCCESS";
export const GET_KEYWORDS_FAILURE = "GET_KEYWORDS_FAILURE";
export const getKeywords = id => (dispatch, getState, axios) => {
  const { id: websiteId } = getState().websites.current;
  const { maxKeywords } = getState().user.rights.ranking;

  dispatch({ type: "GET_KEYWORDS_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/rankings/${id}/keywords`, {
      params: { from: 0, size: maxKeywords }
    })
    .then(({ data: keywords }) =>
      dispatch({ type: GET_KEYWORDS_SUCCESS, keywords })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GET_KEYWORDS_FAILURE, error });
      throw error;
    });
};

export const ADD_KEYWORDS_SUCCESS = "ADD_KEYWORDS_SUCCESS";
export const ADD_KEYWORDS_FAILURE = "ADD_KEYWORDS_FAILURE";
export const addKeywords = (rankingId, keywords) => (
  dispatch,
  getState,
  axios
) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "ADD_KEYWORDS_REQUEST" });
  return axios
    .post(`/websites/${websiteId}/rankings/${rankingId}/keywords`, {
      keywords,
      country: "fr"
    })
    .then(({ data }) =>
      dispatch({ type: ADD_KEYWORDS_SUCCESS, keywords: data })
    )
    .catch(({ response: error }) => {
      dispatch({ type: ADD_KEYWORDS_FAILURE, error });
      throw error;
    });
};

export const GENERATE_KEYWORDS_SUCCESS = "GENERATE_KEYWORDS_SUCCESS";
export const GENERATE_KEYWORDS_FAILURE = "GENERATE_KEYWORDS_FAILURE";
export const generateKeywords = (country, language, keyword = null) => (
  dispatch,
  getState,
  axios
) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "GENERATE_KEYWORDS_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/rankings/keywords-generation`, {
      params: { country, language, keyword, limit: 500 }
    })
    .then(({ data }) =>
      dispatch({ type: GENERATE_KEYWORDS_SUCCESS, keywords: data })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GENERATE_KEYWORDS_FAILURE, error });
      throw error;
    });
};

export const DELETE_KEYWORD_SUCCESS = "DELETE_KEYWORD_SUCCESS";
export const DELETE_KEYWORD_FAILURE = "DELETE_KEYWORD_FAILURE";
export const deleteKeyword = (rankingId, keywordId) => (
  dispatch,
  getState,
  axios
) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "DELETE_KEYWORD_REQUEST" });
  return axios
    .delete(`/websites/${websiteId}/rankings/${rankingId}/keywords`, {
      data: { keywordId }
    })
    .then(({ data: keyword }) =>
      dispatch({ type: DELETE_KEYWORD_SUCCESS, keyword })
    )
    .catch(({ response: error }) => {
      dispatch({ type: DELETE_KEYWORD_FAILURE, error });
      throw error;
    });
};

export const GET_KEYWORDS_RANKS_SUCCESS = "GET_KEYWORDS_RANKS_SUCCESS";
export const GET_KEYWORDS_RANKS_FAILURE = "GET_KEYWORDS_RANKS_FAILURE";
export const getKeywordsRanks = (
  id,
  device,
  startDate,
  endDate,
  from,
  size,
  sort
) => (dispatch, getState, axios) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "GET_KEYWORDS_RANKS_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/rankings/${id}/keywords/ranks`, {
      params: {
        date_from: moment(startDate).toISOString(),
        date_to: moment(endDate).toISOString(),
        platform: device,
        from,
        size,
        sort
      }
    })
    .then(({ data: ranks }) =>
      dispatch({ type: GET_KEYWORDS_RANKS_SUCCESS, ranks })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GET_KEYWORDS_RANKS_FAILURE, error });
      throw error;
    });
};

export const GET_KEYWORD_RANKS_BY_ID_SUCCESS =
  "GET_KEYWORD_RANKS_BY_ID_SUCCESS";
export const GET_KEYWORD_RANKS_BY_ID_FAILURE =
  "GET_KEYWORD_RANKS_BY_ID_FAILURE";
export const getKeywordRanksById = (
  id,
  keywordId,
  dateFrom = null,
  dateTo = null,
  platform = null
) => (dispatch, getState, axios) => {
  const { id: websiteId } = getState().websites.current;
  const { startDate, endDate, device } = getState().ranking;

  const convertDateToIso = date => moment(date).toISOString();

  dispatch({ type: "GET_KEYWORD_RANKS_BY_ID_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/rankings/${id}/keywords/${keywordId}/ranks`, {
      params: {
        date_from: dateFrom
          ? convertDateToIso(dateFrom)
          : convertDateToIso(startDate),
        date_to: dateTo ? convertDateToIso(dateTo) : convertDateToIso(endDate),
        platform: platform || device
      }
    })
    .then(({ data: keyword }) =>
      dispatch({ type: GET_KEYWORD_RANKS_BY_ID_SUCCESS, keyword })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GET_KEYWORD_RANKS_BY_ID_FAILURE, error });
      throw error;
    });
};
