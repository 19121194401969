import React from "react";

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.78 56.15">
    <g id="Calque_3" data-name="Calque 3">
      <path
        className="cls-1"
        d="M45.89,22.59c0,12.78-9,22.59-22.91,22.59S0,35.37,0,22.59,9,0,23,0,45.89,9.81,45.89,22.59Zm-12.65,0c0-10.78-3.61-17-10.26-17s-10.33,6.19-10.33,17,3.61,17,10.33,17S33.24,33.37,33.24,22.59Z"
      />
      <path
        className="cls-1"
        d="M72.93,32.34l4.78.9c-2,7.81-6.33,11.94-14.91,11.94-10.78,0-18-6.65-18-16.72S52.28,11,62.47,11C71.7,11,78,17,78,22.46A5.34,5.34,0,0,1,72.54,28a5.44,5.44,0,0,1-5.68-5.42,5.71,5.71,0,0,1,2.07-4.52,5.61,5.61,0,0,0-4.71-1.87c-6.46,0-8.27,5.55-8.27,11,0,8.27,3.49,12.14,8.65,12.14A8.1,8.1,0,0,0,72.93,32.34Z"
      />
      <path
        className="cls-1"
        d="M82.61,34.85V17.75H78.22V11.68h.13c5.68,0,7.87-3.48,10.84-7.55h3.94v7.55h10.78v6.07H93.13V34.92a3,3,0,0,0,3,3.1c1.93,0,3.29-1,3.55-3.88l4.71.78c-1.16,7.42-4.84,10.26-10.65,10.26C86.74,45.18,82.61,40.28,82.61,34.85Z"
      />
      <path
        className="cls-1"
        d="M139.53,28c0,9.87-6.84,17.23-17.68,17.23S104.16,37.82,104.16,28C104.16,18.27,111,11,121.85,11S139.53,18.27,139.53,28Zm-11.42,0c0-9-1.68-12.33-6.26-12.33s-6.26,3.29-6.26,12.33c0,9.29,1.61,12.65,6.26,12.65S128.11,37.24,128.11,28Z"
      />
      <path
        className="cls-1"
        d="M178.06,28.21c0,10.45-5.36,17-13.81,17-4.78,0-8.46-1.68-10.52-6v8.91a1.8,1.8,0,0,0,2,2h6.39v6.06h-23.3V50.09h2.39a1.8,1.8,0,0,0,2-2V19.75a1.8,1.8,0,0,0-2-2h-2.39V11.68h14.91v5.49c2.06-4.39,5.74-6.13,10.52-6.13C172.7,11,178.06,17.69,178.06,28.21Zm-11.23,0c0-6.72-2.58-10.59-6.58-10.59-3.75,0-6.52,3.42-6.52,10.59s2.77,10.58,6.52,10.58C164.25,38.79,166.83,34.92,166.83,28.21Z"
      />
      <path
        className="cls-1"
        d="M218.33,38.47v6.07h-15V38.92a11.6,11.6,0,0,1-10.52,6.26c-6.13,0-10.46-4-10.46-10.33V19.49c0-1.35-.84-1.74-1.87-1.74h-2.39V11.68H192.9V32.47c0,3.93,1.62,6.06,4.52,6.06,3.1,0,5.94-1.93,5.94-9.94v-9a1.68,1.68,0,0,0-1.87-1.81h-2.33V11.68h14.72V36.47a1.8,1.8,0,0,0,2,2Z"
      />
      <path
        className="cls-1"
        d="M238.21,38.47v6.07h-20V38.47H221a1.81,1.81,0,0,0,2-2V8.71a1.81,1.81,0,0,0-2-2h-2.71V.65H233.5V36.47a1.8,1.8,0,0,0,2,2Z"
      />
      <path
        className="cls-1"
        d="M267.25,34.47c0,6.45-5.29,10.71-14.59,10.71-7.94,0-14.13-3.1-14.13-8.32,0-2.91,1.87-5,5.16-5,3,0,5,1.87,5,4.38a4.06,4.06,0,0,1-2.07,3.62,8.68,8.68,0,0,0,4.52,1.29c3.36,0,5.16-1.94,5.16-4.32,0-7.3-17.3-3.55-17.3-15.62,0-6.78,5.55-10.2,14.2-10.2,7.36,0,13,2.51,13,7.55,0,3-1.93,4.71-5,4.71s-5.17-1.74-5.17-4.58a3.62,3.62,0,0,1,1.36-3,6.87,6.87,0,0,0-3.1-.71c-3.29,0-5.23,1.81-5.23,4.07C249.11,26.14,267.25,22.4,267.25,34.47Z"
      />
      <path
        className="cls-1"
        d="M287.51,39.63a8.62,8.62,0,0,0,8.78-7.29l4.84.9c-1.93,7.55-6.45,11.94-15.29,11.94-11,0-17.95-6.78-17.95-16.78S274.67,11,285.32,11c10.26,0,16.46,7,16.46,15.94a15.2,15.2,0,0,1-.32,3H279.58C280.16,36.08,282.48,39.63,287.51,39.63Zm-8.06-13.94h11.74c-.13-7.81-2.13-10.07-5.61-10.07S279.7,18.2,279.45,25.69Z"
      />
    </g>
  </svg>
);
