import axios from "axios";
import store from "../store";
import { logout } from "../actions/auth";

const axiosOptions = {
  baseURL: process.env.REACT_APP_API_URL
};

const _axios = axios.create(axiosOptions);

// Use axios request interceptor to set HTTP Authorization header
_axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("jwt");

    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`
        }
      };
    }

    return config;
  },
  error =>
    // Forward rejection
    Promise.reject(error)
);

// Use axios response interceptor to handle HTTP errors
_axios.interceptors.response.use(
  res => res,
  err => {
    // If token expires or is invalid
    if (
      err.response &&
      err.response.status === 401 &&
      (err.response.data === "TokenExpiredError" ||
        err.response.data === "JsonWebTokenError")
    ) {
      store.dispatch(logout());
    }

    // If down for maintenance
    if (err.response && err.response.status === 503)
      window.location.href = "/maintenance";

    return Promise.reject(err);
  }
);

export default _axios;
