import {
  GET_ADS_CAMPAIGNS_SUCCESS,
  GET_ADS_CAMPAIGN_SUCCESS,
  GET_AD_GROUPS_SUCCESS,
  CHANGE_CAMPAIGN_DATES,
  RESET_ADS_CAMPAIGNS
} from "../actions/sea";

const initialState = {
  startDate: null,
  endDate: null
};

const audit = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADS_CAMPAIGNS_SUCCESS:
      return { ...state, list: action.adsCampaigns };
    case GET_ADS_CAMPAIGN_SUCCESS:
      return { ...state, current: action.adsCampaign };
    case GET_AD_GROUPS_SUCCESS:
      return { ...state, adGroups: action.adGroups };
    case CHANGE_CAMPAIGN_DATES:
      return { ...state, startDate: action.startDate, endDate: action.endDate };
    case RESET_ADS_CAMPAIGNS:
      return initialState;
    default:
      return state;
  }
};

export default audit;
