import React from "react";
import style from "./MobileFooterPrice.module.sass";
import Price from "../_price";

const MobileFooterPrice = () => (
  <div className={style.container}>
    <Price />
  </div>
);

export default MobileFooterPrice;
