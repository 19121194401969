import {
  GET_NETLINKING_CAMPAIGNS_SUCCESS,
  GET_NETLINKING_CONFIGURATIONS_SUCCESS,
  GET_NETLINKING_PUBLICATIONS_SUCCESS
} from "../actions/netlinking";

const netlinking = (state = {}, action) => {
  switch (action.type) {
    case GET_NETLINKING_CAMPAIGNS_SUCCESS:
      return { ...state, campaigns: action.netlinkingCampaigns };
    case GET_NETLINKING_CONFIGURATIONS_SUCCESS:
      return { ...state, configurations: action.netlinkingConfigurations };
    case GET_NETLINKING_PUBLICATIONS_SUCCESS:
      return { ...state, publications: action.netlinkingPublications };
    default:
      return state;
  }
};

export default netlinking;
