import {
  CHANGE_DATES,
  CHANGE_DEVICE,
  CHANGE_FROM,
  CHANGE_SIZE,
  CHANGE_SORT,
  GET_KEYWORDS_SUCCESS,
  GET_KEYWORDS_RANKS_SUCCESS,
  GENERATE_KEYWORDS_SUCCESS,
  GET_RANKINGS_SUCCESS,
  GET_RANKING_BY_ID_SUCCESS,
  GET_KEYWORD_RANKS_BY_ID_SUCCESS
} from "../actions/ranking";

const initialState = {
  startDate: null,
  endDate: null,
  device: 0,
  from: 0,
  size: 10, // default page size
  sort: []
};

const ranking = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_DATES:
      return { ...state, startDate: action.startDate, endDate: action.endDate };
    case CHANGE_DEVICE:
      return { ...state, device: action.device };
    case CHANGE_FROM:
      return { ...state, from: action.from };
    case CHANGE_SIZE:
      return { ...state, size: action.size };
    case CHANGE_SORT:
      return { ...state, sort: action.sort };
    case GET_RANKINGS_SUCCESS:
      return { ...state, rankings: action.rankings };
    case GET_RANKING_BY_ID_SUCCESS:
      return { ...state, project: action.project };
    case GET_KEYWORDS_SUCCESS:
      return { ...state, keywords: action.keywords };
    case GET_KEYWORDS_RANKS_SUCCESS:
      return { ...state, ranks: action.ranks };
    case GET_KEYWORD_RANKS_BY_ID_SUCCESS:
      return { ...state, keyword: action.keyword };
    case GENERATE_KEYWORDS_SUCCESS:
      return { ...state, generated: action.keywords };
    default:
      return state;
  }
};

export default ranking;
