import React from "react";

const ExtendContent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>content-write</title>
    <path
      className="a"
      d="M12.5,20.5H1.5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1h14a1,1,0,0,1,1,1v5"
    />
    <line className="a" x1="6.5" y1="3.5" x2="12.5" y2="3.5" />
    <line className="a" x1="6.5" y1="6.5" x2="12.5" y2="6.5" />
    <line className="a" x1="6.5" y1="9.5" x2="7.5" y2="9.5" />
    <path className="a" d="M3.5,3.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
    <path className="a" d="M3.5,6.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
    <path className="a" d="M3.5,9.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
    <path
      className="a"
      d="M14.609,10.353a3.562,3.562,0,0,0-.475-.286l-1.1-.549a1.924,1.924,0,0,0-1.979.155L9.343,10.9a2.424,2.424,0,0,0-.99,1.628l-.259,1.819"
    />
    <line className="a" x1="14" y1="17.5" x2="17" y2="23.5" />
    <polyline className="a" points="6.774 15.154 5 18 8.342 17.712" />
    <path
      className="a"
      d="M11.951,15.5l5.77-3.535a1.5,1.5,0,0,0-1.567-2.558l-9.38,5.747,1.568,2.558,1.675-1.027"
    />
    <path className="a" d="M14.5,15.5H11a1,1,0,0,0,0,2h4.5" />
    <line className="a" x1="23.5" y1="23.5" x2="16.462" y2="12.736" />
  </svg>
);

const Ux = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>responsive-design-hand</title>
    <path
      className="a"
      d="M23,23.5l.5-3.279a.95.95,0,0,0-.59-.971L17.5,17.309V13a1.5,1.5,0,0,0-3,0v8.191l-1.72-2.058a1.038,1.038,0,0,0-.8-.367l-1,.017a.976.976,0,0,0-.861,1.438L11.931,23.5"
    />
    <path
      className="a"
      d="M4.5,17.5H2A1.5,1.5,0,0,1,.5,16V2A1.5,1.5,0,0,1,2,.5h9A1.5,1.5,0,0,1,12.5,2V6.5"
    />
    <line className="a" x1="0.5" y1="13.5" x2="4.5" y2="13.5" />
    <line className="a" x1="10.5" y1="8.5" x2="10.5" y2="17" />
    <rect className="a" x="14.5" y="0.5" width="5" height="9" rx="1" ry="1" />
    <line className="a" x1="14.5" y1="7.5" x2="19.5" y2="7.5" />
    <line className="a" x1="14.5" y1="2.5" x2="19.5" y2="2.5" />
    <line className="a" x1="0.501" y1="3.5" x2="12.501" y2="3.5" />
    <line className="a" x1="20.5" y1="11" x2="20.5" y2="16.5" />
    <path className="a" d="M21.5,8.5H22A1.5,1.5,0,0,1,23.5,10v7.5" />
    <path
      className="a"
      d="M8,20.5A1.5,1.5,0,0,1,6.5,19V10A1.5,1.5,0,0,1,8,8.5h4.5"
    />
  </svg>
);

const Architecture = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>hierarchy-5</title>
    <line className="a" x1="12" y1="5.499" x2="12" y2="18.499" />
    <rect className="a" x="4.5" y="1.499" width="15" height="4" rx="1" ry="1" />
    <rect className="a" x="9.5" y="18.499" width="5" height="5" rx="1" ry="1" />
    <rect className="a" x="0.5" y="18.499" width="5" height="5" rx="1" ry="1" />
    <rect
      className="a"
      x="18.5"
      y="18.499"
      width="5"
      height="5"
      rx="1"
      ry="1"
    />
    <path className="a" d="M20.5,18.5v-5a1,1,0,0,0-1-1H4.5a1,1,0,0,0-1,1v5" />
  </svg>
);

const SpeedupServer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>server-check</title>
    <path
      className="a"
      d="M21.5,4.5a2,2,0,0,1-2,2H2.5a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h17a2,2,0,0,1,2,2Z"
    />
    <path
      className="a"
      d="M2.5,12.5a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h17a2,2,0,0,1,2,2V10"
    />
    <path
      className="a"
      d="M4.75,3.25A.25.25,0,1,0,5,3.5a.25.25,0,0,0-.25-.25h0"
    />
    <path
      className="a"
      d="M8.25,3.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25h0"
    />
    <path
      className="a"
      d="M4.75,9.25A.25.25,0,1,0,5,9.5a.25.25,0,0,0-.25-.25h0"
    />
    <path
      className="a"
      d="M8.25,9.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25h0"
    />
    <path className="a" d="M9,18.5H2.5a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h8" />
    <path
      className="a"
      d="M4.75,15.25A.25.25,0,1,0,5,15.5a.25.25,0,0,0-.25-.25h0"
    />
    <path
      className="a"
      d="M8.25,15.25a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25h0"
    />
    <circle className="a" cx="17.5" cy="17.5" r="6" />
    <path
      className="a"
      d="M20.174,15.756,17.269,19.63a.75.75,0,0,1-1.13.08l-1.5-1.5"
    />
  </svg>
);

const SecureWebsite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>password-key</title>
    <path
      className="a"
      d="M9.5,19.5h-7a2,2,0,0,1-2-2V2.5a2,2,0,0,1,2-2h19a2,2,0,0,1,2,2v7"
    />
    <line className="a" x1="0.5" y1="5.5" x2="23.5" y2="5.5" />
    <path className="a" d="M4,2.75A.25.25,0,1,1,3.75,3,.25.25,0,0,1,4,2.75" />
    <path className="a" d="M7,2.75A.25.25,0,1,1,6.75,3,.25.25,0,0,1,7,2.75" />
    <path className="a" d="M10,2.75A.25.25,0,1,1,9.75,3,.25.25,0,0,1,10,2.75" />
    <path
      className="a"
      d="M11,14.5H4a.5.5,0,0,1-.5-.5V11a.5.5,0,0,1,.5-.5H18.5"
    />
    <path
      className="a"
      d="M16.405,20.231a1.25,1.25,0,1,1,0-1.768A1.251,1.251,0,0,1,16.405,20.231Z"
    />
    <path
      className="a"
      d="M19.048,17.942,23.5,13.5v-2h-2l-4.574,4.321a4,4,0,1,0,2.122,2.121Z"
    />
  </svg>
);

const SpeedupWebsite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>browser-gauge</title>
    <path
      className="a"
      d="M23.5,19.5a2,2,0,0,1-2,2H2.5a2,2,0,0,1-2-2V3.5a2,2,0,0,1,2-2h19a2,2,0,0,1,2,2Z"
    />
    <line className="a" x1="0.5" y1="6.5" x2="23.5" y2="6.5" />
    <line className="a" x1="4.25" y1="3.5" x2="4.25" y2="3.5" />
    <path className="a" d="M4.25,3.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
    <line className="a" x1="7.25" y1="3.5" x2="7.25" y2="3.5" />
    <path className="a" d="M7.25,3.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
    <line className="a" x1="10.25" y1="3.5" x2="10.25" y2="3.5" />
    <path
      className="a"
      d="M10.25,3.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25"
    />
    <line className="a" x1="4" y1="18.001" x2="6" y2="18.001" />
    <line className="a" x1="5.072" y1="14.001" x2="6.804" y2="15.001" />
    <line className="a" x1="8" y1="11.072" x2="9" y2="12.804" />
    <line className="a" x1="12" y1="10" x2="12" y2="11.999" />
    <line className="a" x1="18.93" y1="13.999" x2="17.198" y2="14.998" />
    <line className="a" x1="20.002" y1="17.999" x2="18.003" y2="17.999" />
    <path
      className="a"
      d="M15.629,11.93c.2-.511.053-.611-.336-.222l-4.086,4.086A2.782,2.782,0,0,0,10.5,17.5V18a.79.79,0,0,0,.981.8l.538-.108a2.065,2.065,0,0,0,1.352-1.124Z"
    />
  </svg>
);

const NotFound = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    `<title>app-window-error-404</title>
    <rect
      className="a"
      x="0.5"
      y="3.004"
      width="23"
      height="18"
      rx="2"
      ry="2"
    />
    <line className="a" x1="0.5" y1="8.004" x2="23.5" y2="8.004" />
    <path className="a" d="M4,5.254a.25.25,0,1,1-.25.25A.25.25,0,0,1,4,5.254" />
    <path className="a" d="M7,5.254a.25.25,0,1,1-.25.25A.25.25,0,0,1,7,5.254" />
    <path className="a" d="M10,5.254a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
    <path
      className="a"
      d="M13,15.014a1.5,1.5,0,0,1-3,0v-2a1.5,1.5,0,0,1,3,0Z"
    />
    <line className="a" x1="7.987" y1="16.504" x2="7.987" y2="15.504" />
    <path className="a" d="M7.987,15.5v-3.99A5.5,5.5,0,0,0,5,15.5H7.987" />
    <line className="a" x1="18" y1="16.504" x2="18" y2="15.504" />
    <path className="a" d="M18,15.5v-3.99a5.5,5.5,0,0,0-2.987,3.99H18" />
  </svg>
);

const Duplicate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>hierarchy-files-1</title>
    <line className="a" x1="2.505" y1="9.499" x2="2.505" y2="11.999" />
    <line className="a" x1="2.505" y1="14.499" x2="2.505" y2="16.999" />
    <path className="a" d="M2.505,5.5v1a1,1,0,0,0,1,1h1" />
    <path className="a" d="M2.505,19.5v1a1,1,0,0,0,1,1h1" />
    <line className="a" x1="7" y1="7.499" x2="10" y2="7.499" />
    <line className="a" x1="7" y1="21.499" x2="10" y2="21.499" />
    <line className="a" x1="2.505" y1="0.499" x2="2.505" y2="2.999" />
    <path
      className="a"
      d="M20.5,9.5a1,1,0,0,1-1,1h-6a1,1,0,0,1-1-1v-8a1,1,0,0,1,1-1h3a2.782,2.782,0,0,1,1.707.707l1.586,1.586A2.782,2.782,0,0,1,20.5,4.5Z"
    />
    <path className="a" d="M17,.569V3a1,1,0,0,0,1,1h2.429" />
    <path
      className="a"
      d="M20.5,22.5a1,1,0,0,1-1,1h-6a1,1,0,0,1-1-1v-8a1,1,0,0,1,1-1h3.114a2.139,2.139,0,0,1,1.513.627l1.666,1.666A2.782,2.782,0,0,1,20.5,17.5Z"
    />
    <path className="a" d="M17,13.536V16a1,1,0,0,0,1,1h2.43" />
  </svg>
);

const Social = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>human-resources-network</title>
    <path
      className="a"
      d="M11.5,23.5a5.779,5.779,0,0,1,6-6,5.779,5.779,0,0,1,6,6Z"
    />
    <circle className="a" cx="17.502" cy="12" r="4" />
    <path
      className="a"
      d="M21.5,11.816a4.411,4.411,0,0,1-2.385.684,5.527,5.527,0,0,1-4.661-3.107"
    />
    <line className="a" x1="1.736" y1="14.5" x2="11.511" y2="14.5" />
    <line className="a" x1="2.134" y1="4.5" x2="17.381" y2="4.5" />
    <line className="a" x1="12.011" y1="9.5" x2="0.502" y2="9.5" />
    <path
      className="a"
      d="M9.011,19A9.052,9.052,0,0,1,.5,9.756,9.263,9.263,0,0,1,18.43,6.5"
    />
    <path className="a" d="M8.982.531C4.182,5.759,4.191,12.574,9.011,19" />
    <path className="a" d="M10.541.533A12.974,12.974,0,0,1,13.88,7.5" />
  </svg>
);

const HTML = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>programming-language-html</title>
    <rect className="a" x="0.5" y="2.5" width="23" height="19" rx="2" ry="2" />
    <line className="a" x1="0.5" y1="7.5" x2="23.5" y2="7.5" />
    <path className="a" d="M4,4.75A.25.25,0,1,1,3.75,5,.25.25,0,0,1,4,4.75" />
    <path className="a" d="M7,4.75A.25.25,0,1,1,6.75,5,.25.25,0,0,1,7,4.75" />
    <path className="a" d="M10,4.75A.25.25,0,1,1,9.75,5,.25.25,0,0,1,10,4.75" />
    <line className="a" x1="9.5" y1="16.5" x2="9.5" y2="11.5" />
    <line className="a" x1="8.5" y1="11.5" x2="10.5" y2="11.5" />
    <line className="a" x1="4.5" y1="16.5" x2="4.5" y2="11.5" />
    <line className="a" x1="6.5" y1="16.5" x2="6.5" y2="11.5" />
    <line className="a" x1="4.5" y1="14.5" x2="6.5" y2="14.5" />
    <path className="a" d="M17.5,11.5v4a1,1,0,0,0,1,1h1" />
    <polyline
      className="a"
      points="15.5 16.5 15.5 11.5 14 14 12.5 11.5 12.5 16.5"
    />
  </svg>
);

const Google = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>social-media-google</title>
    <path
      className="a"
      d="M12,23.5A11.5,11.5,0,1,1,20.383,4.128L17.468,6.866A7.432,7.432,0,0,0,12,4.5a7.5,7.5,0,1,0,7.087,10H13.5v-4h10l0,2.1A11.5,11.5,0,0,1,12,23.5Z"
    />
    <line className="a" x1="5.5" y1="8.2" x2="2.348" y2="5.679" />
    <line className="a" x1="5.58" y1="15.828" x2="2.547" y2="18.558" />
    <line className="a" x1="16.471" y1="18.029" x2="19.141" y2="21.034" />
  </svg>
);

const QuillPencil = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <title>content-pencil-quill</title>
    <line className="a" x1="23.246" y1="23.214" x2="17.25" y2="17.217" />
    <path
      className="a"
      d="M3.693,11.221A18.253,18.253,0,0,1,.812.78c.12,0,6.684-.059,11.178,2.9"
    />
    <path
      className="a"
      d="M19.336,13.525c.887,2.517.918,4.679-.091,5.688-1.059,1.059-3.246,1.056-5.756.137"
    />
    <path
      className="a"
      d="M22.164,1.8a3,3,0,0,0-4.242,0L2.956,16.762.746,23.221,7.2,21,22.164,6.038A3,3,0,0,0,22.164,1.8Z"
    />
    <line className="a" x1="17.041" y1="2.677" x2="21.283" y2="6.919" />
    <line className="a" x1="2.956" y1="16.762" x2="7.203" y2="21" />
  </svg>
);

export default ({ tag }) => {
  switch (tag) {
    case "content":
      return <QuillPencil />;
    case "basic_tags":
      return <HTML />;
    case "social_media":
      return <Social />;
    case "duplicate_content":
      return <Duplicate />;
    case "content_not_found":
      return <NotFound />;
    case "speedup_website":
      return <SpeedupWebsite />;
    case "secure_website":
      return <SecureWebsite />;
    case "speedup_server":
      return <SpeedupServer />;
    case "architecture":
      return <Architecture />;
    case "ux":
      return <Ux />;
    case "extend_content":
      return <ExtendContent />;
    case "google_services":
      return <Google />;
    default:
      return null;
  }
};
