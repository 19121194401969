import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "components";
import { verifyEmail } from "actions/auth";
import {
  createLoadingSelector,
  createErrorMessageSelector
} from "api/selectors";
import style from "./Verify.module.sass";
import Template from "../_template";

const Verify = ({ location, dispatch, error, loading }) => {
  // get token in params
  const params = new URLSearchParams(location.search);
  const token = params.get("token") || "";

  useEffect(() => {
    dispatch(verifyEmail(token));
  }, []);

  if (loading)
    return (
      <Template>
        <div className={style.container}>Loading...</div>
      </Template>
    );
  if (error)
    return (
      <Template>
        <div className={style.container}>
          Nous n&apos;avons pas réussi a valider votre compte. Contactez notre
          support si le problème persiste,
        </div>
      </Template>
    );
  return (
    <Template>
      <div className={style.container}>
        Votre compte a bien été validé, vous pouvez à présent vous connecter !
        <Button to="/login" className={style.container__button}>
          Me connecter
        </Button>
      </div>
    </Template>
  );
};

const loadingSelector = createLoadingSelector(["VERIFY_EMAIL"]);
const errorSelector = createErrorMessageSelector(["VERIFY_EMAIL"]);

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  error: errorSelector(state)
});

export default connect(mapStateToProps)(Verify);
