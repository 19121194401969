import React, { FC, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import numbro from "numbro";
import style from "./Header.module.sass";
import { Pencil } from "../../../img/icons";
import Settings from "./Settings";
import { Modal } from "../../../components";

const Header: FC = () => {
  const modalSettings = useRef(null);
  const { domain } = useSelector((state: any) => state.websites.current);
  const { budget, createdAt } = useSelector(
    (state: any) => state.adsCampaigns.list[0]
  );

  return (
    <div className={style.container}>
      <div className={style.left}>
        <h4>Campagne Google Ads pour {domain}</h4>
        <span>Commencée {moment(createdAt).fromNow()}</span>
      </div>
      <div
        className={style.budget}
        // @ts-ignore
        onClick={() => modalSettings.current.openModal()}
      >
        <div className={style.budget__num}>
          <span>Budget</span>
          <span>
            {numbro(budget).format({ thousandSeparated: true })}€/mois
          </span>
        </div>
        <div className={style.budget__edit}>
          <Pencil />
        </div>
      </div>
      <Modal ref={modalSettings}>
        <Settings modal={modalSettings} />
      </Modal>
    </div>
  );
};

export default Header;
