import React, { useState } from "react";
import { connect } from "react-redux";
import { Seo } from "components/Layout/SideBar/_icons";
import { toast } from "react-toastify";
import { Elements, injectStripe } from "react-stripe-elements";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import style from "./SubscriptionTab.module.sass";
import badge from "../../../img/kpis/badge.svg";
import arrow from "../../../img/illustrations/arrow.svg";
import { Button, Input } from "../../../components";
import { Check, Octopus, CouponScissors } from "../../../img/icons";
import { orderSubscription, stopSubscription } from "../../../actions/checkout";
import { getUser } from "../../../actions/user";

const mapStateToProps = state => ({
  user: state.user
});

const StopSubscription = connect(mapStateToProps)(({ dispatch, user }) => {
  const { stripeCustomer } = user;
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const endSubscriptionDate = moment
    .unix(stripeCustomer.subscriptions.data[0].current_period_end)
    .format("LL");

  const stop = () => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir arrêter votre abonnement Octopulse ?\n\nNous mettrons fin à votre abonnement à la fin de la période en cours."
      )
    ) {
      setLoading(true);
      dispatch(stopSubscription())
        .then(() => {
          toast.success(
            `Votre abonnement a bien été arrêté. Vous n'aurez plus accès aux fonctionnalités du forfait PRO à partir du ${endSubscriptionDate}.`,
            { autoClose: 10000 }
          );
          // get new subscription object from user
          dispatch(getUser());
        })
        .finally(() => {
          setLoading(false);
          setShowButton(false);
        });
    }
  };

  if (showButton)
    return (
      <p
        className={[
          style["stop-subscription"],
          loading && style["stop-subscription--disabled"]
        ].join(" ")}
        onClick={() => stop()}
      >
        Arrêter mon abonnement
      </p>
    );
  return null;
});

const SubscriptionButton = injectStripe(
  connect()(({ dispatch, stripe }) => {
    const [loadingSession, setLoadingSession] = useState(false);
    const [showCouponInput, setShowCouponInput] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const start = e => {
      e.preventDefault();
      setLoadingSession(true);

      dispatch(orderSubscription("pro_monthly", inputValue)).then(
        ({ session }) => {
          stripe
            .redirectToCheckout({
              sessionId: session.id
            })
            .then(() => {
              toast.error("Une erreur est survenue");
            })
            .finally(() => setLoadingSession(false));
        }
      );
    };

    return (
      <form className={style.subscription} onSubmit={e => start(e)}>
        {showCouponInput && (
          <Input
            placeholder="Entrez votre code promo"
            autoFocus
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
        )}
        <div className={style.subscription__button}>
          <Button cta submit disabled={loadingSession}>
            Souscrire
          </Button>
          <div
            className={style.subscription__coupon}
            onClick={() => setShowCouponInput(true)}
            data-tip="Cliquez-ici pour ajouter un code promo"
          >
            {!showCouponInput && <CouponScissors />}
          </div>
        </div>
        {!showCouponInput && <ReactTooltip type="dark" effect="solid" html />}
      </form>
    );
  })
);

const CheckMark = ({ isChecked }) => (
  <div className={style.table__centered}>
    <div
      className={[
        style["check-mark"],
        isChecked ? style["check-mark--checked"] : style["check-mark--uncheked"]
      ].join(" ")}
    >
      {isChecked && <Check />}
    </div>
  </div>
);

const SubscriptionTab = ({ user }) => {
  const { stripeCustomer } = user;

  // user has an ongoing subscription
  const hasASubscription =
    stripeCustomer && stripeCustomer.subscriptions.data.length;

  // user has canceled his subscription but still has days left before the end of the period
  const hasACanceledSubscription =
    stripeCustomer &&
    stripeCustomer.subscriptions.data.length &&
    stripeCustomer.subscriptions.data[0].cancel_at_period_end;

  return (
    <div className={style.wrapper}>
      <table className={style.table}>
        <colgroup>
          <col />
          {hasASubscription ? (
            <>
              <col />
              <col className={style["table__col-selected"]} />
            </>
          ) : (
            <>
              <col className={style["table__col-selected"]} />
              <col />
            </>
          )}
        </colgroup>
        <thead>
          <tr>
            <th colSpan="3">
              <div className={style.table__header}>
                <img
                  src={badge}
                  alt="octopulse badge"
                  className={style.table__badge}
                />
                <h2>
                  {hasASubscription ? "Votre forfait PRO" : "Passez PRO !"}
                  <img src={arrow} alt="arrow" className={style.table__arrow} />
                </h2>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={style.table__corner}>
              Choisissez la formule qui vous convient !
            </td>
            <td>
              <div className={style.table__subscription}>
                <p>Starter</p>
                <p>Gratuit</p>
              </div>
            </td>
            <td>
              <div className={style.table__subscription}>
                <p>Pro</p>
                <p>19€ / mois</p>
              </div>
            </td>
          </tr>
          <tr className={style["table__line--no-border"]}>
            <td />
            {hasASubscription ? (
              <>
                <td />
                <td>
                  <div className={style["table__subscription--selected"]}>
                    <p>Votre forfait actuel</p>
                  </div>
                </td>
              </>
            ) : (
              <>
                <td>
                  <div className={style["table__subscription--selected"]}>
                    <p>Votre forfait actuel</p>
                  </div>
                </td>
                <td>
                  <div className={style["table__subscription--cta"]}>
                    {/* see why it must be wrapped in Elements : https://github.com/stripe/react-stripe-elements/issues/38#issuecomment-315870032 */}
                    <Elements>
                      <SubscriptionButton />
                    </Elements>
                    <span>1 mois offert !</span>
                  </div>
                </td>
              </>
            )}
          </tr>

          {/* ----------GENERAL----------*/}

          <tr className={style["table__line--responsive"]}>
            <td colSpan="3">
              <h3>
                <Octopus />
                Général
              </h3>
            </td>
          </tr>
          <tr className={style["table__line--responsive"]}>
            <td colSpan="3">Nombre de projets</td>
          </tr>
          <tr
            className={[
              style["table__line--no-border"],
              style["table__line--title"]
            ].join(" ")}
          >
            <td>
              <h3>
                <Octopus />
                Général
              </h3>
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>Nombre de projets</td>
            <td>1 site</td>
            <td>100 sites</td>
          </tr>

          {/* ----------AUDIT----------*/}

          <tr className={style["table__line--responsive"]}>
            <td colSpan="3">
              <h3>
                <Seo />
                Audit SEO
              </h3>
            </td>
          </tr>
          <tr className={style["table__line--responsive"]}>
            <td colSpan="3">Audit en profondeur</td>
          </tr>
          <tr
            className={[
              style["table__line--no-border"],
              style["table__line--title"]
            ].join(" ")}
          >
            <td>
              <h3>
                <Seo />
                Audit SEO
              </h3>
            </td>
            <td />
            <td />
          </tr>
          <tr className={style["table__line--no-border"]}>
            <td>Audit en profondeur</td>
            <td>100 pages</td>
            <td>1000 pages</td>
          </tr>
          <tr className={style["table__line--responsive"]}>
            <td colSpan="3">Relance d&apos;audit</td>
          </tr>
          <tr className={style["table__line--no-border"]}>
            <td>Relance d&apos;audit</td>
            <td>Tous les mois</td>
            <td>Toutes les semaines</td>
          </tr>
          <tr className={style["table__line--responsive"]}>
            <td colSpan="3">Historique des anciens audits</td>
          </tr>
          <tr className={style["table__line--no-border"]}>
            <td>Historique des anciens audits</td>
            <td>{<CheckMark />}</td>
            <td>{<CheckMark isChecked />}</td>
          </tr>
          <tr className={style["table__line--responsive"]}>
            <td colSpan="3">Rapport détaillé téléchargeable</td>
          </tr>
          <tr>
            <td>Rapport détaillé téléchargeable</td>
            <td>{<CheckMark />}</td>
            <td>{<CheckMark isChecked />}</td>
          </tr>
        </tbody>
      </table>
      {!!hasASubscription && !hasACanceledSubscription && <StopSubscription />}
    </div>
  );
};

export default connect(mapStateToProps)(SubscriptionTab);
