import React, { useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { logout } from "actions/auth";
import { QuestionCircle, Unlock } from "img/icons";
import style from "./Header.module.sass";
import { ArrowDown, Profile, Payment, Notification } from "./_icons";
import { toggleGuidedTour } from "../../../actions/global";
import ArrowShowCart from "./ArrowShowCart";
import { Button, Modal } from "../../index";
import Contact from "../../../containers/Contact";

const Header = ({ dispatch, user, history }) => {
  const modalContact = useRef(null);

  return (
    <div className={style.header}>
      <Modal ref={modalContact}>
        <Contact type="Header" modal={modalContact} />
      </Modal>
      <div className={style.header__left}>
        {user.Account.subscription === "FREE" && (
          <div className={style.left__subscription}>
            <Link to="/settings/subscription">
              <Unlock />
              Passez PRO - 1 mois offert !
            </Link>
          </div>
        )}
      </div>
      <div className={style.header__right}>
        {/* <div className={style.right__gopro}> */}
        {/* <span>Passez PRO</span> */}
        {/* </div> */}
        <div className={style.right__help}>
          <Button
            type={null}
            onClick={() => modalContact.current.openModal()}
            cta
          >
            Nous contacter
          </Button>
        </div>
        <div
          className={style.right__help}
          onClick={() => dispatch(toggleGuidedTour())}
        >
          <QuestionCircle />
        </div>
        <div className={style.right__profile}>
          <div
            className={style["right__profile-container"]}
            onClick={() => history.push("/settings/profile")}
          >
            {/* TODO: remettre photo de profil */}
            {/* <div className={style["right__profile-picture"]}> */}
            {/* <img */}
            {/* src="https://media.licdn.com/dms/image/C4D03AQE2mMBzGSYZEg/profile-displayphoto-shrink_200_200/0?e=1556150400&v=beta&t=Iiv4uB8wfqv27sUtp4sUJgEm4udXCGq-YK9i-3YXraQ" */}
            {/* alt="logo" */}
            {/* /> */}
            {/* </div> */}
            <span>
              {user.firstName && user.lastName ? (
                `${user.firstName} ${user.lastName}`
              ) : (
                <FormattedMessage id="header.myAccount" />
              )}
            </span>
            <ArrowDown />
          </div>
          <nav className={style["right__profile-menu"]}>
            <div className={style["profile-menu__items"]}>
              <Link
                className={style["profile-menu__item"]}
                to="/settings/profile"
              >
                <Profile />
                <FormattedMessage id="header.myProfile" />
              </Link>
              <Link
                className={style["profile-menu__item"]}
                to="/settings/subscription"
              >
                <Payment />
                <FormattedMessage id="header.mySubscription" />
              </Link>
              <Link
                className={style["profile-menu__item"]}
                to="/settings/notifications"
              >
                <Notification />
                <FormattedMessage id="header.myNotifications" />
              </Link>
            </div>
            <div
              onClick={() => dispatch(logout())}
              className={style["profile-menu__disconnect"]}
            >
              <FormattedMessage id="header.logout" />
            </div>
          </nav>
        </div>
      </div>
      <ArrowShowCart />
    </div>
  );
};

const mapStateToProps = state => ({
  cart: state.cart,
  user: state.user
});

export default withRouter(connect(mapStateToProps)(Header));
