import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import arrow from "img/icons/arrow.svg";
import style from "./GuidedTour.module.sass";
import Dashboard from "./Dashboard";
import SEO from "./SEO";
import SEA from "./SEA";
import Netlinking from "./Netlinking";
import Ranking from "./Ranking";
import Competitors from "./Competitors";
import Welcome from "./Welcome";
import Button from "../Button";
import { toggleGuidedTour } from "../../actions/global";
import { CloseIcon } from "../../img/icons";

const MAX_STEP = 7;

const Buttons = ({ step, setStep, dispatch, goToSavePath }) => {
  if (step > 0)
    return (
      <div className={style.buttons}>
        {step < MAX_STEP - 1 && (
          <Button cta onClick={() => setStep(step + 1)}>
            Continuer
          </Button>
        )}
        {step === MAX_STEP - 1 && (
          <Button
            cta
            onClick={() => {
              dispatch(toggleGuidedTour());
              goToSavePath();
            }}
          >
            Terminer
          </Button>
        )}
        <span className={style.buttons__back} onClick={() => setStep(step - 1)}>
          Revenir en arrière
        </span>
      </div>
    );
  return null;
};

const GuidedTour = ({ dispatch, location, history }) => {
  const [step, setStep] = useState(0);
  const [saveCurrentPath, setSaveCurrentPath] = useState("");
  const arrowPosition = `${145 + (step - 1) * 50}px`;

  // save the current path so we can use it when the tour is finished
  useEffect(() => {
    localStorage.setItem("guidedTourHasBeenShown", "true");
    setSaveCurrentPath(location.pathname);
  }, []);

  // scroll to top when step changes
  useEffect(() => {
    document.getElementById("guided-tour").scrollTop = 0;
  }, [step]);

  const getStep = () => {
    switch (step) {
      case 0:
        return (
          <Welcome
            goToSavePath={() => history.push(saveCurrentPath)}
            goToNextPage={() => setStep(step + 1)}
          />
        );
      case 1:
        return <Dashboard />;
      case 2:
        return <SEO />;
      case 3:
        return <SEA />;
      case 4:
        return <Netlinking />;
      case 5:
        return <Ranking />;
      default:
        return <Competitors />;
    }
  };

  return (
    <div className={style.container} id="guided-tour">
      {step > 0 && (
        <img
          style={{ top: arrowPosition }}
          className={style.arrow}
          src={arrow}
          alt="arrow"
        />
      )}
      <div className={style["container__step-wrapper"]}>
        <span
          className={style.close}
          onClick={() => dispatch(toggleGuidedTour())}
        >
          <CloseIcon size={20} color={step === 0 ? "white" : "#9AA9AB"} />
        </span>
        {getStep()}
        <Buttons
          goToSavePath={() => history.push(saveCurrentPath)}
          dispatch={dispatch}
          step={step}
          setStep={setStep}
        />
      </div>
    </div>
  );
};

export default withRouter(connect()(GuidedTour));
