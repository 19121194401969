import React from "react";
import { connect } from "react-redux";
import Button from "../../Button";
import style from "./Welcome.module.sass";
import HeaderPage from "../../HeaderPage";
import { toggleGuidedTour } from "../../../actions/global";

const Welcome = ({ goToNextPage, goToSavePath, dispatch }) => (
  <div className={style.wrapper}>
    <HeaderPage
      type="sky"
      title="Bienvenue sur Octopulse !"
      description="Découvrez Octopulse pas à pas grâce à notre visite guidée."
    />
    <div className={style.container}>
      <h3 className={style.container__title}>Comment fonctionne Octopulse ?</h3>
      <p className={style.container__text}>
        Pour classer votre site, les moteurs de recherche envoient régulièrement
        des robots parcourir vos pages.
        <br />
        <br />
        La bonne indexation sur les pages de résultats dépend donc du niveau de
        compréhension qu&apos;ont ces robots de vos pages et leur accessibilité.
        Le robot Octopulse analyse votre site pour déterminer les améliorations
        nécessaires pour être bien compris par ces robots.
        <br />
        <br />
        <br />
        Vous êtes maintenant fin prêt pour commencer notre visite guidée !
      </p>
      <div className={style.buttons}>
        <Button cta onClick={() => goToNextPage()}>
          Commencer la visite guidée
        </Button>
        <span
          onClick={() => {
            dispatch(toggleGuidedTour());
            goToSavePath();
          }}
          className={style.buttons__quit}
        >
          Je ne suis pas intéressé
        </span>
      </div>
    </div>
  </div>
);

export default connect()(Welcome);
