import React from "react";
import { Arrow, KpiTile } from "components";
import { connect } from "react-redux";
import optimizations from "./_icons/optimizations.svg";
import performance from "./_icons/performance.svg";
import impact from "./_icons/impact.svg";
import style from "./Kpis.module.sass";
import { getModuleFromJson } from "../../../../utils";

const getNumOptims = optims =>
  optims.filter(optim => {
    const { score, TechnoOptimisations } = optim;
    const { minSuccessScore } = TechnoOptimisations[0];

    return score < minSuccessScore;
  }).length;

const Kpis = ({ module }) => {
  const numOptims = getNumOptims(module.optims);

  const getImpact = () => {
    const impactValue = getModuleFromJson(module.name).impact;

    if (impactValue > 0.5) return "Fort";
    return "Modéré";
  };
  return (
    <div className={style.wrapper}>
      <div className={style.kpis}>
        <KpiTile
          icon={performance}
          kpi={`${Math.round((1 - module.compareRatio) * 100)}%`}
          kpiText="des sites ne l'ont pas corrigé"
          description={
            numOptims >= 1
              ? "Démarquez-vous de la concurrence !"
              : "Bravo, vous n'en faites plus partie !"
          }
        />
        <KpiTile
          icon={optimizations}
          kpi={numOptims}
          kpiText={
            numOptims > 1 ? "améliorations restantes" : "amélioration restante"
          }
          description={
            numOptims >= 1
              ? `Encore ${numOptims} ${
                  numOptims > 1 ? "améliorations" : "amélioration"
                } à valider.`
              : "Félicitations, vous avez tout validé !"
          }
        />
        <KpiTile
          icon={impact}
          kpi={getImpact()}
          kpiText="impact"
          description={`L'impact sur votre position ${
            numOptims >= 1 ? "sera" : "est"
          } important !`}
        />
        {numOptims > 0 && (
          <div className={style.kpis__arrow}>
            <Arrow
              text="Démarquez-vous de la concurrence en validant cette optimisation !"
              width={270}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ module: state.audit.module });

export default connect(mapStateToProps)(Kpis);
