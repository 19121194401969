import React, { forwardRef } from "react";

// Assets
import style from "./Input.module.sass";
import { Check } from "../../img/icons/index";

// Components
import Loading from "../Loading/Loading";

export type Ref = HTMLInputElement;

interface OwnProps {
  error?: string;
  loading?: boolean;
  isValid?: boolean;
}

type Props = OwnProps & React.InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<Ref, Props>(
  ({ children, error, loading, isValid, ...inputProps }, ref) => {
    const getStyle = () => {
      if (inputProps.disabled) return { color: "#9AA9AB" };
      if (isValid) return { borderBottom: "1px solid #27A49B" };
      return {};
    };

    return (
      <label htmlFor={inputProps.id} className={style.label}>
        <input
          {...inputProps}
          className={[
            error ? style["input--error"] : style.input,
            inputProps.className
          ].join(" ")}
          ref={ref}
          style={getStyle()}
        />

        {error && <span className={style["error-message"]}>{error}</span>}

        {loading && (
          <span className={style.loading}>
            <Loading size={10} />
          </span>
        )}

        {isValid && (
          <span className={style.valid}>
            <Check />
          </span>
        )}
      </label>
    );
  }
);

export default Input;
