import React, { FC, ReactNode, useMemo } from "react";
import { useTable } from "headless-react-table";
import styles from "./Table.module.sass";

interface TableProps {
  data: any[];
  columns: { Header: string; accessor: string }[];
  loading?: boolean;
  colgroup?: ReactNode;
  noDataText?: string;
  /**
   * Exemple :
   * <colgroup>
   *   <col style={{ width: "50%" }} />
   *   <col style={{ width: "25%" }} />
   *   <col style={{ width: "25%" }} />
   * </colgroup>
   */
}

const Table: FC<TableProps> = ({
  data: tableData,
  columns: tableColumns,
  loading,
  colgroup,
  noDataText
}) => {
  const columns = useMemo(() => tableColumns, []);
  const data = useMemo(() => tableData, [tableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data });

  return (
    <div className={styles.table__wrapper}>
      {loading && (
        <div
          className={[
            styles.loading,
            loading ? styles["loading--show"] : styles["loading--hide"]
          ].join(" ")}
        >
          Chargement...
        </div>
      )}
      <table {...getTableProps()} className={styles.table}>
        {colgroup}
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Check if data exists or is empty */}
        {!loading && (!data || (data && !data.length)) ? (
          <tbody>
            <tr>
              <td className={styles["no-data"]}>
                {noDataText || "Aucune donnée"}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps}>
            {rows.map(row => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Table;
