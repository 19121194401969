import React, { FunctionComponent, useEffect, useState } from "react";
import classnames from "classnames";

// API client
import { NavLink } from "react-router-dom";
import api from "../../api/axios";

// Hooks
import useScript from "../../hooks/useScript";
import styles from "./LocalSeo.module.scss";

const tabs = [
  {
    name: "Mes établissements",
    to: "businesses"
  },
  {
    name: "Ma diffusion",
    to: "presenceManagement"
  },
  {
    name: "Avis",
    to: "reviewManagement"
  },
  {
    name: "Statistiques diffusion",
    to: "analytics"
  },
  {
    name: "Statistiques avis",
    to: "reviewAnalytics"
  },
  {
    name: "Google Posts",
    to: "googlePosts"
  },
  {
    name: "Connexions",
    to: "partnerConnections"
  }
];

const PartooIframe: FunctionComponent = () => {
  const partooStatus = useScript(
    "https://static.partoo.co/javascript/build/partoo.js"
  );
  const [partooPage, setPartooPage] = useState<any>(undefined);
  const [activeTab, setActiveTab] = useState("businesses");

  useEffect(() => {
    (async () => {
      if (partooStatus === "ready") {
        setPartooPage(
          // @ts-ignore
          // eslint-disable-next-line no-undef
          Partoo.init("partoo", {
            // startPage: "partnerConnections", // here the user will be redirected to the presenceManagement view after logging
            displayIntercom: false,
            displaySideBar: false,
            displayPartnerConnexionBanner: false,
            displayUserParams: false,
            displayAddButton: false
          })
        );
      }
    })();
  }, [partooStatus]);

  useEffect(() => {
    (async () => {
      if (partooPage) {
        const {
          data: { token }
        } = await api.get<{ token: string }>("/partoo/token");
        partooPage.login(token);
      }
    })();
  }, [partooPage]);

  return (
    <div className={styles.content}>
      <div className={styles.tabs}>
        {tabs.map(({ name, to }, idx) => (
          <NavLink
            className={classnames(
              activeTab === to && styles.activeTab,
              styles.tab
            )}
            key={idx}
            to="/local/iframe"
            onClick={e => {
              if (partooPage) {
                e.preventDefault();
                partooPage.navigate(to);
                setActiveTab(to);
              }
            }}
          >
            {name}
          </NavLink>
        ))}
      </div>
      <div id="partoo" className={styles["iframe-container"]} />
    </div>
  );
};

export default PartooIframe;
