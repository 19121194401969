import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SeaSelection from "./SeaSelection";
import SeaDashbord from "./SeaDashboard";
import { getAdsCampaigns, resetAdsCampaigns } from "../../actions/sea";
import { LoadingPage } from "../../components";

const Sea: FC = () => {
  const { websites, adsCampaigns } = useSelector((state: any) => state);
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns]: any = useState();
  const dispatch: any = useDispatch();

  useEffect(() => {
    // reset ads campaigns state if current website is not linked to the current adCampaign in state
    if (
      adsCampaigns.current &&
      Number(adsCampaigns.current.websiteId) !== websites.current.id
    ) {
      dispatch(resetAdsCampaigns());
    }

    dispatch(getAdsCampaigns()).then(res => {
      setCampaigns(res.adsCampaigns);
      setLoading(false);
    });
  }, []);

  if (loading) return <LoadingPage />;
  return campaigns && campaigns.length && campaigns[0].datastudioId ? (
    <SeaDashbord />
  ) : (
    <SeaSelection />
  );
};

export default Sea;
