import React from "react";
import { connect } from "react-redux";
import style from "./Dashboard.module.sass";
import SeoBox from "./SeoBox";
import SeaBox from "./SeaBox";
import NetlinkingBox from "./NetlinkingBox";
import DokeyBox from "./DokeyBox";

const Dashboard = () => (
  <div className={style.wrapper}>
    <h2>Vue d&apos;ensemble</h2>
    <div className={style.container}>
      <DokeyBox />
      <SeoBox />
      <SeaBox />
      <NetlinkingBox />
    </div>
  </div>
);

const mapStateToProps = state => ({ websites: state.websites });

export default connect(mapStateToProps)(Dashboard);
