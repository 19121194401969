import React from "react";
import screenshot from "./screenshot4.png";
import Template from "../_stepTemplate";

const Step2 = () => (
  <Template
    img={screenshot}
    text="Grâce à notre analyse concurrentielle, vous allez pouvoir déterminer
    les sites se rapprochant de votre domaine d'activité et vous comparer à eux
    !"
    title="Mes concurrents"
  />
);

export default Step2;
