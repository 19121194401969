import {
  TOGGLE_GUIDED_TOUR,
  TOGGLE_ARROW_CART,
  GET_SUBSCRIPTIONS_SUCCESS,
  SET_REDIRECT_URL,
  SET_INTL
} from "../actions/global";

const initialState = {
  isGuidedTourShown: !localStorage.getItem("guidedTourHasBeenShown"),
  isArrowCartShown: false
};

const ranking = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_GUIDED_TOUR:
      return { ...state, isGuidedTourShown: !state.isGuidedTourShown };
    case TOGGLE_ARROW_CART:
      return { ...state, isArrowCartShown: action.bool };
    case SET_REDIRECT_URL:
      return { ...state, redirectUrl: action.url };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return { ...state, subscriptions: action.subscriptions };
    case SET_INTL:
      return { ...state, intl: action.intl };
    default:
      return state;
  }
};

export default ranking;
