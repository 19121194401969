import React from "react";

export const ShoppingCart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M23.663,6.763A1,1,0,0,0,22.7,5.5H5.547A.249.249,0,0,1,5.3,5.3l-.572-2.72A3.264,3.264,0,0,0,1.551,0a1.25,1.25,0,0,0,0,2.5.752.752,0,0,1,.733.6L5.45,18.169A3.252,3.252,0,0,0,6.687,20.1a.249.249,0,0,1,.013.389,2,2,0,1,0,3.1.623.248.248,0,0,1,.011-.243.252.252,0,0,1,.213-.118h5.97a.25.25,0,0,1,.224.361A2,2,0,0,0,18.008,24a2,2,0,0,0,1.785-2.9.241.241,0,0,1,.215-.35,1.25,1.25,0,0,0,0-2.5H8.631a.753.753,0,0,1-.734-.595L7.718,16.8a.25.25,0,0,1,.245-.3H18.717a3,3,0,0,0,2.894-2.211Z" />
  </svg>
);

export const ArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 24 24">
    <path d="M.249,7.207,11.233,19.678h0a1.066,1.066,0,0,0,1.539,0L23.751,7.207a.987.987,0,0,0-.107-1.414l-1.85-1.557a1.028,1.028,0,0,0-1.438.111L12.191,13.8a.25.25,0,0,1-.379,0L3.644,4.346A1.021,1.021,0,0,0,2.948,4a1,1,0,0,0-.741.238L.356,5.793A.988.988,0,0,0,0,6.478.978.978,0,0,0,.249,7.207Z" />
  </svg>
);

export const Profile = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 24 24">
    <circle cx="12" cy="6.25" r="5.5" />
    <path d="M12,13.25a9.511,9.511,0,0,0-9.5,9.5.5.5,0,0,0,.5.5H21a.5.5,0,0,0,.5-.5A9.511,9.511,0,0,0,12,13.25Z" />
  </svg>
);

export const Payment = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M24,3.805a3.5,3.5,0,0,0-3.5-3.5H3.5A3.487,3.487,0,0,0,1.007,1.351.477.477,0,0,0,.869,1.5,3.483,3.483,0,0,0,0,3.805v13a4.51,4.51,0,0,0,3.306,4.174L14.465,23.6a3.352,3.352,0,0,0,.771.092,2.725,2.725,0,0,0,1.716-.582A2.86,2.86,0,0,0,18,20.805v-2.5h0V11.112a4.262,4.262,0,0,0-3.119-4.1L4.056,4.027a.749.749,0,1,1,.4-1.445s3.149.87,3.5.966a.237.237,0,0,0,.065.009l12.478,0a.75.75,0,1,1,0,1.5H15.265a.25.25,0,0,0-.067.491l.086.023a5.725,5.725,0,0,1,2.9,1.893.254.254,0,0,0,.2.093H20.5a.75.75,0,0,1,0,1.5H19.462a.252.252,0,0,0-.2.1.248.248,0,0,0-.035.224,5.608,5.608,0,0,1,.281,1.73v6.943a.25.25,0,0,0,.25.25H20.5a3.5,3.5,0,0,0,3.5-3.5Zm-8.5,11.5a2,2,0,1,1-2-2A2,2,0,0,1,15.5,15.305Z" />
  </svg>
);

export const Notification = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21,17.5A1.5,1.5,0,0,1,19.5,16V11.138A7.957,7.957,0,0,0,13,3.073V1a1,1,0,0,0-2,0V3.073a7.957,7.957,0,0,0-6.5,8.065V16A1.5,1.5,0,0,1,3,17.5a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z" />
    <path d="M14.236,21H9.764a.25.25,0,0,0-.248.222A2.319,2.319,0,0,0,9.5,21.5a2.5,2.5,0,1,0,5,0,2.319,2.319,0,0,0-.016-.278A.248.248,0,0,0,14.236,21Z" />
  </svg>
);
