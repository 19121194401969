import React from "react";
import style from "./Maintenance.module.sass";
import logo from "../../img/logo.svg";

const Maintenance = () => (
  <div className={style.container}>
    <img src={logo} alt="logo" />
    <h2>Notre site est actuellement en maitenance</h2>
    <h3>Nous serons de retour très prochainement !</h3>
    <span className={style.container__arrow}>
      N&apos;hésitez pas à nous contacter <br />
      si vous avez la moindre question !
    </span>
  </div>
);

export default Maintenance;
