import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

interface StateProps {
  partooId: string;
}

type Props = StateProps;

const LocalSeo: FunctionComponent<Props> = ({ partooId }) => (
  <Redirect to={partooId ? "/local/view" : "/local/landing"} />
);

const mapStateToSeoBoxProps = ({
  websites: {
    current: { id: websiteId }
  },
  user: { partooId }
}) => ({
  websiteId,
  partooId
});

export default connect(mapStateToSeoBoxProps)(LocalSeo);
