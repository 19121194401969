import React from "react";
import { connect } from "react-redux";
import { Input, Button } from "components";
import { Formik } from "formik/dist/index";
import { updateUser } from "actions/user";
import { createLoadingSelector } from "api/selectors";
import Footer from "../../Cart/Footer";
import style from "./Form.module.sass";
import sendinblueCustomTrack from "../../../utils/sendinblueCustomTrack";
import { orderContact } from "../../../actions/checkout";

const Form = ({ user, dispatch, loading, modal, type }) => {
  function validateForm(values) {
    const errors = {};

    // -----FIRSTNAME-----
    if (!values.firstname) errors.firstname = "Champs requis";

    // -----LASTNAME-----
    if (!values.lastname) errors.lastname = "Champs requis";

    // -----PHONE-----
    if (!values.phone) errors.phone = "Champs requis";
    if (
      values.phone &&
      values.phone.length > 0 &&
      !/^((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))$/i.test(
        values.phone
      )
    ) {
      errors.phone = "Numéro de téléphone incorrect";
    }

    return errors;
  }

  return (
    <div className={style.container}>
      <Button
        cta
        className={style.form__button}
        submit
        onClick={() => {
          window.open(
            "https://go.octopulse.io/rdv?utm_source=octopulse-website",
            "_blank"
          );
        }}
        disabled={false}
        id="sea-send"
      >
        Prendre rendez-vous
      </Button>
      <span style={{ margin: "10px 0px" }}>ou</span>
      <span className={style.container__title}>
        Remplissez les informations ci-dessous afin que nous puissions vous
        recontacter :
      </span>
      <Formik
        initialValues={{
          firstname: user.firstName || "",
          lastname: user.lastName || "",
          email: user.email || "",
          phone: user.phone || ""
        }}
        validate={values => validateForm(values)}
        onSubmit={({ firstname, lastname, phone }) => {
          dispatch(updateUser(firstname, lastname, phone)).then(() => {
            dispatch(orderContact(type)).then(() => {
              sendinblueCustomTrack("octopulse_contact", {
                event: {
                  data: {
                    from: type
                  }
                }
              });
              modal.current.closeModal();
            });
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <div className={style["form__input-container"]}>
              <Input
                placeholder="Prénom"
                type="firstname"
                name="firstname"
                id="firstname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstname}
                error={
                  errors.firstname && touched.firstname
                    ? errors.firstname
                    : null
                }
              />
              <Input
                placeholder="Nom"
                type="lastname"
                name="lastname"
                id="lastname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastname}
                error={
                  errors.lastname && touched.lastname ? errors.lastname : null
                }
              />
              <Input
                placeholder="Email"
                type="email"
                name="email"
                id="email"
                value={values.email}
                disabled
              />
              <Input
                placeholder="Téléphone"
                type="phone"
                name="phone"
                id="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={errors.phone && touched.phone ? errors.phone : null}
              />
            </div>
            <Button
              cta
              className={style.form__button}
              submit
              disabled={loading}
              id="sea-send"
            >
              Être recontacté
            </Button>
          </form>
        )}
      </Formik>
      <div style={{ marginTop: "50px" }}>
        <Footer />
      </div>
    </div>
  );
};

const loadingSelector = createLoadingSelector(["UPDATE_USER", "ORDER_SEA"]);

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  user: state.user
});

export default connect(mapStateToProps)(Form);
