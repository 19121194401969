import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import style from "./Layout.module.sass";
import SideBar from "./SideBar";
import Header from "./Header";

export const ToggleContext = React.createContext();

const Layout = ({ children, match, global }) => {
  const [toggle, setToggle] = useState(false);

  function switchToggle(value) {
    if (_.isBoolean(value)) setToggle(value);
    else setToggle(!toggle);
  }

  return (
    <ToggleContext.Provider value={{ toggleValue: toggle, switchToggle }}>
      <div className={style.layout}>
        {/* disable menu when guided tour appears */}
        <div
          className={[
            style.layout__menu,
            global.isGuidedTourShown && style["menu--disable"]
          ].join(" ")}
        >
          <SideBar {...match} />
        </div>
        <div className={style.layout__content}>
          {/* disable header when guided tour appears */}
          <div
            className={[
              style.layout__header,
              global.isGuidedTourShown && style["menu--disable"]
            ].join(" ")}
          >
            <Header />
          </div>
          <div
            id="layout-content"
            className={
              // disable scroll on content when a div is over
              // https://stackoverflow.com/questions/9280258/prevent-body-scrolling-but-allow-overlay-scrolling
              global.isGuidedTourShown || toggle
                ? style["content--no-scroll"]
                : style.content
            }
          >
            {children}
          </div>
        </div>
      </div>
    </ToggleContext.Provider>
  );
};

const mapStateToProps = state => ({ global: state.global });

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default connect(mapStateToProps)(Layout);
