import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import store from "../store";
import { logout } from "../actions/auth";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (extensions) {
          switch (extensions.code) {
            case "UNAUTHENTICATED":
              store.dispatch(logout());
              break;
            default:
              break;
          }
        }
      });
    }
  },
  request: operation => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("jwt");

    // set header for every request
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    });
  },
  cache: new InMemoryCache()
});

export default client;
