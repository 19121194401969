import React from "react";
import { connect } from "react-redux";
import { Input, Button } from "components";
import { Formik } from "formik";
import { toast } from "react-toastify";
import style from "./Profile.module.sass";
import { updateUser } from "../../../../actions/user";
import { createLoadingSelector } from "../../../../api/selectors";

const Profile = ({ user, dispatch, loading }) => {
  function validateForm(values) {
    const errors = {};

    // -----FIRSTNAME-----
    if (!values.firstname) errors.firstname = "Champs requis";

    // -----LASTNAME-----
    if (!values.lastname) errors.lastname = "Champs requis";

    // -----PHONE-----
    if (!values.phone) errors.phone = "Champs requis";
    if (
      values.phone &&
      values.phone.length > 0 &&
      !/^((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))$/i.test(
        values.phone
      )
    ) {
      errors.phone = "Numéro de téléphone incorrect";
    }

    return errors;
  }

  return (
    <>
      <h3>Profil</h3>
      <Formik
        initialValues={{
          firstname: user.firstName || "",
          lastname: user.lastName || "",
          email: user.email || "",
          phone: user.phone || ""
        }}
        validate={values => validateForm(values)}
        onSubmit={({ firstname, lastname, phone }) => {
          dispatch(updateUser(firstname, lastname, phone))
            .then(() => toast.success("Votre profil a été mis à jour"))
            .catch(() => toast.error("Une erreur est survenue"));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <div className={style["form__input-container"]}>
              <Input
                placeholder="Prénom"
                type="firstname"
                name="firstname"
                id="firstname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstname}
                error={
                  errors.firstname && touched.firstname
                    ? errors.firstname
                    : null
                }
              />
              <Input
                placeholder="Nom"
                type="lastname"
                name="lastname"
                id="lastname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastname}
                error={
                  errors.lastname && touched.lastname ? errors.lastname : null
                }
              />
              <Input
                placeholder="Email"
                type="email"
                name="email"
                id="email"
                value={values.email}
                disabled
              />
              <Input
                placeholder="Téléphone"
                type="phone"
                name="phone"
                id="phone"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={errors.phone && touched.phone ? errors.phone : null}
              />
            </div>
            <Button className={style.form__button} submit disabled={loading}>
              Mettre à jour
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

const loadingSelector = createLoadingSelector(["UPDATE_USER"]);

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  user: state.user
});

export default connect(mapStateToProps)(Profile);
