import React from "react";
import screenshot from "./screenshot.png";
import Template from "../_stepTemplate";

const Step1 = () => (
  <Template
    img={screenshot}
    text="Cette page récapitule tout simplement l'ensemble de la plateforme Octopulse. Visualisez rapidement l'état de votre site, de son positionnement sur Google ou même de vos campagnes Adwords grâce à notre dashboard."
    title="Mon dashboard"
  />
);

export default Step1;
