import moment from "moment";

export const GET_COMPETITORS_SUCCESS = "GET_COMPETITORS_SUCCESS";
export const GET_COMPETITORS_FAILURE = "GET_COMPETITORS_FAILURE";
export const getCompetitors = id => (dispatch, getState, axios) => {
  const { maxCompetitors } = getState().user.rights.ranking;
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "GET_COMPETITORS_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/rankings/${id}/competitors/`, {
      params: { from: 0, size: maxCompetitors }
    })
    .then(({ data: competitors }) =>
      dispatch({ type: GET_COMPETITORS_SUCCESS, competitors })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GET_COMPETITORS_FAILURE, error });
      throw error;
    });
};

export const GENERATE_COMPETITORS_SUCCESS = "GENERATE_COMPETITORS_SUCCESS";
export const GENERATE_COMPETITORS_FAILURE = "GENERATE_COMPETITORS_FAILURE";
export const generateCompetitors = id => (dispatch, getState, axios) => {
  const { maxCompetitors } = getState().user.rights.ranking;
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "GENERATE_COMPETITORS_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/rankings/${id}/competitors/related`, {
      params: { from: 0, size: maxCompetitors }
    })
    .then(({ data: generatedList }) =>
      dispatch({ type: GENERATE_COMPETITORS_SUCCESS, generatedList })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GENERATE_COMPETITORS_FAILURE, error });
      throw error;
    });
};

export const LAUNCH_COMPETITORS_PROJECT_SUCCESS =
  "LAUNCH_COMPETITORS_PROJECT_SUCCESS";
export const LAUNCH_COMPETITORS_PROJECT_FAILURE =
  "LAUNCH_COMPETITORS_PROJECT_FAILURE";
export const launchCompetitorsProject = (id, domain) => (
  dispatch,
  getState,
  axios
) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "LAUNCH_COMPETITORS_PROJECT_REQUEST" });
  return axios
    .post(`/websites/${websiteId}/rankings/${id}/competitors`, {
      domain
    })
    .then(({ data: competitors }) =>
      dispatch({ type: LAUNCH_COMPETITORS_PROJECT_SUCCESS, competitors })
    )
    .catch(({ response: error }) => {
      dispatch({ type: LAUNCH_COMPETITORS_PROJECT_FAILURE, error });
      throw error;
    });
};

export const ADD_COMPETITORS_SUCCESS = "ADD_COMPETITORS_SUCCESS";
export const ADD_COMPETITORS_FAILURE = "ADD_COMPETITORS_FAILURE";
export const addCompetitors = (id, domain) => (dispatch, getState, axios) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "ADD_COMPETITOR_REQUEST" });
  return axios
    .post(`/websites/${websiteId}/rankings/${id}/competitors`, { domain })
    .then(({ data: competitors }) =>
      dispatch({ type: ADD_COMPETITORS_SUCCESS, competitors })
    )
    .catch(({ response: error }) => {
      dispatch({ type: ADD_COMPETITORS_FAILURE, error });
      throw error;
    });
};

export const DELETE_COMPETITOR_SUCCESS = "DELETE_COMPETITOR_SUCCESS";
export const DELETE_COMPETITOR_FAILURE = "DELETE_COMPETITOR_FAILURE";
export const deleteCompetitor = (id, competitorId) => (
  dispatch,
  getState,
  axios
) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "DELETE_COMPETITOR_REQUEST" });
  return axios
    .delete(`/websites/${websiteId}/rankings/${id}/competitors/`, {
      data: { competitorId }
    })
    .then(({ data: competitor }) =>
      dispatch({ type: DELETE_COMPETITOR_SUCCESS, competitor })
    )
    .catch(({ response: error }) => {
      dispatch({ type: DELETE_COMPETITOR_FAILURE, error });
      throw error;
    });
};

export const RETRIEVE_COMPETITOR_ID_SUCCESS = "RETRIEVE_COMPETITOR_ID_SUCCESS";
export const RETRIEVE_COMPETITOR_ID_FAILURE = "RETRIEVE_COMPETITOR_ID_FAILURE";
export const retrieveCompetitorId = (id, domain) => (
  dispatch,
  getState,
  axios
) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "RETRIEVE_COMPETITOR_ID_REQUEST" });
  return axios
    .post(`/websites/${websiteId}/rankings/${id}/competitors`, { domain })
    .then(({ data: idCompetitor }) => {
      const { competitorId } = idCompetitor;
      dispatch({ type: RETRIEVE_COMPETITOR_ID_SUCCESS, competitorId });
    })
    .catch(({ response: error }) => {
      dispatch({ type: RETRIEVE_COMPETITOR_ID_FAILURE });
      throw error;
    });
};

export const GET_COMPETITOR_KEYWORDS_COMPARE_SUCCESS =
  "GET_COMPETITOR_KEYWORDS_COMPARE_SUCCESS";
export const GET_COMPETITOR_KEYWORDS_COMPARE_FAILURE =
  "GET_COMPETITOR_KEYWORDS_COMPARE_FAILURE";
export const getCompetitorKeywordsCompare = (
  id,
  competitorId,
  startDate,
  endDate
) => (dispatch, getState, axios) => {
  const { id: websiteId } = getState().websites.current;

  dispatch({ type: "GET_KEYWORDS_COMPARE_BY_COMPETITOR_ID_REQUEST" });
  return axios
    .get(
      `/websites/${websiteId}/rankings/${id}/competitors/${competitorId}/compare`,
      {
        params: {
          date_from: moment(startDate).toISOString(),
          date_to: moment(endDate).toISOString(),
          platform: 0,
          from: 0,
          size: 100
        }
      }
    )
    .then(({ data: keywordsCompare }) =>
      dispatch({
        type: GET_COMPETITOR_KEYWORDS_COMPARE_SUCCESS,
        keywordsCompare
      })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GET_COMPETITOR_KEYWORDS_COMPARE_FAILURE });
      throw error;
    });
};

export const GET_COMPETITOR_SCREENSHOT_SUCCESS =
  "GET_COMPETITOR_SCREENSHOT_SUCCESS";
export const GET_COMPETITOR_SCREENSHOT_FAILURE =
  "GET_COMPETITOR_SCREENSHOT_FAILURE";
export const getCompetitorScreenshot = id => (dispatch, axios) => {
  dispatch({ type: "GET_COMPETITOR_SCREENSHOT_REQUEST" });
  return axios
    .get(
      `https://octopulsefront.blob.core.windows.net/screenshots-staging/audit_${id}.jpeg`,
      {}
    )
    .then(({ data: competitorScreenshot }) =>
      dispatch({
        type: GET_COMPETITOR_SCREENSHOT_SUCCESS,
        competitorScreenshot
      })
    )
    .catch(({ response: error }) => {
      dispatch({ type: GET_COMPETITOR_SCREENSHOT_FAILURE });
      throw error;
    });
};
