import {
  LOGIN_SUCCESS,
  LOGOUT,
  VERIFY_EMAIL_SUCCESS,
  SET_JWT,
  SIGNUP_SUCCESS
} from "../actions/auth";

const initialState = {
  jsonWebToken: localStorage.getItem("jwt") || null
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, ...action.user };
    case SIGNUP_SUCCESS:
      return { ...state, ...action.user };
    case VERIFY_EMAIL_SUCCESS:
      return { ...state, ...action.user };
    case SET_JWT:
      return { ...state, jsonWebToken: action.jsonWebToken };
    case LOGOUT:
      return null;
    default:
      return state;
  }
};

export default auth;
