import React, { FC, useContext } from "react";
import arrow from "img/icons/arrow.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import styles from "./Header.module.scss";
import SelectCampaign from "./SelectCampaign";
import ExtendCampaignModal from "./Modals/ExtendCampaignModal";
import { NetlinkingCampaignStatus } from "../../../enum";
import NetlinkingContext from "./NetlinkingContext";
import useValidationModal from "./Modals/useValidationModal";

interface PublishedArticlesProps {
  publishedNumber: number;
  remainingNumber: number;
}

const PublishedArticles: FC<PublishedArticlesProps> = ({
  publishedNumber,
  remainingNumber
}) => (
  <div className={styles.campaign__right}>
    <p>
      {publishedNumber} article{publishedNumber > 1 && "s"} publié
      {publishedNumber > 1 && "s"}
    </p>
    <p>
      {remainingNumber} restant{remainingNumber > 1 && "s"}
    </p>
  </div>
);

interface CampaignDateProps {
  createdAt: string;
  duration: number;
}

const CampaignDate: FC<CampaignDateProps> = ({ createdAt, duration }) => (
  <p className={styles.campaign__date}>
    <span>
      Se termine{" "}
      {moment()
        .add(duration, "M")
        .fromNow()}
    </span>{" "}
    - commencée {moment(createdAt).fromNow()}
  </p>
);

interface CampaignStatusProps {
  status: string;
}

const CampaignStatus: FC<CampaignStatusProps> = ({ status }) => {
  const { RUNNING, PENDING, FINISHED } = NetlinkingCampaignStatus;

  const getClassnames = classname =>
    [styles.campaign__status, styles[classname]].join(" ");

  switch (status) {
    case RUNNING:
      return (
        <p className={getClassnames("campaign__status--running")}>En cours</p>
      );
    case PENDING:
      return (
        <p className={getClassnames("campaign__status--pending")}>En pause</p>
      );
    case FINISHED:
      return (
        <p className={getClassnames("campaign__status--done")}>Terminée</p>
      );
    default:
      return null;
  }
};

const Header: FC = () => {
  const {
    netlinking: { publications }
  } = useSelector((state: any) => state);
  const { currentCampaign }: any = useContext(NetlinkingContext);
  const [
    extendCampaignWithValidationModal,
    extendCampaignModal
  ] = useValidationModal(ExtendCampaignModal, {
    currentCampaign
  });

  return (
    <header className={styles.container}>
      <div className={styles.campaign}>
        <div className={styles.campaign__left}>
          <div className={styles.campaign__current}>
            <SelectCampaign />
            <CampaignStatus status={currentCampaign.status} />
          </div>
          <CampaignDate
            createdAt={currentCampaign.createdAt}
            duration={currentCampaign.duration}
          />
        </div>
        {publications && (
          <PublishedArticles
            publishedNumber={publications.length}
            remainingNumber={
              currentCampaign.duration * currentCampaign.monthlyArticlesNumber -
              publications.length
            }
          />
        )}
      </div>
      <div
        className={styles.cta}
        onClick={() => extendCampaignModal.current.openModal()}
      >
        <div className={styles.cta__content}>
          <p>
            Prolonger ma
            <br /> campagne
          </p>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      {extendCampaignWithValidationModal}
    </header>
  );
};

export default Header;
