import {
  ADD_ITEMS_TO_CART_SUCCESS,
  CLEAR_SHOPPING_CART,
  GET_SHOPPING_CART_SUCCESS,
  REMOVE_ITEM_FROM_CART_SUCCESS
} from "../actions/cart";

const cart = (state = [], action) => {
  switch (action.type) {
    case GET_SHOPPING_CART_SUCCESS:
      return action.orders;
    case ADD_ITEMS_TO_CART_SUCCESS:
      /**
       * we don't have to add the productModule object inside the array
       * because getShoppingCart is called and return all the new cart
       */
      return state;
    case REMOVE_ITEM_FROM_CART_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));
      const modules = newState[0].products[0].productModules;

      newState[0].products[0].productModules = modules.filter(
        item => +item.id !== +action.id
      );

      return newState;
    }
    case CLEAR_SHOPPING_CART:
      return [];
    default:
      return state;
  }
};

export default cart;
