import React from "react";
import PropTypes from "prop-types";
import arrow from "./arrow.svg";
import style from "./Arrow.module.sass";

const Arrow = ({ text, isRed, width }) => (
  <div className={style.arrow} style={{ maxWidth: `${width}px` }}>
    {text && <span className={isRed && style["arrow__text--red"]}>{text}</span>}
    <img className={style.arrow__img} src={arrow} alt="arrow" />
  </div>
);

Arrow.propTypes = {
  text: PropTypes.string,
  isRed: PropTypes.bool,
  width: PropTypes.number
};

export default Arrow;
