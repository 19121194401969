import React from "react";

export const ShoppingCart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>shopping-cart-empty-1</title>
    <path d="M23.663,6.763A1,1,0,0,0,22.7,5.5H5.547A.249.249,0,0,1,5.3,5.3l-.572-2.72A3.264,3.264,0,0,0,1.551,0a1.25,1.25,0,0,0,0,2.5.752.752,0,0,1,.733.6L5.45,18.169A3.252,3.252,0,0,0,6.687,20.1a.249.249,0,0,1,.013.389,2,2,0,1,0,3.1.623.248.248,0,0,1,.011-.243.252.252,0,0,1,.213-.118h5.97a.25.25,0,0,1,.224.361A2,2,0,0,0,18.008,24a2,2,0,0,0,1.785-2.9.241.241,0,0,1,.215-.35,1.25,1.25,0,0,0,0-2.5H8.631a.753.753,0,0,1-.734-.595L7.718,16.8a.25.25,0,0,1,.245-.3H18.717a3,3,0,0,0,2.894-2.211Z" />
  </svg>
);

export const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 24 24">
    <title>arrow-thick-right-2</title>
    <path d="M23.6,11.289l-9.793-9.7a2.607,2.607,0,0,0-3.679.075,2.638,2.638,0,0,0-.068,3.689l3.871,3.714a.25.25,0,0,1-.173.43H2.135A2.28,2.28,0,0,0,.1,12c0,.815.448,2.51,2,2.51H13.779a.25.25,0,0,1,.177.427l-3.731,3.733a2.66,2.66,0,0,0,3.758,3.754l9.625-9.72A1,1,0,0,0,23.6,11.289Z" />
  </svg>
);

export const AddCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>add-circle</title>
    <path d="M12,23.5A11.5,11.5,0,1,0,.5,12,11.513,11.513,0,0,0,12,23.5ZM6,11h4.75a.25.25,0,0,0,.25-.25V6a1,1,0,0,1,2,0v4.75a.25.25,0,0,0,.25.25H18a1,1,0,0,1,0,2H13.25a.25.25,0,0,0-.25.25V18a1,1,0,0,1-2,0V13.25a.25.25,0,0,0-.25-.25H6a1,1,0,0,1,0-2Z" />
  </svg>
);
