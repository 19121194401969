import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Button, Input } from "components";
import { Formik } from "formik";
import {
  createErrorMessageSelector,
  createLoadingSelector
} from "api/selectors";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import style from "./ResetPassword.module.sass";
import Template from "../_template";
import { resetPassword } from "../../../actions/auth";

const ForgetPassword = ({ dispatch, loading, error, location, history }) => {
  const intl = useIntl();

  // get params in url
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!token) history.push("/");
  }, []);

  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[a-z]).{8,}$/,
        intl.formatMessage({ id: "passwordReset.errors.weakPassword" })
      )
      .required(
        intl.formatMessage({ id: "passwordReset.errors.missingPassword" })
      ),
    repassword: Yup.string()
      .matches(
        /^(?=.*[0-9])(?=.*[a-z]).{8,}$/,
        intl.formatMessage({ id: "passwordReset.errors.weakPassword" })
      )
      .oneOf(
        [Yup.ref("password"), null],
        intl.formatMessage({ id: "passwordReset.errors.passwordsMismatch" })
      )
      .required(
        intl.formatMessage({
          id: "passwordReset.errors.missingPasswordConfirmation"
        })
      )
  });

  if (success)
    return (
      <Template title={intl.formatMessage({ id: "passwordReset.title" })}>
        <div className={style.success}>
          <p>
            <FormattedMessage id="passwordReset.success" />
          </p>
          <Button to="/login">
            <FormattedMessage id="passwordReset.link.login" />
          </Button>
        </div>
      </Template>
    );
  return (
    <Template title={intl.formatMessage({ id: "passwordReset.title" })}>
      <Formik
        initialValues={{ password: "", repassword: "" }}
        validationSchema={ValidationSchema}
        onSubmit={({ password }) => {
          dispatch(resetPassword(password, token)).then(() => {
            setSuccess(true);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form className={style.container} onSubmit={handleSubmit}>
            <Input
              placeholder={intl.formatMessage({
                id: "passwordReset.placeholders.newPassword"
              })}
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={
                errors.password && touched.password ? errors.password : null
              }
            />
            <Input
              placeholder={intl.formatMessage({
                id: "passwordReset.placeholders.passwordConfirmation"
              })}
              type="password"
              name="repassword"
              id="repassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.repassword}
              error={
                errors.repassword && touched.repassword
                  ? errors.repassword
                  : null
              }
            />
            {error && (
              <span className={style.container__error}>
                <FormattedMessage id="passwordReset.errors.generic" />
              </span>
            )}
            <div className={style.container__bottom}>
              <Button submit disabled={loading}>
                <FormattedMessage id="passwordReset.link.updatePassword" />
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Template>
  );
};

const loadingSelector = createLoadingSelector(["RESET_PASSWORD"]);
const errorSelector = createErrorMessageSelector(["RESET_PASSWORD"]);

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  error: errorSelector(state)
});

export default withRouter(connect(mapStateToProps)(ForgetPassword));
