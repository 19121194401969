import {
  GET_AUDITS_SUCCESS,
  GET_MODULES_SUCCESS,
  GET_MODULE_SUCCESS,
  LAUNCH_AUDIT_SUCCESS
} from "../actions/audit";

const audit = (state = {}, action) => {
  switch (action.type) {
    case GET_AUDITS_SUCCESS:
      return { ...state, audits: action.audits };
    case GET_MODULES_SUCCESS:
      return { ...state, modules: action.modules };
    case GET_MODULE_SUCCESS:
      return { ...state, module: action.module };
    case LAUNCH_AUDIT_SUCCESS:
      return { ...state, audits: [action.audit, ...state.audits] };
    default:
      return state;
  }
};

export default audit;
