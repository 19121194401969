import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Icon from "img/modules";
import numbro from "numbro";
import style from "./Tile.module.sass";
import Check from "./_icons";
import { getModuleFromJson } from "../../utils";
import { ShoppingCart } from "../Button/_icons";
import { Lock } from "../../img/icons";

const ProgressBar = ({ progress }) => {
  const progression = Math.floor(progress * 100);
  const isComplete = progression === 100;

  return (
    <React.Fragment>
      <div className={style.card__progressWrapper}>
        <div className={style.card__progressBar}>
          <div
            className={
              isComplete
                ? style["card__progress--complete"]
                : style.card__progress
            }
            style={{ width: `${progression || 0}%` }}
          />
        </div>
      </div>
      {isComplete ? (
        <div className={style.card__progressNumber}>
          <Check />
        </div>
      ) : (
        <div className={style.card__progressNumber}>{progression || 0}%</div>
      )}
    </React.Fragment>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired
};

const Tile = ({
  disabled,
  locked,
  progress,
  history,
  tag,
  inCart,
  price,
  to,
  isHighlight
}) => {
  const isComplete = progress === 1;

  return (
    <div
      className={[
        isComplete ? style["card--complete"] : style.card,
        disabled && style.disabled
      ].join(" ")}
      onClick={() => !disabled && history.push(to)}
      id="module-tile"
    >
      {!isComplete && price && (
        <div
          className={
            disabled ? style["card__price--disabled"] : style.card__price
          }
        >
          {numbro(price / 100).formatCurrency({
            thousandSeparated: true,
            optionalMantissa: true,
            mantissa: 2
          })}
        </div>
      )}
      {inCart && (
        <span className={style.card__cart}>
          <ShoppingCart />
        </span>
      )}
      {locked && (
        <span className={style.card__lock}>
          <Lock />
        </span>
      )}
      {isHighlight && !isComplete && !locked && !disabled && (
        <div className={style.card__ribbon}>Recommandé</div>
      )}
      <div className={style.card__icon}>
        <Icon tag={tag} />
      </div>
      {tag === "content" ? (
        <div className={style.card__title}>Contenu</div>
      ) : (
        <div className={style.card__title}>{getModuleFromJson(tag).title}</div>
      )}
      <ProgressBar progress={progress} />
      <button
        className={
          disabled ? style["card__button--disabled"] : style.card__button
        }
      >
        + de détails
      </button>
    </div>
  );
};

Tile.propTypes = {
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  progress: PropTypes.number,
  tag: PropTypes.string.isRequired,
  inCart: PropTypes.shape({
    accountId: PropTypes.number,
    auditId: PropTypes.number,
    createdAt: PropTypes.string,
    deletedAt: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    moduleId: PropTypes.number,
    updatedAt: PropTypes.string
  }),
  price: PropTypes.number,
  to: PropTypes.string
};

export default withRouter(Tile);
