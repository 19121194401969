import React from "react";
import { connect } from "react-redux";
import Box from "./Box";
import style from "./Dashboard.module.sass";
import { Button, Confetti } from "../../components";
import logoDokey from "../../img/logo-dokey.svg";

const DokeyBox = () => (
  <Box title="Notre outil de rédaction SEO est là !">
    <div className={[style.main, style["main--dokey"]].join(" ")}>
      <Confetti />
      <img src={logoDokey} alt="dokey logo" />
    </div>
    <div
      className={[style.footer, style["footer--text-with-button"]].join(" ")}
    >
      Optimisez vos contenus grâce à notre nouvel outil !
      <Button cta to="/content">
        Tester Dokey
      </Button>
    </div>
  </Box>
);

const mapStateToSeoBoxProps = (state: any) => ({
  websites: state.websites
});

export default connect(mapStateToSeoBoxProps)(DokeyBox);
