import React, { FC } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useValidationModalProps } from "./useValidationModal";
import styles from "./Modal.module.scss";
import { Button, HelpButton, TextArea } from "../../../../components";
import { notifyChangeNetlinkingCampaign } from "../../../../actions/netlinking";

const ExtendCampaignSchema = Yup.object().shape({
  message: Yup.string().min(5, "Votre message est trop court")
});

interface ExtendCampaignModalProps {
  currentCampaign: any;
}

const ExtendCampaignModal: FC<useValidationModalProps &
  ExtendCampaignModalProps> = ({ showValidation, currentCampaign }) => {
  const dispatch: any = useDispatch();
  const {
    loading: { NOTIFY_CHANGE_NETLINKING_CAMPAIGN: loading }
  } = useSelector((state: any) => state);

  return (
    <div className={styles.container}>
      <h1>Prolongation de la campagne</h1>
      <Formik
        initialValues={{
          message: "",
          months: 3
        }}
        validationSchema={ExtendCampaignSchema}
        onSubmit={({ message, months }) => {
          const { id, duration } = currentCampaign;

          dispatch(
            notifyChangeNetlinkingCampaign(id, duration + months, message)
          ).then(() => {
            showValidation();
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.month}>
              <h4>Durée de prologation :</h4>
              <p>
                Je veux prolonger de{" "}
                <input
                  id="months"
                  name="months"
                  type="number"
                  min={1}
                  max={12}
                  value={values.months}
                  onChange={handleChange}
                />{" "}
                <span>mois</span> ma campagne de netlinking.
              </p>
            </div>
            <TextArea
              label="Ajouter un commentaire (facultatif)"
              name="message"
              id="message"
              placeholder="Envie d'ajouter un message à votre demande ?"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.message && touched.message ? errors.message : undefined
              }
            />
            <p className={styles.information}>
              Aucune action ne sera effectuée avant de vous avoir contacté au
              préalable.
            </p>
            <Button submit cta disabled={loading}>
              Prolonger ma campagne de {values.months} mois
            </Button>
            <HelpButton>Besoin d&apos;aide ?</HelpButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ExtendCampaignModal;
