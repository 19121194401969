import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { toast } from "react-toastify";
import OctoScore from "./OctoScore";
import style from "./Header.module.sass";
import { getModulesNotDone } from "../../../../utils";
import AuditContext from "../AuditContext";
import SelectAudit from "./SelectAudit";
import { downloadAuditReport } from "../../../../actions/audit";

const mapStateToProps = state => ({
  modules: state.audit.modules,
  currentWebsite: state.websites.current,
  user: state.user
});

const DownloadReport = withRouter(
  connect(mapStateToProps)(
    ({ text, dispatch, user, currentWebsite, match }) => {
      const { id: websiteId } = currentWebsite;
      const { id: auditId } = match.params;
      const [loading, setLoading] = useState(false);
      const intl = useIntl();
      const isFree = user.Account.subscription === "FREE";

      const downloadReport = (idWebsite, idAudit) => {
        if (!isFree) {
          setLoading(true);

          dispatch(downloadAuditReport(idWebsite, idAudit))
            .then(() =>
              toast.success(
                intl.formatMessage({
                  id: "myOptimisations.results.downloadReport.success"
                })
              )
            )
            .catch(() =>
              toast.error(
                intl.formatMessage({
                  id: "myOptimisations.results.downloadReport.error"
                })
              )
            )
            .finally(() => setLoading(false));
        }
      };

      return (
        <span
          className={[
            style.downloadReport,
            loading && style["downloadReport--loading"],
            isFree && style["downloadReport--disabled"]
          ].join(" ")}
          onClick={() => downloadReport(websiteId, auditId)}
          data-tip={
            isFree
              ? intl.formatMessage({
                  id: "myOptimisations.results.downloadReport.forbidden"
                })
              : null
          }
        >
          {text}
        </span>
      );
    }
  )
);

const Header = ({ modules, currentWebsite }) => {
  const { audit } = useContext(AuditContext);
  const { url } = currentWebsite;
  // +1 for content optimization
  const numOptimizations = getModulesNotDone(modules).length + 1;

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.container__score}>
          <OctoScore score={audit.score} />
        </div>
        <div className={style.container__text}>
          {numOptimizations > 0 ? (
            <>
              <h1>
                <FormattedMessage
                  id="myOptimisations.results.header.title1"
                  values={{ count: numOptimizations }}
                />
              </h1>
              <p>
                <FormattedMessage
                  id="myOptimisations.results.header.message1"
                  values={{ span: text => <DownloadReport text={text} /> }}
                />
              </p>
            </>
          ) : (
            <>
              <h1>
                <FormattedMessage id="myOptimisations.results.header.title2" />
              </h1>
              <p>
                <FormattedMessage
                  id="myOptimisations.results.header.message2"
                  values={{ span: text => <DownloadReport text={text} /> }}
                />
              </p>
            </>
          )}
          <div className={style.text__audit}>
            <FormattedMessage
              id="myOptimisations.results.header.selector"
              values={{
                link: (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    // check if url has a protocol --> http(s)://
                    href={/https?:\/\//.test(url) ? url : `//${url}`}
                    className={style.container__website}
                  >
                    {/* remove last slash & http(s):// */}
                    {url
                      .replace(/^(.+?)\/*?$/, "$1")
                      .replace(/(^\w+:|^)\/\//, "")}
                  </a>
                )
              }}
            />
            <SelectAudit />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect(mapStateToProps)(Header));
