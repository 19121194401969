import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button, Input } from "components";
import {
  createErrorMessageSelector,
  createLoadingSelector
} from "api/selectors";
import style from "./ForgetPassword.module.sass";
import Template from "../_template";
import { resetPasswordByEmail } from "../../../actions/auth";

const ServerErrorMessage = ({ error }) => {
  if (!error) return null;

  if (error.data === "UnknownEmail")
    return (
      <div className={style.error}>
        <FormattedMessage id="passwordForgotten.errors.unknownEmail" />
      </div>
    );

  return (
    <div className={style.error}>
      <FormattedMessage id="passwordForgotten.errors.generic" />
    </div>
  );
};

const ForgetPassword = ({ dispatch, loading, error }) => {
  const intl = useIntl();
  const [success, setSuccess] = useState(false);

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({ id: "passwordForgotten.errors.malformedEmail" })
      )
      .required(
        intl.formatMessage({ id: "passwordForgotten.errors.missingEmail" })
      )
  });

  if (success)
    return (
      <Template title={intl.formatMessage({ id: "passwordForgotten.title" })}>
        <div className={style.success}>
          <p>
            <FormattedMessage id="passwordForgotten.success1" />
          </p>
          <p className={style.spam}>
            <FormattedMessage id="passwordForgotten.success2" />
          </p>
          <Button to="/login">
            <FormattedMessage id="passwordForgotten.link.login" />
          </Button>
        </div>
      </Template>
    );
  return (
    <Template
      title={intl.formatMessage({ id: "passwordForgotten.title" })}
      description={intl.formatMessage({ id: "passwordForgotten.description" })}
    >
      <Formik
        initialValues={{ email: "" }}
        validationSchema={ValidationSchema}
        onSubmit={({ email }) => {
          dispatch(resetPasswordByEmail(email)).then(() => {
            setSuccess(true);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form className={style.container} onSubmit={handleSubmit}>
            <Input
              placeholder={intl.formatMessage({
                id: "passwordForgotten.placeholder.email"
              })}
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email ? errors.email : null}
            />
            <ServerErrorMessage error={error} />
            <div className={style.container__bottom}>
              <Link to="/login" className={style.container__signup}>
                <FormattedMessage id="passwordForgotten.link.login" />
              </Link>
              <Button submit disabled={loading}>
                <FormattedMessage id="passwordForgotten.link.send" />
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Template>
  );
};

const loadingSelector = createLoadingSelector(["RESET_PASSWORD_BY_EMAIL"]);
const errorSelector = createErrorMessageSelector(["RESET_PASSWORD_BY_EMAIL"]);

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  error: errorSelector(state)
});

export default connect(mapStateToProps)(ForgetPassword);
