import React, { FC, MutableRefObject, useRef, useState } from "react";
import { Button, Modal } from "../../../../components";
import styles from "./useValidationModal.module.scss";
import { Check } from "../../../../img/icons";

export interface useValidationModalProps {
  showValidation: () => void;
}

const useValidationModal = (
  PassedComponnent: FC<any>,
  passedProps?: Object
): [FC<any> | JSX.Element, MutableRefObject<any>] => {
  const modalRef = useRef<any>(null);
  const [showValidation, setShowValidation] = useState(false);

  const handleValidation = () => {
    setShowValidation(true);
  };

  const modalComponent = (
    <Modal ref={modalRef} onClose={() => setShowValidation(false)}>
      {showValidation ? (
        <div className={styles.container}>
          <div className={styles.check}>
            <Check />
          </div>
          <h1>Merci !</h1>
          <p>Votre demande a bien été prise en compte !</p>
          <p>Nous vous recontacterons dans les plus brefs délais.</p>
          <Button
            onClick={() => {
              modalRef.current.closeModal();
            }}
          >
            J&apos;ai compris
          </Button>
        </div>
      ) : (
        <PassedComponnent {...passedProps} showValidation={handleValidation} />
      )}
    </Modal>
  );

  return [modalComponent, modalRef];
};

export default useValidationModal;
