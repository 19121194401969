import React from "react";
import style from "./NotificationsTab.module.sass";
import Box from "../Box";
import Email from "./Email";

const NotificationsTab = () => (
  <div className={style.wrapper}>
    <Box>
      <Email />
    </Box>
  </div>
);

export default NotificationsTab;
