import {
  GET_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  GET_USER_EMAIL_PREFERENCE_SUCCESS
} from "../actions/user";

const user = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return { ...state, ...action.user };
    case UPDATE_USER_SUCCESS:
      return { ...state, ...action.user };
    case GET_USER_EMAIL_PREFERENCE_SUCCESS:
      return { ...state, emailPreference: action.userEmailPreference };
    default:
      return state;
  }
};

export default user;
