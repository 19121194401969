import React, { FC } from "react";
import { Link } from "react-router-dom";
import Box from "./Box";
import style from "./Dashboard.module.sass";
import men from "../../img/illustrations/men.svg";

const NetlinkingBox: FC = () => (
  <Box title="Gagnez en notoriété rapidement">
    <div className={[style.main, style["main--netlinking"]].join(" ")}>
      <img className={style.main__illu} src={men} alt="men" />
      <p>
        Le netlinking peut apporter une croissance de{" "}
        <span>30% de votre trafic naturel dans les 6 mois</span>.
      </p>
    </div>
    <Link
      to="/netlinking"
      className={[style.footer, style["footer--button"]].join(" ")}
    >
      Faire une campagne de netlinking
    </Link>
  </Box>
);

export default NetlinkingBox;
