import React, { FunctionComponent, MutableRefObject, Ref } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

// Assets
import classes from "./AskEstimate.module.css";

// Components
import { HelpButton, Input } from "../../../../components";
import Button from "../../components/Button";

// Redux
import { orderLocalSEO as orderLocalSEOActionCreator } from "../../../../actions/checkout";
import { updateUser as updateUserActionCreator } from "../../../../actions/user";
import sendinblueCustomTrack from "../../../../utils/sendinblueCustomTrack";

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

interface StateProps {
  user: {
    firstName: string;
    lastName: string;
    phone: string;
  };
}

interface DispatchProps {
  orderLocalSEO: () => Promise<any>;
  updateUser: (
    firstName: string,
    lastName: string,
    phone: string
  ) => Promise<any>;
}

interface OwnProps {
  modal: MutableRefObject<any>;
}

type Props = StateProps & DispatchProps & OwnProps;

const AskEstimate: FunctionComponent<Props> = ({
  modal,
  orderLocalSEO,
  user,
  updateUser
}) => {
  const { handleSubmit, register } = useForm<FormData>({
    defaultValues: user
  });

  const onSubmit = ({ firstName, lastName, phone }) => {
    updateUser(firstName, lastName, phone).then(() =>
      orderLocalSEO().then(() => {
        modal.current.closeModal();
        sendinblueCustomTrack("octopulse_local_order", {});
      })
    );
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.header}>
        <p>
          Veuillez remplir et/ou vérifier les informations ci-dessous afin que
          nous puissions vous contacter
        </p>
      </div>

      <Input
        name="firstName"
        placeholder="Prénom"
        ref={register({ required: true })}
      />
      <Input
        name="lastName"
        placeholder="Nom"
        ref={register({ required: true })}
      />
      <Input
        disabled
        name="email"
        placeholder="Email"
        ref={register({ required: true })}
      />
      <Input
        name="phone"
        placeholder="Téléphone"
        ref={register({ required: true })}
      />

      <div className={classes.footer}>
        <Button emphasized>Envoyer ma demande de devis</Button>
        <HelpButton id="local-seo-help">Besoin d&apos;aide ?</HelpButton>
      </div>
    </form>
  );
};

const mapState = ({ user }) => ({
  user
});

const mapDispatch = {
  orderLocalSEO: orderLocalSEOActionCreator,
  updateUser: updateUserActionCreator
};

export default connect(mapState, mapDispatch)(AskEstimate);
