import React, { FC, useContext } from "react";
import styles from "./DurationSelection.module.sass";
import RangeSlider from "../../../components/RangeSlider/index";
import NetlinkingContext from "./NetlinkingContext";

const MIN_DURATION = 3;
const MAX_DURATION = 12;

const DurationSelection: FC = () => {
  const { duration, setDuration } = useContext(NetlinkingContext);

  const formatDuration = (num: number) => {
    if (num >= 12) return "+12";
    return num;
  };

  return (
    <div className={styles.container}>
      <p className={styles.container__title}>Durée de la campagne</p>
      <div className={styles.container__slider}>
        <p>Faites glisser le curseur</p>
        <RangeSlider
          min={MIN_DURATION}
          max={MAX_DURATION}
          defaultValue={6}
          onChange={value => setDuration(value)}
        />
      </div>
      <div className={styles.result}>
        <p>Campagne sur</p>
        <p>{formatDuration(duration)} mois</p>
      </div>
    </div>
  );
};

export default DurationSelection;
