import React from "react";
import PropTypes from "prop-types";
import style from "./CrawlingPage.module.sass";
import illustration from "../../img/illustrations/octo-analysing.svg";

const CrawlingPage = ({ title, description }) => (
  <div className={style.wrapper}>
    <div className={style.container}>
      <img
        className={style.container__illustration}
        src={illustration}
        alt="analysing"
      />
      <h3>{title}</h3>
      <span>{description}</span>
    </div>
  </div>
);

CrawlingPage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default CrawlingPage;
