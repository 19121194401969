import React, { FunctionComponent, useEffect } from "react";

// Assets
import styles from "./Content.module.scss";
import dokeyIllu from "./dokey-illu.svg";

// Components
import { CtaBanner, HeaderPage } from "../../components/index";
import Button from "../../components/Button/index";

const Content: FunctionComponent = () => {
  useEffect(() => {
    const trustbox = document.getElementById("trustbox");
    // @ts-ignore
    window.Trustpilot.loadFromElement(trustbox);
  }, []);

  return (
    <>
      <HeaderPage
        type="content"
        title="Découvrez Dokey, notre outil de rédaction SEO !"
        description="Laissez-vous guider et atteignez la 1ère page de Google grâce à des contenus optimisés pour votre SEO."
      />
      <div className={styles.container}>
        <section>
          <h3>Comment Dokey optimise-t-il vos contenus ?</h3>
          <div className={styles.intro}>
            <div className={styles.intro__img}>
              <img src={dokeyIllu} alt="dokey illustration" />
            </div>
            <div className={styles.intro__text}>
              <p>
                Le constat est simple,{" "}
                <span className={styles.highlight}>le contenu</span> est devenu{" "}
                <span className={styles.highlight}>
                  le pilier le plus important
                </span>{" "}
                pour un bon référencement.
              </p>
              <p>
                Après 3 ans de R&D, nous avons donc décidé de concevoir Dokey,
                l&apos;outil d&apos;aide à la rédaction de contenu SEO{" "}
                <span className={styles.highlight}>
                  le plus puissant du marché
                </span>
                .
              </p>
              <p>
                Maximisez le SEO de chacun de vos articles afin d’être visible
                sur de nombreuses recherches et ainsi vous assurer un trafic
                grandissant.
              </p>
              <div className={styles.cta}>
                <Button
                  cta
                  onClick={() =>
                    window.open(
                      "https://dokey.io?utm_source=octopulse-app&utm_content=my-content-page",
                      "_blank"
                    )
                  }
                >
                  Tester gratuitement
                </Button>
                <div className={styles.cta__text}>
                  <p>
                    Découvrez notre solution
                    <br />
                    dès maintenant !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.trustpilot}>
          <div
            id="trustbox"
            className="trustpilot-widget"
            data-locale="fr-FR"
            data-template-id="54ad5defc6454f065c28af8b"
            data-businessunit-id="61407496070817001db16819"
            data-style-height="240px"
            data-style-width="100%"
            data-theme="light"
            data-stars="4,5"
            data-review-languages="fr"
          >
            <a
              href="https://fr.trustpilot.com/review/dokey.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </section>
        <section className={styles.citation}>
          <blockquote>
            1450, c&apos;est le nombre moyen de mots pour un contenu qui se
            positionne en 1ère page sur Google.
          </blockquote>
        </section>
        <section>
          <CtaBanner
            textButton="Commencer"
            onClick={() =>
              window.open(
                "https://dokey.io?utm_source=octopulse-app&utm_content=my-content-page",
                "_blank"
              )
            }
          >
            Commencez à optimiser les contenus de votre site dès maintenant !
          </CtaBanner>
        </section>
      </div>
    </>
  );
};

export default Content;
