import React from "react";
import screenshot from "./screenshot.png";
import Template from "../_stepTemplate";

const Step1 = () => (
  <Template
    img={screenshot}
    text="Grâce au netlinking, vous augmenterez la notoriété de votre site aux yeux de Google. Si elle est pertinente et bien implémentée, une campagne de netlinking peut apporter une croissance de 30% de votre trafic naturel dans les 6 mois !"
    title="Mon netlinking"
  />
);

export default Step1;
