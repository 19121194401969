import React from "react";

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M10.5,17.435H10a1,1,0,0,0,0,2h.5a1,1,0,0,0,0-2Z" />
    <path d="M15,17.435H13.5a1,1,0,0,0,0,2H15a1,1,0,1,0,0-2Z" />
    <path d="M19.5,17.435H18a1,1,0,0,0,0,2h1.5a1,1,0,0,0,0-2Z" />
    <path d="M23,17.435h-.5a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
    <path d="M17.923,15.935a.254.254,0,0,0,.207-.11.6.6,0,0,0,.064-.12,1,1,0,0,0-.576-1.291l-5.724-2.192a.25.25,0,0,1,0-.466L23.363,7.277a1,1,0,0,0,.054-1.84,11.021,11.021,0,0,0-10.872.64A14.039,14.039,0,0,0,8.927,9.509a.25.25,0,0,1-.436-.067A12.559,12.559,0,0,1,7.739,5.3h0a3.873,3.873,0,1,0-1.9,3.333.25.25,0,0,1,.369.153,16.588,16.588,0,0,0,1.149,3.089.25.25,0,0,1,0,.22A16.28,16.28,0,0,0,6.2,15.207a.252.252,0,0,1-.37.153A3.868,3.868,0,1,0,7.739,18.7h0a12.594,12.594,0,0,1,.753-4.157.25.25,0,0,1,.2-.165.253.253,0,0,1,.238.1,15.156,15.156,0,0,0,1.148,1.384.25.25,0,0,0,.182.079ZM3.869,7.174A1.87,1.87,0,1,1,5.739,5.3,1.872,1.872,0,0,1,3.869,7.174Zm0,13.391a1.87,1.87,0,1,1,1.87-1.87A1.871,1.871,0,0,1,3.869,20.565Z" />
  </svg>
);
