import React from "react";
import Form from "./Form";
import style from "./Contact.module.sass";

const Contact = ({ modal, type }) => (
  <div className={style.container}>
    <h3 style={{ marginBottom: "20px" }}>Échangez avec nos experts</h3>
    <span>
      Profitez de <b>notre expertise</b> afin d&apos;échanger avec nous sur{" "}
      <b>votre projet d&apos;acquisition digitale</b>.
    </span>
    <br />
    <span>
      Ce premier échange ne vous engage en rien. Il nous permet de mieux{" "}
      <b>comprendre vos besoins</b> afin de vous proposer la solution qui{" "}
      <b>vous correspond</b> au mieux.
    </span>
    <Form modal={modal} type={type} />
  </div>
);

export default Contact;
