import React, { FC } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useValidationModalProps } from "./useValidationModal";
import styles from "./Modal.module.scss";
import { Button, HelpButton, TextArea } from "../../../../components";
import { urlWithoutProtocol } from "../../../../utils";
import { notifyChangeNetlinkingPublication } from "../../../../actions/netlinking";

interface ArticleModificationModalProps {
  url: string;
  currentCampaign: any;
  publication: { url: string; id: number };
}

const ArticleModificationSchema = Yup.object().shape({
  message: Yup.string()
    .min(5, "Votre message est trop court")
    .required("Décrivez la modification à apporter")
});

const ArticleModificationModal: FC<useValidationModalProps &
  ArticleModificationModalProps> = ({
  showValidation,
  currentCampaign,
  publication
}) => {
  const dispatch: any = useDispatch();
  const {
    loading: { NOTIFY_CHANGE_NETLINKING_PUBLICATION: loading }
  } = useSelector((state: any) => state);

  return (
    <div className={styles.container}>
      <h1>Modification d&apos;article</h1>
      <div className={styles.article}>
        <h4>Article à modifier :</h4>
        <a href={publication.url} target="_blank" rel="noopener noreferrer">
          {urlWithoutProtocol(publication.url)}
        </a>
      </div>
      <Formik
        initialValues={{
          message: ""
        }}
        validationSchema={ArticleModificationSchema}
        onSubmit={({ message }) => {
          const { id: currentCampaignId } = currentCampaign;
          const { id: publicationId } = publication;

          dispatch(
            notifyChangeNetlinkingPublication(
              currentCampaignId,
              publicationId,
              message
            )
          ).then(() => {
            showValidation();
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <TextArea
              label="Description de la modification souhaitée"
              name="message"
              id="message"
              placeholder="Que souhaitez-vous modifier ?"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.message && touched.message ? errors.message : undefined
              }
            />
            <p className={styles.information}>
              Aucune modification ne sera effectuée avant de vous avoir contacté
              au préalable.
            </p>
            <Button submit cta disabled={loading}>
              Demander une modification
            </Button>
            <HelpButton>Besoin d&apos;aide ?</HelpButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ArticleModificationModal;
