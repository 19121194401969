import React from "react";

export const Seo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>seo-label</title>
    <path d="M16.235,13.409a.748.748,0,0,0-.531.219L13.583,15.75a.748.748,0,0,0,0,1.06.767.767,0,0,0,1.059,0h0l2.122-2.121a.749.749,0,0,0-.53-1.28Z" />
    <path d="M9.845.647A1.511,1.511,0,0,0,8.785.208H1.708a1.5,1.5,0,0,0-1.5,1.5L.214,8.788a1.493,1.493,0,0,0,.44,1.06L14.161,23.354a1.5,1.5,0,0,0,2.121,0l7.071-7.07a1.5,1.5,0,0,0,0-2.121Zm-4.016,9.6a2.046,2.046,0,0,1-1.452-.6l-1.2-1.2A.75.75,0,0,1,4.238,7.38l1.2,1.2a.553.553,0,0,0,.933-.5L5.937,5.913A2.054,2.054,0,0,1,9.4,4.058l1.2,1.2a.75.75,0,0,1-1.06,1.06l-1.2-1.2a.553.553,0,0,0-.933.5l.433,2.168a2.051,2.051,0,0,1-2.012,2.455Zm5.5,1.8a.746.746,0,0,1-.531-.22l-.707-.707a.25.25,0,0,0-.353,0l-.707.707a.249.249,0,0,0-.073.177.244.244,0,0,0,.073.176l1.414,1.415a.75.75,0,1,1-1.061,1.061L7.968,13.231a1.75,1.75,0,0,1,0-2.474L10.8,7.928a1.755,1.755,0,0,1,2.475,0l1.414,1.415a.75.75,0,1,1-1.06,1.06L12.21,8.989a.25.25,0,0,0-.353,0L11.15,9.7a.25.25,0,0,0,0,.354l.707.707a.75.75,0,0,1-.53,1.28Zm6.5,3.712L15.7,17.871a2.25,2.25,0,0,1-3.182-3.183l2.121-2.121a2.251,2.251,0,0,1,3.184,3.182Z" />
  </svg>
);

export const MoneyBag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>money-bag-euro</title>
    <path d="M9,8h6a1,1,0,0,0,0-2H9A1,1,0,0,0,9,8Z" />
    <path d="M9.471,4.5a.5.5,0,0,0,.5.438h4.064a.5.5,0,0,0,.5-.438,9.165,9.165,0,0,1,1.206-3.049.5.5,0,0,0-.638-.7l-1.862.8a.25.25,0,0,1-.33-.137l-.441-1.1a.5.5,0,0,0-.928,0l-.441,1.1a.25.25,0,0,1-.33.137L8.9.755a.5.5,0,0,0-.638.7A9.165,9.165,0,0,1,9.471,4.5Z" />
    <path d="M15.438,9.121A.591.591,0,0,0,15.081,9H8.919a.585.585,0,0,0-.357.121c-2.551,1.95-5.4,5.4-5.4,8.4C3.16,21.75,5.518,24,12,24s8.84-2.25,8.84-6.482C20.84,14.523,17.99,11.071,15.438,9.121ZM12.964,15.75a.75.75,0,0,1,0,1.5H11.207a.249.249,0,0,0-.244.3c.32,1.458,1.374,2.2,3.144,2.2a.75.75,0,0,1,0,1.5c-2.633,0-4.311-1.4-4.664-3.787A.249.249,0,0,0,9.2,17.25h-.8a.75.75,0,0,1,0-1.5h.8a.25.25,0,0,0,.247-.213c.353-2.384,2.031-3.787,4.664-3.787a.75.75,0,0,1,0,1.5c-1.77,0-2.824.738-3.144,2.2a.25.25,0,0,0,.244.3Z" />
  </svg>
);

export const Trophy = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>award-trophy-star-1</title>
    <path d="M22,3H20.237l.2-1.35A1.5,1.5,0,0,0,18.947,0H5.053A1.5,1.5,0,0,0,3.94.494a1.528,1.528,0,0,0-.374,1.2l.2,1.3H2A2,2,0,0,0,0,5V8a5.019,5.019,0,0,0,2.518,4.342,1,1,0,0,0,.994-1.737A3.009,3.009,0,0,1,2,8V5H4.062l.632,4.209C4.868,10.367,6.352,15.5,12,15.5s7.131-5.131,7.306-6.291L19.937,5H22V8a3.009,3.009,0,0,1-1.512,2.605,1,1,0,0,0,.994,1.737A5.019,5.019,0,0,0,24,8V5A2,2,0,0,0,22,3Zm-6.922,7.274a.524.524,0,0,1-.746.656l-2.2-1.239a.251.251,0,0,0-.246,0l-2.2,1.239a.524.524,0,0,1-.746-.656l.893-2.051a.25.25,0,0,0-.053-.278L8.164,6.357A.489.489,0,0,1,8.508,5.5h1.841a.248.248,0,0,0,.226-.145l.962-2.07a.53.53,0,0,1,.942,0l.961,2.07a.25.25,0,0,0,.227.145h1.84a.489.489,0,0,1,.345.855L14.239,7.945a.249.249,0,0,0-.054.278Z" />
    <path d="M10.784,16.912a.25.25,0,0,0-.2.06.247.247,0,0,0-.086.188v2.433C7.142,20.013,5,21.779,5,23a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1c0-1.221-2.142-2.987-5.5-3.407V17.16a.249.249,0,0,0-.086-.188.254.254,0,0,0-.2-.06,8.445,8.445,0,0,1-2.432,0Z" />
  </svg>
);

export const Competitors = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 24 24">
    <title>multiple-neutral-2</title>
    <path d="M19.932,16.6l-.564-.2a.589.589,0,0,1-.137-.308,2.565,2.565,0,0,1,.058-1.3,5.618,5.618,0,0,0,1.465-4.312A3.661,3.661,0,0,0,17,6.5a3.965,3.965,0,0,0-1.615.348.251.251,0,0,0-.148.221,8.69,8.69,0,0,1-1.69,5.349.252.252,0,0,0-.043.217,5.1,5.1,0,0,0,.258.737.249.249,0,0,0,.142.134c2.434.9,5.6,2.392,5.6,6.994V22a1.576,1.576,0,0,1-.015.218.251.251,0,0,0,.249.282H23.5A.5.5,0,0,0,24,22v-.853C24,18.046,21.839,17.276,19.932,16.6Z" />
    <path d="M18,20.5c0-3.948-2.773-4.935-5.221-5.806l-.739-.266c-.067-.025-.166-.142-.242-.4a3.358,3.358,0,0,1,.081-1.95c.949-1.045,1.876-2.333,1.876-5.536A4.641,4.641,0,0,0,9,1.5,4.641,4.641,0,0,0,4.245,6.545c0,3.2.927,4.491,1.871,5.529A3.336,3.336,0,0,1,6.2,14.031c-.076.255-.175.372-.242.4-.252.093-.507.184-.762.274-2.436.862-5.2,1.84-5.2,5.8V22a.5.5,0,0,0,.5.5h17A.5.5,0,0,0,18,22Z" />
  </svg>
);

export const Advertising = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>advertising-megaphone</title>
    <path d="M23.854,14.293l-2.163-3.561a.25.25,0,0,1-.035-.149l.334-4.154a1,1,0,0,0-.76-1.052L17.182,4.39a.254.254,0,0,1-.131-.081l-2.7-3.174A1,1,0,0,0,13.069.929L9.509,3.092a.248.248,0,0,1-.15.035L5.205,2.793a1.025,1.025,0,0,0-1.052.76L3.069,8a1,1,0,0,0,1.944.474l.831-3.407a.25.25,0,0,1,.263-.189l3.5.281a1.024,1.024,0,0,0,.6-.143l3-1.82a.249.249,0,0,1,.32.052L15.79,5.914a1,1,0,0,0,.524.323l3.407.831a.25.25,0,0,1,.19.263l-.281,3.5a1,1,0,0,0,.142.6l1.821,3a.252.252,0,0,1-.052.32L18.87,17.014a1,1,0,0,0-.323.525l-.831,3.406a.25.25,0,0,1-.263.19l-3.495-.281a1,1,0,0,0-.16,1.994l4.556.366c.028,0,.054,0,.082,0a1,1,0,0,0,.97-.762l.988-4.049a.248.248,0,0,1,.081-.131l3.173-2.7A1,1,0,0,0,23.854,14.293Z" />
    <path d="M.114,19.226a.5.5,0,0,0-.031.6l1.008,1.518a.5.5,0,0,0,.559.2h0l2.257-.675a.249.249,0,0,1,.28.1l.6.91A2.87,2.87,0,0,0,9.9,19.365a.249.249,0,0,1,.182-.324l4.294-.964a.249.249,0,0,1,.263.1l.078.117a.752.752,0,0,0,.626.335.743.743,0,0,0,.414-.125.751.751,0,0,0,.21-1.04L9.588,7.857a.75.75,0,1,0-1.25.83l.071.106a.249.249,0,0,1-.015.3ZM7.95,21.433a1.375,1.375,0,0,1-1.906-.385l-.268-.4a.251.251,0,0,1,.137-.378L8.146,19.6a.251.251,0,0,1,.3.139A1.375,1.375,0,0,1,7.95,21.433Z" />
  </svg>
);

export const Netlinking = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M21.5,5A2.5,2.5,0,1,0,19,2.5a2.426,2.426,0,0,0,.126.766.251.251,0,0,1-.061.255L16.4,6.193a.25.25,0,0,1-.307.037A4.972,4.972,0,0,0,9.533,7.47a.248.248,0,0,1-.3.075L5.074,5.615A.128.128,0,0,1,5,5.5,2.5,2.5,0,1,0,2.5,8a2.463,2.463,0,0,0,1.511-.523.252.252,0,0,1,.259-.029L8.433,9.381a.252.252,0,0,1,.141.27A4.936,4.936,0,0,0,9.233,13.1a.25.25,0,0,1-.037.307L3.522,19.064a.251.251,0,0,1-.255.061A2.437,2.437,0,0,0,2.5,19,2.5,2.5,0,1,0,5,21.5a2.448,2.448,0,0,0-.125-.765.25.25,0,0,1,.061-.256l5.68-5.665a.251.251,0,0,1,.306-.037,4.967,4.967,0,0,0,1.387.579.25.25,0,0,1,.19.243v3.474a.221.221,0,0,1-.12.2,2.5,2.5,0,1,0,2.256.007.249.249,0,0,1-.136-.222V15.6a.25.25,0,0,1,.19-.243,4.982,4.982,0,0,0,2.427-1.437.25.25,0,0,1,.291-.055l1.477.709a.254.254,0,0,1,.141.2,2.492,2.492,0,1,0,.809-2.132.249.249,0,0,1-.275.039l-1.134-.543a.251.251,0,0,1-.132-.3A4.75,4.75,0,0,0,18.5,10.5a4.958,4.958,0,0,0-.725-2.582.251.251,0,0,1,.036-.307l2.669-2.676a.252.252,0,0,1,.255-.061A2.431,2.431,0,0,0,21.5,5Z" />
  </svg>
);

export const SeoLocal = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M15.608,21.684a.5.5,0,0,0-.545-.165,9.936,9.936,0,0,1-7.423-.531.25.25,0,0,1-.14-.225v-1.15A2.5,2.5,0,0,1,8.392,17.7,4.5,4.5,0,0,0,5.5,9.75H2.584a.25.25,0,0,1-.241-.314A9.982,9.982,0,0,1,16.889,3.282a.25.25,0,0,1-.122.468H14.25a2.75,2.75,0,0,0,0,5.5,2.524,2.524,0,0,1,.978.2.5.5,0,0,0,.45-.033A6.468,6.468,0,0,1,22.9,9.81a.5.5,0,0,0,.789-.511A12,12,0,1,0,7.341,23.059l.159.078v-.02a11.928,11.928,0,0,0,8.566.17.5.5,0,0,0,.209-.795C16.063,22.245,15.839,21.974,15.608,21.684Z" />
    <path d="M19,10a5.006,5.006,0,0,0-5,5c0,3.171,4.457,7.664,4.647,7.854a.5.5,0,0,0,.707,0C19.544,22.664,24,18.171,24,15A5.006,5.006,0,0,0,19,10Zm0,6.75A1.75,1.75,0,1,1,20.75,15,1.752,1.752,0,0,1,19,16.75Z" />
  </svg>
);

export const MobileOpenMenu = ({ onClick }) => (
  <svg
    onClick={() => onClick()}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>navigation-menu-3-alternate</title>
    <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z" />
    <path d="M16.5,11h-9a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z" />
    <path d="M16.5,15h-9a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z" />
    <path d="M16.5,7h-9a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z" />
  </svg>
);

export const MobileCloseMenu = ({ onClick }) => (
  <svg
    onClick={() => onClick()}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <title>close</title>
    <path d="M14.3,12.179a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.442L12.177,9.7a.25.25,0,0,1-.354,0L2.561.442A1.5,1.5,0,0,0,.439,2.563L9.7,11.825a.25.25,0,0,1,0,.354L.439,21.442a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,0,0,2.122-2.121Z" />
  </svg>
);

export const Dashboard = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M.793.786A1,1,0,0,0,.5,1.493V12.508a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1v-11a1,1,0,0,0-1-1L1.5.493A1,1,0,0,0,.793.786Z" />
    <path d="M22.5.493l-8,.014a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V1.493a1,1,0,0,0-1-1Z" />
    <path d="M10.207,23.214a1,1,0,0,0,.293-.707v-5a1,1,0,0,0-1-1h-8a1,1,0,0,0-1,1v4.985a1,1,0,0,0,1,1l8,.014A1,1,0,0,0,10.207,23.214Z" />
    <path d="M22.5,10.508h-8a1,1,0,0,0-1,1v11a1,1,0,0,0,1,1l8-.014a1,1,0,0,0,1-1V11.508A1,1,0,0,0,22.5,10.508Z" />
  </svg>
);

export const Quill = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M23.853.149a.5.5,0,0,0-.431-.14A36.56,36.56,0,0,0,18.758,1.15a.506.506,0,0,0-.331.333L17.274,5.272a.248.248,0,0,1-.178.17.252.252,0,0,1-.238-.066l-2.3-2.3a.5.5,0,0,0-.564-.1A23.373,23.373,0,0,0,7.258,7.482C4.683,10.058,3.447,12.5,3.585,14.755a5.578,5.578,0,0,0,1.186,3.062L.293,22.3a1,1,0,0,0,0,1.414,1.014,1.014,0,0,0,1.414,0L12.535,12.882a.75.75,0,1,1,1.061,1.06L7.805,19.734a.249.249,0,0,0,.1.413,6.578,6.578,0,0,0,5.408-.845,16.027,16.027,0,0,0,3.211-2.557C22.307,10.959,23.928,1,23.994.581A.5.5,0,0,0,23.853.149Z" />
  </svg>
);
