import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import style from "./SettingsPages.module.sass";
import { Breadcrumb } from "../../components";
import Tabs from "./Tabs";
import Profile from "./ProfileTab";
import Subscription from "./SubscriptionTab";
import Notifications from "./NotificationsTab";
import Header from "./Header";

const SettingPages = ({ history, match }) => {
  // default redirect to /settings/profile
  useEffect(() => {
    const { url } = match;

    if (url === "/settings") history.push("/settings/profile");
  }, []);

  return (
    <div className={style.wrapper}>
      <Breadcrumb title="Paramètres" />
      <Header />
      <Tabs />
      <Route path="/settings/profile" component={Profile} />
      <Route path="/settings/subscription" component={Subscription} />
      <Route path="/settings/notifications" component={Notifications} />
    </div>
  );
};

export default SettingPages;
