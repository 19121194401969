export default (score: number): string => {
  switch (true) {
    case score >= 90:
      return "A";
    case score >= 80 && score < 90:
      return "B";
    case score >= 70 && score < 80:
      return "C";
    case score >= 60 && score < 70:
      return "D";
    default:
      return "E";
  }
};
