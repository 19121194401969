import React, { FC, useContext, useRef } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./Footer.module.sass";
import NetlinkingContext from "./NetlinkingContext";
import { Button, HelpButton, Modal } from "../../../components/index";
import Contact from "../../Contact";

const ARTICLE_PRICE = 80;

interface FooterProps {
  onClick: () => void;
  loading?: boolean;
}

const Footer: FC<FooterProps> = () => {
  const { duration, articles } = useContext(NetlinkingContext);
  const totalArticles = duration * articles;
  const totalPrice = totalArticles * ARTICLE_PRICE;
  const modalContact = useRef(null);

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.price}>
          <p>Coût estimé :</p>
          <p>{totalPrice}€ HT</p>
        </div>
        <div className={styles.cart}>
          <h4>Votre commande</h4>
          <div className={styles.cart__row}>
            <p>Articles mensuels</p>
            <p>{articles} articles</p>
          </div>
          <div className={styles.cart__row}>
            <p>Durée de la campagne</p>
            <p>{duration} mois</p>
          </div>
          <div
            className={[styles.cart__row, styles["cart__row--total"]].join(" ")}
          >
            <p>Total articles</p>
            <p>{totalArticles} articles</p>
          </div>
        </div>
        <div className={styles.cta}>
          <p>
            Vous souhaitez lancer une campagne de Netlinking ou vous avez besoin
            de plus d&apos;informations ?<br />
            Discutez-en avec nos experts !
          </p>
          <div className={styles.cta__buttons}>
            <Modal ref={modalContact}>
              <Contact type="Netlinking" modal={modalContact} />
            </Modal>
            <Button
              // @ts-ignore
              onClick={() => modalContact.current.openModal()}
              cta
              id="add-to-cart"
            >
              Démarrer ma campagne
            </Button>
            <HelpButton className={styles.cta__question}>
              <FormattedMessage id="netlinking.needHelp" />
            </HelpButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
