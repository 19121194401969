import React, { useContext } from "react";
import { KpiTile } from "components";
import { connect } from "react-redux";
import optimizations from "img/kpis/optimizations.svg";
import performance from "img/kpis/performance.svg";
import traffic from "img/kpis/traffic.svg";
import { useHistory } from "react-router-dom";
import style from "./Kpis.module.sass";
import { getModulesNotDone } from "../../../../utils";
import AuditContext from "../AuditContext";

const Kpis = ({ modules }) => {
  const { audit } = useContext(AuditContext);
  // +1 for content optimization
  const numOptimizations = getModulesNotDone(modules).length + 1;
  const history = useHistory();

  return (
    // This wrapper is used to hide the white circle on the header
    <div className={style.wrapper}>
      <div className={style.kpis}>
        <KpiTile
          icon={performance}
          kpi={`${Math.round(audit.scoreCompareRatio * 100)}%`}
          kpiText="myOptimisations.results.kpi1.title"
          description={
            numOptimizations > 0
              ? "myOptimisations.results.kpi1.description1"
              : "myOptimisations.results.kpi1.description2"
          }
        />
        <KpiTile
          icon={optimizations}
          kpi={numOptimizations}
          kpiText="myOptimisations.results.kpi2.title"
          description={
            numOptimizations > 0
              ? "myOptimisations.results.kpi2.description1"
              : "myOptimisations.results.kpi2.description2"
          }
        />
        <KpiTile
          icon={traffic}
          kpi="Faible"
          kpiText="présence de contenus"
          description="Rédigez plus de contenus pour gagner en trafic !"
          onClick={() => history.push("/content")}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  modules: state.audit.modules
});

export default connect(mapStateToProps)(Kpis);
