import { defineMessages } from "react-intl";
import { toast } from "react-toastify";

export const GET_AUDITS_SUCCESS = "GET_AUDITS_SUCCESS";
export const GET_AUDITS_FAILURE = "GET_AUDITS_FAILURE";
export const getAudits = websiteId => (dispatch, getState, axios) => {
  dispatch({ type: "GET_AUDITS_REQUEST" });
  return axios
    .get(`/websites/${websiteId}/audits`)
    .then(({ data: audits }) =>
      dispatch({
        type: GET_AUDITS_SUCCESS,
        audits
      })
    )
    .catch(({ response: error }) => {
      dispatch({
        type: GET_AUDITS_FAILURE,
        error
      });
      throw error;
    });
};

export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_MODULES_FAILURE = "GET_MODULES_FAILURE";
export const getModules = (websiteId, id = null) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "GET_MODULES_REQUEST" });
  const lastAudit = getState().audit.audits[0];

  return axios
    .get(`/websites/${websiteId}/audits/${id || lastAudit.id}/modules`)
    .then(({ data: modules }) =>
      dispatch({
        type: GET_MODULES_SUCCESS,
        modules
      })
    )
    .catch(({ response: error }) => {
      dispatch({
        type: GET_MODULES_FAILURE,
        error
      });
      throw error;
    });
};

export const GET_MODULE_SUCCESS = "GET_MODULE_SUCCESS";
export const GET_MODULE_FAILURE = "GET_MODULE_FAILURE";
export const getModule = (websiteId, auditId, moduleId) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "GET_MODULE_REQUEST" });

  return axios
    .get(`/websites/${websiteId}/audits/${auditId}/modules/${moduleId}`)
    .then(({ data: module }) =>
      dispatch({
        type: GET_MODULE_SUCCESS,
        module
      })
    )
    .catch(({ response: error }) => {
      dispatch({
        type: GET_MODULE_FAILURE,
        error
      });
      throw error;
    });
};

export const LAUNCH_AUDIT_SUCCESS = "LAUNCH_AUDIT_SUCCESS";
export const LAUNCH_AUDIT_FAILURE = "LAUNCH_AUDIT_FAILURE";
export const launchAudit = websiteId => (dispatch, getState, axios) => {
  const {
    global: {
      intl: { formatMessage }
    }
  } = getState();
  const messages = defineMessages({
    genericError: {
      id: "global.errors.generic"
    }
  });

  dispatch({ type: "LAUNCH_AUDIT_REQUEST" });

  // Create audit in mariadb
  return axios
    .post(`/websites/${websiteId}/audits`)
    .then(({ data: audit }) =>
      // Launch audit job with the audit id
      axios
        .post(`/websites/${websiteId}/audits/${audit.id}/job`)
        .then(({ data: launchedAudit }) =>
          dispatch({
            type: LAUNCH_AUDIT_SUCCESS,
            audit: launchedAudit
          })
        )
        .catch(({ response: error }) => {
          toast.error(formatMessage(messages.genericError));
          dispatch({
            type: LAUNCH_AUDIT_FAILURE,
            error
          });
          throw error;
        })
    )
    .catch(({ response: error }) => {
      toast.error(formatMessage(messages.genericError));
      dispatch({
        type: LAUNCH_AUDIT_FAILURE,
        error
      });
      throw error;
    });
};

export const GET_AUDIT_REPORT_SUCCESS = "GET_AUDIT_REPORT_SUCCESS";
export const GET_AUDIT_REPORT_FAILURE = "GET_AUDIT_REPORT_FAILURE";
export const downloadAuditReport = (websiteId, auditId) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "GET_AUDIT_REPORT_REQUEST" });

  return axios
    .get(`/websites/${websiteId}/audits/${auditId}/export`)
    .then(() => dispatch({ type: GET_AUDIT_REPORT_SUCCESS }))
    .catch(({ response: error }) => {
      dispatch({
        type: GET_AUDIT_REPORT_FAILURE,
        error
      });
      throw error;
    });
};
