import React, { FC } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useValidationModalProps } from "./useValidationModal";
import styles from "./Modal.module.scss";
import { Button, HelpButton, TextArea } from "../../../../components";
import { notifyChangeNetlinkingConfiguration } from "../../../../actions/netlinking";

interface CampaignConfigurationModalProps {
  currentCampaign: any;
  configuration: any;
}

const CampaignConfigurationSchema = Yup.object().shape({
  message: Yup.string()
    .min(5, "Votre message est trop court")
    .required("Décrivez la modification à apporter")
});

const CampaignConfigurationModal: FC<useValidationModalProps &
  CampaignConfigurationModalProps> = ({
  showValidation,
  currentCampaign,
  configuration
}) => {
  const dispatch: any = useDispatch();
  const {
    loading: { NOTIFY_CHANGE_NETLINKING_CONFIGURATION: loading }
  } = useSelector((state: any) => state);

  return (
    <div className={styles.container}>
      <h1>Configuration de la campagne</h1>
      <Formik
        initialValues={{
          message: ""
        }}
        validationSchema={CampaignConfigurationSchema}
        onSubmit={({ message }) => {
          const { id: currentCampaignId } = currentCampaign;
          const { id: configurationId } = configuration;

          dispatch(
            notifyChangeNetlinkingConfiguration(
              currentCampaignId,
              configurationId,
              message
            )
          ).then(() => showValidation());
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit} className={styles.configuration}>
            <TextArea
              label="Description de la configuration souhaitée"
              name="message"
              id="message"
              placeholder="Quelle(s) modification(s) souhaitez-vous apporter à votre campagne ?"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.message && touched.message ? errors.message : undefined
              }
            />
            <p className={styles.information}>
              Aucun changement ne sera effectué avant de vous avoir contacté au
              préalable.
            </p>
            <Button submit cta disabled={loading}>
              Modifier ma campagne
            </Button>
            <HelpButton>Besoin d&apos;aide ?</HelpButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CampaignConfigurationModal;
