import { gql } from "apollo-boost";
import apollo from "../api/apollo";
import { toggleArrowCart } from "./global";
import { ProductType } from "../enum";

export const CLEAR_SHOPPING_CART = "CLEAR_SHOPPING_CART";

export const GET_SHOPPING_CART_SUCCESS = "GET_SHOPPING_CART_SUCCESS";
export const GET_SHOPPING_CART_FAILURE = "GET_SHOPPING_CART_FAILURE";
export const getShoppingCart = () => async dispatch => {
  dispatch({ type: "GET_SHOPPING_CART_REQUEST" });

  try {
    const {
      data: { orders }
    } = await apollo.query({
      query: gql`
        {
          orders(input: { status: CREATED }, limit: 1) {
            id
            products {
              id
              type
              productModules {
                id
                module {
                  id
                  name
                  level
                  depth
                }
                audit {
                  id
                  website {
                    id
                    url
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_SHOPPING_CART_SUCCESS,
      orders
    });
  } catch (error) {
    dispatch({
      type: GET_SHOPPING_CART_FAILURE,
      error
    });
    throw error;
  }
};

export const REMOVE_ITEM_FROM_CART_SUCCESS = "REMOVE_ITEM_FROM_CART_SUCCESS";
export const REMOVE_ITEM_FROM_CART_FAILURE = "REMOVE_ITEM_FROM_CART_FAILURE";
// eslint-disable-next-line consistent-return
export const removeItemFromCart = id => async dispatch => {
  dispatch({ type: "REMOVE_ITEM_FROM_CART_REQUEST" });

  const handleError = error => {
    dispatch({
      type: REMOVE_ITEM_FROM_CART_FAILURE,
      error
    });
    throw error;
  };

  try {
    const {
      data: { deleteProductModule }
    } = await apollo.mutate({
      mutation: gql`
        mutation DeleteProductModule($id: Int) {
          deleteProductModule(id: $id)
        }
      `,
      variables: { id: +id }
    });

    // deleteProductModule is number of deleted rows
    if (deleteProductModule)
      return dispatch({
        type: REMOVE_ITEM_FROM_CART_SUCCESS,
        id
      });

    handleError(deleteProductModule);
  } catch (error) {
    handleError(error);
  }
};

export const ADD_ITEMS_TO_CART_SUCCESS = "ADD_ITEMS_TO_CART_SUCCESS";
export const ADD_ITEMS_TO_CART_FAILURE = "ADD_ITEMS_TO_CART_FAILURE";
/*
 * items --> [{ auditId, moduleId }, { auditId, moduleId }, ...]
 */
export const addItemsToCart = items => async (dispatch, getState) => {
  dispatch({ type: "ADD_ITEMS_TO_CART_REQUEST" });
  const { cart } = getState();

  try {
    let productId;

    if (!cart[0] || !cart[0].products[0]) {
      // product doesn't exist so create one and take its id to create a productModule
      const {
        data: { createProduct: product }
      } = await apollo.mutate({
        mutation: gql`
          mutation CreateProduct($input: ProductInput) {
            createProduct(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: {
            type: ProductType.Optimization
          }
        }
      });

      productId = product.id;
    } else {
      // product already exists
      productId = cart[0].products[0].id;
    }

    // create input array for the resolver --> [ProductModuleInput]
    const arrayToSend = items.map(({ auditId, moduleId }) => ({
      auditId: +auditId,
      moduleId: +moduleId,
      productId: +productId
    }));

    const {
      data: { createProductModules: productModules }
    } = await apollo.mutate({
      mutation: gql`
        mutation CreateProductModules($input: [ProductModuleInput]) {
          createProductModules(input: $input) {
            module {
              id
              name
              level
              depth
            }
            audit {
              website {
                id
                url
              }
            }
          }
        }
      `,
      variables: {
        input: arrayToSend
      }
    });

    // get new shopping cart because it is currently empty
    await dispatch(getShoppingCart());

    dispatch(toggleArrowCart(true));
    return dispatch({
      type: ADD_ITEMS_TO_CART_SUCCESS,
      productModules
    });
  } catch (error) {
    dispatch({
      type: ADD_ITEMS_TO_CART_FAILURE,
      error
    });
    throw error;
  }
};
