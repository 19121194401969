import React from "react";
import screenshot from "./screenshot1.png";
import Template from "../_stepTemplate";

const Step1 = () => (
  <Template
    img={screenshot}
    text="Octopulse détermine les optimisations à mettre en place pour améliorer
    votre position sur les moteurs de recherche. Vous pourrez sélectionner les
    optimisations de votre choix, nous reviendrons vers vous avec une proposition
    d'amélioration. Nos experts se chargeront de les implémenter sur votre site
    en toute sécurité."
    title="Mes optimisations"
  />
);

export default Step1;
