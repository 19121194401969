import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { hotjar } from "react-hotjar";
import _ from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter
} from "react-router-dom";

import { createIntl, createIntlCache, RawIntlProvider } from "react-intl";
import { getUser } from "actions/user";
import { getAudits } from "actions/audit";

import { Layout, ScrollToTop, ErrorBoundary, GuidedTour } from "components";
import Login from "containers/LoginPages/Login";
import Module from "containers/Optimizations/Module";
import Maintenance from "containers/Maintenance";
import Optimizations from "containers/Optimizations";
import OptimizationsResults from "containers/Optimizations/Results";
import Impersonate from "containers/Impersonate";
import Sea from "containers/Sea";
import Signup from "containers/LoginPages/Signup";
import Verify from "containers/LoginPages/Verify";
import ForgetPassword from "containers/LoginPages/ForgetPassword";
import ResetPassword from "containers/LoginPages/ResetPassword";
import Settings from "containers/SettingsPages";
import LogRocket from "logrocket";
import PartooIframe from "./containers/SeoLocal/PartooIframe";
import LocalSeo from "./containers/SeoLocal/LocalSeo";
import LocalSeoLanding from "./containers/SeoLocal/Landing/Landing";
import { getWebsites, setCurrentWebsite } from "./actions/websites";
import { getSubscriptions, setIntl, setRedirectUrl } from "./actions/global";
import Dashboard from "./containers/Dashboard";
import Netlinking from "./containers/Netlinking";
import { getShoppingCart } from "./actions/cart";
import store from "./store";
import style from "./App.module.sass";
import logo from "./img/logo.svg";
import NetlinkingDashboard from "./containers/Netlinking/NetlinkingDashboard";
import Content from "./containers/Content";

const sendPageViewToGA = pathname => {
  window.gtag("event", "page_view", {
    page_path: pathname,
    send_to: "UA-119876152-1"
  });
  window.gtag("event", "page_view", {
    page_path: pathname,
    send_to: "UA-119876152-3"
  });
};

// Hotjar
const hjid = process.env.REACT_APP_HOTJAR_ID;
const hjsv = 6;
hotjar.initialize(hjid, hjsv);

const mapStateToProps = state => ({
  auth: state.auth,
  global: state.global,
  user: state.user
});

const Logged = withRouter(
  connect(mapStateToProps)(({ global, history, user }) => {
    const [loading, setLoading] = useState(true);

    // redirect user to the previous url after login
    useEffect(() => {
      const { redirectUrl } = global;

      if (redirectUrl) history.push(redirectUrl);
    }, []);

    // track page changes
    useEffect(
      () =>
        history.listen(location => {
          const { pathname } = location;

          // send to sendinblue
          window.sendinblue.page(pathname);

          // send to GA
          sendPageViewToGA(pathname);
        }),
      []
    );

    useEffect(() => {
      const onStartPromises = [
        store.dispatch(getShoppingCart()),
        store.dispatch(getSubscriptions()),
        store.dispatch(getUser()),
        store.dispatch(getWebsites())
      ];

      Promise.all(onStartPromises).then(
        // eslint-disable-next-line
        async ([cart, subscription, { user }, { websites }]) => {
          if (user && user.id) {
            LogRocket.identify(user.id, user);
          }

          const {
            Account: { id: accountId }
          } = user;

          const lastSelectedWebsite = JSON.parse(
            localStorage.getItem("lastSelectedWebsite")
          );
          const lastWebsite = websites[websites.length - 1];

          // set default website (last website in array or last selected website during the last session)
          let defaultWebsite;
          if (
            lastSelectedWebsite &&
            lastSelectedWebsite.accountId === accountId
          )
            defaultWebsite = lastSelectedWebsite;
          else defaultWebsite = lastWebsite;

          await store.dispatch(setCurrentWebsite(defaultWebsite));

          // fetch audits
          store
            .dispatch(getAudits(defaultWebsite.id))
            .then(() => setLoading(false));
        }
      );
    }, []);

    // track (or add) user in sendinblue
    useEffect(() => {
      if (user) {
        window.sendinblue.identify(user.email, {
          PRENOM: user.firstName,
          NOM: user.lastName,
          SMS: user.phone,
          productType: "octopulse",
          ...user
        });
      }
    }, [user]);

    const { isGuidedTourShown } = global;

    // display logo when loading
    if (loading)
      return (
        <div className={style.loading}>
          <img src={logo} alt="logo" />
        </div>
      );
    return (
      <>
        {/* <Popup /> */}
        <Layout>
          {isGuidedTourShown && <GuidedTour />}
          {/* scroll to top of the layout content div on every navigation */}
          <ScrollToTop>
            <Switch>
              {/* Redirections */}
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>

              {/* Dashboard */}
              <Route path="/dashboard" component={Dashboard} />

              {/* Audit */}
              <Route path="/audit/:id/:module" component={Module} />
              <Route path="/audit/:id" component={OptimizationsResults} />
              <Route path="/audit" component={Optimizations} />

              {/* Ranking */}
              {/* <Route path="/ranking/:id/:keywordId" component={KeywordPage} /> */}
              {/* <Route path="/ranking/:id" component={RankingResults} /> */}
              {/* <Route path="/ranking" component={Ranking} /> */}

              {/* SEA */}
              <Route path="/sea" component={Sea} />

              {/* Netlinking */}
              <Route path="/netlinking/:id" component={NetlinkingDashboard} />
              <Route path="/netlinking" component={Netlinking} />

              {/* Competitors */}
              {/* <Route */}
              {/*  path="/competitors/:id/:competitorId" */}
              {/*  component={CompetitorsPositionCompare} */}
              {/* /> */}
              {/* <Route path="/competitors/:id" component={CompetitorsScores} /> */}
              {/* <Route path="/competitors" component={Competitors} /> */}

              {/* SEO Local */}
              <Route path="/local/view" component={PartooIframe} />
              <Route path="/local/landing" component={LocalSeoLanding} />
              <Route path="/local" component={LocalSeo} />

              {/* SEA */}
              <Route path="/content" component={Content} />

              {/* Cart */}
              {/* <Route path="/cart" component={Cart} /> */}

              {/* Settings */}
              <Route path="/settings/profile" component={Settings} />
              <Route path="/settings/subscription" component={Settings} />
              <Route path="/settings/notifications" component={Settings} />
              <Route strict path="/settings" component={Settings} />

              {/* Not found */}
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
          </ScrollToTop>
        </Layout>
      </>
    );
  })
);

const NotLoggedRoutes = withRouter(
  connect(mapStateToProps)(({ location, dispatch, global, history }) => {
    // save url before going to login page so we can redirect user when he will be logged
    useEffect(() => {
      const { redirectUrl } = global;
      const { pathname } = location;

      /**
       * value is null only if user disconnect
       * this avoid setting the last url used before disconnecting
       */
      if (!_.isNull(redirectUrl)) dispatch(setRedirectUrl(pathname));
    }, []);

    // track page changes
    useEffect(
      () =>
        history.listen(historyLocation => {
          const { pathname } = historyLocation;

          // send to GA
          sendPageViewToGA(pathname);
        }),
      []
    );

    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/verify" component={Verify} />
        <Route path="/forget" component={ForgetPassword} />
        <Route path="/reset" component={ResetPassword} />
        {/* Not found */}
        <Redirect to="/login" />
      </Switch>
    );
  })
);

const App = ({ dispatch, auth, loading, global }) => {
  const [loadingLocaleData, setLoadingLocaleData] = useState(true);

  // prevents memory leak
  const cache = createIntlCache();

  // Define user local
  // See https://developer.mozilla.org/fr/docs/Web/API/NavigatorLanguage/language
  // TODO Handle the locales names without a specific region (e.g. "fr" and "en")
  const userLocale = ["fr-FR"].includes(navigator.language)
    ? navigator.language
    : "fr-FR";

  // Fetch the translations
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BLOBS_CONTAINER_URL}/${userLocale}/app.json`
      )
      .then(({ data: messages }) => {
        const intl = createIntl(
          {
            locale: userLocale,
            messages
          },
          cache
        );

        dispatch(setIntl(intl));
        setLoadingLocaleData(false);
      })
      .catch(() => {
        // Do not fail the application init outside production environments
        // if the translations cannot be fetched. All text fields will be hydrated
        // with their default values
        if (process.env.NODE_ENV !== "production") {
          setLoadingLocaleData(false);
        } else {
          // TODO Display a proper error
          // setLoadingLocaleData(false);
        }
      });
  }, []);

  // Display a loader until the locale data is fetched
  if (loadingLocaleData)
    return (
      <div className={style.loading}>
        <img src={logo} alt="logo" />
      </div>
    );

  return (
    <RawIntlProvider value={global.intl}>
      <Router>
        <ErrorBoundary>
          <Switch>
            {/* Routes both accessible when you're logged in and not logged in */}
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/impersonate" component={Impersonate} />
            <Route path="/">
              {/* Check if user is logged */}
              {auth && auth.jsonWebToken ? (
                <Logged loading={loading} global={global} />
              ) : (
                <NotLoggedRoutes />
              )}
            </Route>
            {/* Not found */}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </ErrorBoundary>
      </Router>
      <ToastContainer position="top-center" />
    </RawIntlProvider>
  );
};

export default connect(mapStateToProps)(App);
