import {
  GET_WEBSITES_SUCCESS,
  SET_CURRENT_WEBSITE,
  ADD_WEBSITE_SUCCESS
} from "../actions/websites";

const websites = (state = {}, action) => {
  switch (action.type) {
    case GET_WEBSITES_SUCCESS:
      return { ...state, list: action.websites };
    case SET_CURRENT_WEBSITE:
      return { ...state, current: action.website };
    case ADD_WEBSITE_SUCCESS:
      return { ...state, list: [...state.list, action.website] };
    default:
      return state;
  }
};

export default websites;
