import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import style from "./Modal.module.sass";
import { MobileCloseMenu } from "../Layout/SideBar/_icons";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { isModalOpen } = this.state;
    const { onClose, children } = this.props;

    return (
      <ReactModal
        className={style.modal__content}
        overlayClassName={style.modal__overlay}
        isOpen={isModalOpen}
        onRequestClose={this.closeModal}
        contentLabel="Modal Component"
        onAfterClose={onClose}
      >
        <div className={style.modal__closeicon} onClick={this.closeModal}>
          <MobileCloseMenu onClick={this.closeModal} />
        </div>
        {children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default Modal;
