import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import styles from "./NewAuditModal.module.sass";
import InputUrl from "./InputUrl";
import Button from "../../../../Button";

const SubscriptionMessage = ({ modal }) => (
  <div className={styles.subscriptionContainer}>
    <p>
      <FormattedMessage
        id="sidebar.newWebsite.subscription.goPro"
        values={{
          span: text => <span>{text}</span>
        }}
      />
    </p>
    <Button
      cta
      to="/settings/subscription"
      onClick={() => modal.current.closeModal()}
    >
      <FormattedMessage id="sidebar.newWebsite.subscription.button" />
    </Button>
  </div>
);

const NewAuditModal = ({ modal, user }) => {
  const {
    Account: { subscription }
  } = user;
  const isFree = subscription === "FREE";

  return (
    <div className={styles.container}>
      {isFree && <SubscriptionMessage modal={modal} />}
      <div
        className={[styles.wrapper, isFree && styles["wrapper--disabled"]].join(
          " "
        )}
      >
        <h3>
          <FormattedMessage id="sidebar.newWebsite.title" />
        </h3>
        <p className={styles.container__desc}>
          <FormattedMessage id="sidebar.newWebsite.desc" />
        </p>
        <InputUrl modal={modal} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(NewAuditModal);
