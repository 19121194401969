import {
  GET_COMPETITORS_SUCCESS,
  RETRIEVE_COMPETITOR_ID_SUCCESS,
  GET_COMPETITOR_KEYWORDS_COMPARE_SUCCESS,
  GENERATE_COMPETITORS_SUCCESS,
  LAUNCH_COMPETITORS_PROJECT_SUCCESS
} from "../actions/competitors";

const initialState = {};

const competitors = (state = initialState, action) => {
  switch (action.type) {
    case LAUNCH_COMPETITORS_PROJECT_SUCCESS:
      return { ...state, competitors: action.competitors };
    case GET_COMPETITORS_SUCCESS:
      return { ...state, competitors: action.competitors.competitors };
    case RETRIEVE_COMPETITOR_ID_SUCCESS:
      return { ...state, competitorsId: action.competitorsId };
    case GET_COMPETITOR_KEYWORDS_COMPARE_SUCCESS:
      return { ...state, keywordsCompare: action.keywordsCompare };
    case GENERATE_COMPETITORS_SUCCESS:
      return { ...state, generatedList: action.generatedList };
    default:
      return state;
  }
};

export default competitors;
