import React, { FC, MutableRefObject, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import numbro from "numbro";
import style from "./Settings.module.sass";
import { BUDGET_MIN, BUDGET_MAX } from "../SeaSelection";
import RangeSlider from "../../../components/RangeSlider";
import { ThinArrow } from "../../../img/icons";
import { Button } from "../../../components";
import { changeSeaBudget } from "../../../actions/sea";
import { createLoadingSelector } from "../../../api/selectors";
import HelpButton from "../../../components/HelpButton";

interface SettingsProps {
  modal: MutableRefObject<any>;
  loading?: boolean;
}

const Settings: FC<SettingsProps> = ({ modal, loading }) => {
  const dispatch: any = useDispatch();
  const { budget: currentBudget, id: campaignId } = useSelector(
    (state: any) => state.adsCampaigns.list[0]
  );
  const defaultValue =
    currentBudget + 100 <= BUDGET_MAX ? currentBudget + 100 : currentBudget;
  const [budget, setBudget] = useState(defaultValue);

  const changeBudget = () => {
    dispatch(changeSeaBudget(campaignId, budget)).then(() => {
      modal.current.closeModal();
    });
  };

  return (
    <div className={style.container}>
      <h3>Modifiez votre budget mensuel</h3>
      <p>
        Vous pouvez changer votre budget d&apos;achat média mensuel à tout
        moment. Nous vous recontacterons afin de valider votre nouveau budget à
        partir du mois prochain.
      </p>
      <div className={style.budget}>
        <div className={style.budget__slider}>
          <p>Faites glisser le curseur</p>
          <RangeSlider
            min={BUDGET_MIN}
            max={BUDGET_MAX}
            defaultValue={defaultValue}
            onChange={value => setBudget(Math.round(value / 50) * 50)}
          />
        </div>
        <div className={style.budget__result}>
          <p>Nouveau budget</p>
          <div>
            <p>{numbro(currentBudget).format({ thousandSeparated: true })}€</p>
            <ThinArrow />
            <p>
              {budget === BUDGET_MAX && "+"}
              {numbro(budget).format({ thousandSeparated: true })}€/mois
            </p>
          </div>
        </div>
      </div>
      <Button cta onClick={() => changeBudget()} disabled={loading}>
        Changer mon budget
      </Button>
      <HelpButton className={style.question} id="sea-help">
        Besoin d&apos;aide ?
      </HelpButton>
    </div>
  );
};

const loadingSelector = createLoadingSelector(["CHANGE_SEA_BUDGET"]);

const mapStateToProps = state => ({
  loading: loadingSelector(state)
});

export default connect(mapStateToProps)(Settings);
