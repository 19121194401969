import React from "react";
import PropTypes from "prop-types";
import style from "./Loading.module.sass";

const Loading = props => {
  let sizeDot = "30px";

  if (props.size && typeof props.size === "number") {
    sizeDot = `${props.size}px`;
  }

  return (
    <div className={style.donut} style={{ width: sizeDot, height: sizeDot }} />
  );
};

Loading.propTypes = {
  size: PropTypes.number
};

export default Loading;
