import React, { useEffect } from "react";
import { Formik } from "formik";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import style from "./Url.module.sass";
import Button from "../../../../components/Button";
import { setUrlToGoogleAccount } from "../../../../actions/auth";
import { Input } from "../../../../components";
import { createLoadingSelector } from "../../../../api/selectors";
import { isUrlAllowed } from "../../../../utils";

const Url = ({
  dispatch,
  location,
  loading,
  history,
  loadingSetUrlToGoogleAccount
}) => {
  const validateForm = values => {
    const errors = {};

    // -----URL-----
    const urlRegexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+/g;
    if (!values.url) {
      errors.url = "Entrez une URL";
    } else if (isUrlAllowed(values.url)) {
      errors.url = "Vous ne pouvez pas utiliser cette URL";
    } else if (!urlRegexp.test(values.url)) {
      errors.url = "Entrez une URL valide";
    }

    return errors;
  };

  // get url params in url
  const params = new URLSearchParams(location.search);
  const initialUrlValue = params.get("url") || "";
  // these params are only present when user sign up with google through login page
  const gsub = params.get("gsub");
  const accountId = params.get("accountId");

  useEffect(() => {
    // force to test url on render if url is passed in params
    if (initialUrlValue) validateForm({ url: initialUrlValue });
  }, []);

  return (
    <div className={style.container}>
      <h3>Commençons par analyser votre site !</h3>
      <p className={style.container__description}>
        Octopulse va analyser votre site en profondeur afin d&apos;en ressortir
        les améliorations à mettre en place pour booster votre acquisition.
        <br />
        <br />
      </p>
      <Formik
        initialValues={{
          url: initialUrlValue
        }}
        validate={values => validateForm(values)}
        onSubmit={({ url }) => {
          // don't process onboarding if user already has an account (but no website)
          // gsub and accountId are sent when user sign up with google through on login page
          if (gsub && accountId) {
            dispatch(setUrlToGoogleAccount(accountId, gsub, url))
              .then(({ jwt }) => {
                history.push(`/login?jwt=${jwt}`);
              })
              // if we catch an error, we continue the onboarding
              .catch(() => history.push(`/signup/form?url=${url.trim()}`));
          } else history.push(`/signup/form?url=${url.trim()}`);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <label> Veuillez entrer l&apos;URL de votre site</label>
            <Input
              placeholder="https://www.monsite.fr"
              name="url"
              id="url"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.url}
              error={errors.url && touched.url ? errors.url : null}
              loading={loading}
            />
            <div className={style.form__buttons}>
              <Button
                id="signup-validate-url"
                cta
                submit
                disabled={loading || loadingSetUrlToGoogleAccount}
              >
                Valider
              </Button>
              <Link to="/login" className={style.form__link}>
                Déjà un compte ?
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

const loadingSelectorTestUrl = createLoadingSelector(["TEST_URL"]);
const loadingSelectorSetUrlToGoogleAccount = createLoadingSelector([
  "SET_URL_TO_GOOGLE_ACCOUNT"
]);

const mapStateToProps = state => ({
  loading: loadingSelectorTestUrl(state),
  loadingSetUrlToGoogleAccount: loadingSelectorSetUrlToGoogleAccount(state)
});
export default withRouter(connect(mapStateToProps)(Url));
