import React from "react";
import { NavLink } from "react-router-dom";
import style from "./Tabs.module.sass";

const tabs = [
  {
    name: "Profil",
    to: "/settings/profile"
  },
  {
    name: "Abonnement",
    to: "/settings/subscription"
  },
  {
    name: "Notifications",
    to: "/settings/notifications"
  }
];

const Tabs = () => (
  <div className={style.tabs}>
    <div className={style.tabs__items}>
      {tabs.map(({ to, name }, index) => (
        <NavLink
          key={index}
          to={to}
          className={style.tabs__item}
          activeClassName={style["tabs__item--selected"]}
        >
          {name}
        </NavLink>
      ))}
    </div>
  </div>
);

export default Tabs;
