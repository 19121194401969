import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import style from "./Button.module.sass";
import { AddCircle, Arrow, ShoppingCart } from "./_icons";

const Icon = ({ type }) => {
  switch (type) {
    case "add":
      return <AddCircle />;
    case "cart":
      return <ShoppingCart />;
    case null:
      return <></>;
    default:
      return <Arrow />;
  }
};

const Button = ({
  children,
  to,
  onClick,
  className,
  disabled,
  submit,
  id,
  type,
  cta,
  dataTip,
  history
}) => (
  <button
    onClick={() => {
      if (onClick) onClick();
      if (to) history.push(to);
    }}
    className={[
      style.button,
      className,
      cta && style["button--cta"],
      disabled &&
        (cta ? style["button--cta-disabled"] : style["button--disabled"])
    ].join(" ")}
    disabled={disabled}
    type={submit ? "submit" : "button"}
    id={id}
    data-tip={dataTip}
  >
    <span>{children}</span>
    <Icon type={type} />
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  submit: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  cta: PropTypes.bool,
  dataTip: PropTypes.string
};

export default withRouter(Button);
