import React, { useEffect, useRef, useState } from "react";
import numbro from "numbro";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import style from "./SeaSelection.module.sass";
import { Button, HeaderPage, Loading, Modal } from "../../../components";
import Contact from "../../Contact";
import RangeSlider from "../../../components/RangeSlider";
import { estimateCpc } from "../../../actions/sea";
import Box from "./Box";
import HelpButton from "../../../components/HelpButton";
import { SeaSubType } from "../../../enum";

export const BUDGET_MIN = 200;
export const BUDGET_MAX = 1000;
export const FEES = 0.15;

const FORMULAS = [
  {
    type: SeaSubType.Starter,
    title: "Formule sans engagement",
    selected: true,
    items: [
      {
        label: "Commission sur achat média (15%)",
        value: ""
      },
      {
        label: "Frais de configuration (1er mois)",
        value: "150€ HT"
      },
      {
        label: "Rapport de performances",
        value: "Offert"
      }
    ]
  }
];

const SeaSelection = ({ dispatch }) => {
  const [budget, setBudget] = useState(400);
  const [formulas, setFormulas] = useState(FORMULAS);
  const [error, setError] = useState(null);
  const [averageCPC, setAverageCPC] = useState(null);
  const modalContact = useRef(null);

  const getAverageCPC = () => {
    setError(null);

    dispatch(estimateCpc())
      .then(({ estimation }) => {
        setAverageCPC(estimation.data.averageCPC);
      })
      .catch(() => setAverageCPC(0.34));
  };

  useEffect(() => {
    getAverageCPC();
  }, []);

  useEffect(() => {
    const newFormulas = [...formulas];

    newFormulas[0].items[0].value = `${budget * FEES}€ HT`;

    setFormulas(newFormulas);
  }, [budget]);

  const onSelectFormula = index => {
    const newFormulas = formulas.map((formula, i) => ({
      ...formula,
      selected: i === index
    }));

    setFormulas(newFormulas);
  };

  const renderFormulas = () =>
    formulas.map(({ title, subtitle, items, selected }, index) => (
      <Box
        key={index}
        index={index}
        title={title}
        subtitle={subtitle}
        selected={selected}
        onSelect={i => onSelectFormula(i)}
      >
        {items.map(({ label, value }, i) => (
          <div key={i} className={style.formulas__item}>
            <p>{label}</p>
            <p>{value}</p>
          </div>
        ))}
      </Box>
    ));

  const isLoading = !error && !averageCPC;

  return (
    <>
      <HeaderPage
        type="sea"
        title="Gagnez des visiteurs instantanément !"
        description="Associez la puissance de Google Ads avec l’expertise Octopulse pour générer rapidement et facilement du trafic qualifié."
      />
      <div className={style.container}>
        <div className={style.steps}>
          <div className={style.step}>
            <h3 data-step={1} className={style.step__title}>
              Choisissez votre budget d&apos;achat média
            </h3>
            <div className={style.step__budget}>
              {isLoading ? (
                <Loading size={20} />
              ) : (
                <>
                  <div className={style.budget__slider}>
                    <label>Faites glisser le curseur</label>
                    <RangeSlider
                      min={BUDGET_MIN}
                      max={BUDGET_MAX}
                      defaultValue={400}
                      onChange={value => setBudget(Math.round(value / 50) * 50)}
                    />
                  </div>
                  <p className={style.budget__result}>
                    Je paye{" "}
                    <span
                      className={style.budget__number}
                      data-tip={`<p>Budget mensuel modifiable à tout moment.</p>`}
                    >
                      {budget === BUDGET_MAX && "+"}
                      {numbro(budget).format({ thousandSeparated: true })}€
                    </span>{" "}
                    et je reçois au minimum{" "}
                    <span
                      className={style.budget__number}
                      data-tip={`<p>Estimation du nombre de visiteurs que vous recevrez chaque mois.</p>`}
                    >
                      {numbro(Math.floor(budget / averageCPC)).format({
                        thousandSeparated: true
                      })}{" "}
                      visiteurs
                    </span>
                    <sup className={style.budget__star}>*</sup> !
                  </p>
                  <ReactTooltip type="dark" effect="solid" html />
                </>
              )}
            </div>
            {isLoading || (
              <p className={style.annotation}>
                *estimation sur la base des données fournies par Google
              </p>
            )}
          </div>

          {budget === BUDGET_MAX ? (
            <div className={style.contact}>
              <h3>Formule entreprise</h3>
              <p>
                Vous avez un budget supérieur à <b>{BUDGET_MAX}€/mois</b> ?
              </p>
              <p>
                Discutez avec nos experts pour en savoir plus sur la{" "}
                <b>Formule entreprise</b>.
              </p>
              <Button onClick={() => window.$crisp.push(["do", "chat:open"])}>
                Contactez un expert
              </Button>
            </div>
          ) : (
            <div className={style.formulas}>{renderFormulas()}</div>
          )}

          <div className={style.step}>
            <h3 data-step={2} className={style.step__title}>
              Lancement de vos campagnes
            </h3>
            <p className={style.step__description}>
              Nos experts positionnent votre site sur des requêtes générant un
              fort volume de recherche associé à votre activité. Pour maximiser
              votre conversion, les campagnes sont segmentées en fonction de
              votre marché cible (géographie, démographie, horaires, ...).
            </p>
          </div>
          <div className={style.step}>
            <h3 data-step={3} className={style.step__title}>
              Flexibilité de votre budget
            </h3>
            <p className={style.step__description}>
              Vous payez uniquement les pubs sur lesquelles cliquent les
              utilisateurs. Seuls les prospects intéressés par votre activité
              seront comptabilisés. Vous pouvez modifier votre budget d’achat
              média à la fin de chaque mois !
            </p>
          </div>
          <div className={style.step}>
            <h3 data-step={4} className={style.step__title}>
              Mesurer l&apos;impact sur votre trafic
            </h3>
            <p className={style.step__description}>
              En permanence, vous disposez d&apos;un rapport de performances
              pour analyser l&apos;impact des campagnes publicitaires sur votre
              trafic.
            </p>
          </div>
        </div>

        <Modal ref={modalContact}>
          <Contact modal={modalContact} type="SEA" />
        </Modal>
      </div>
      <div className={style.cta}>
        <div className={style.cta__wrapper}>
          <Button
            cta
            onClick={() => modalContact.current.openModal()}
            id="sea-button"
          >
            Démarrer ma campagne
          </Button>
          <HelpButton className={style.question}>
            Besoin d&apos;aide ?
          </HelpButton>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({ currentWebsite: state.websites.current });

export default connect(mapStateToProps)(SeaSelection);
