import store from "../store";

// return the item if found, otherwise undefined
export default moduleId => {
  const {
    cart,
    websites: { current: currentWebsite }
  } = store.getState();
  const productModules =
    cart[0] && cart[0].products[0] ? cart[0].products[0].productModules : [];

  return productModules.find(productModule => {
    const {
      module,
      audit: { website }
    } = productModule;

    return +module.id === +moduleId && +website.id === +currentWebsite.id;
  });
};
