import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import badge from "img/kpis/badge.svg";
import { connect } from "react-redux";
import moment from "moment";
import style from "./Header.module.sass";

const PremiumSign = withRouter(({ user, history }) => {
  const { stripeCustomer } = user;
  const [showButton, setShowButton] = useState(false);

  // handle button state
  const setButtonState = pathname => {
    if (pathname === "/settings/subscription") setShowButton(false);
    else setShowButton(true);
  };

  useEffect(() => {
    setButtonState(history.location.pathname);

    // trigger on page change but not on first render
    return history.listen(location => {
      const { pathname } = location;

      setButtonState(pathname);
    });
  }, []);

  // user has an ongoing subscription
  const hasASubscription =
    stripeCustomer && stripeCustomer.subscriptions.data.length;

  const showSubscribedContent = () => {
    // user has canceled his subscription but still has days left before the end of the period
    const hasACanceledSubscription =
      stripeCustomer &&
      stripeCustomer.subscriptions.data.length &&
      stripeCustomer.subscriptions.data[0].cancel_at_period_end;

    const endSubscriptionDate = moment
      .unix(stripeCustomer.subscriptions.data[0].current_period_end)
      .format("LL");

    return (
      <>
        <h1 className={style.sign__title}>Vous êtes PRO !</h1>
        {hasACanceledSubscription ? (
          <p className={style.sign__info}>
            Votre abonnement se terminera le {endSubscriptionDate}.
          </p>
        ) : (
          <p className={style.sign__info}>
            Votre abonnement se renouvellera le {endSubscriptionDate}.
          </p>
        )}
      </>
    );
  };

  return (
    <div className={style.sign}>
      <img src={badge} alt="octopulse badge" />
      <div className={style.sign__content}>
        {hasASubscription ? (
          showSubscribedContent()
        ) : (
          <>
            <h1 className={style.sign__title}>Passez PRO !</h1>
            <ul className={style.sign__list}>
              <li>Audit jusqu&apos;à 1000 pages</li>
              <li>Téléchargez vos rapports détaillés</li>
              <li>Et bien plus encore...</li>
            </ul>
          </>
        )}
      </div>
      {showButton && (
        <Link to="/settings/subscription" className={style.sign__button}>
          En savoir +
        </Link>
      )}
    </div>
  );
});

const Header = ({ user }) => {
  const { firstName, lastName } = user;

  return (
    <div className={style.wrapper}>
      <div className={style.wrapper__content}>
        <div className={style.wrapper__profile}>
          {firstName && lastName ? (
            <h2>
              {firstName} {lastName}
            </h2>
          ) : (
            <h2>Paramètres</h2>
          )}
          <p>
            Bienvenue dans les paramètres de votre compte, vous pouvez modifier
            les informations de votre profil et gérer votre abonnement.
          </p>
        </div>
        <PremiumSign user={user} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Header);
