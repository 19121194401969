import React, { useContext } from "react";
import Icon from "img/modules";
import { connect } from "react-redux";
import { getModuleFromJson } from "utils";
import style from "./Header.module.sass";
import Price from "../_price";
import AuditContext from "../AuditContext";

const Header = ({ module }) => {
  const moduleFromJson = getModuleFromJson(module.name);
  const { isAuditOld } = useContext(AuditContext);

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.container__image}>
          <Icon tag={module.name} />
        </div>
        <div className={style["container__text-container"]}>
          <h2 className={style.container__title}>{moduleFromJson.title}</h2>
          <h3 className={style.container__subtitle}>
            {moduleFromJson.page_title}
          </h3>
          <p className={style.container__desc}>{moduleFromJson.description}</p>
        </div>
        {/* not show if module 100% complete and is not last audit */}
        {module.progress < 1 ? (
          <div className={style.container__price}>
            {!isAuditOld && <Price />}
          </div>
        ) : (
          <div className={style["container__price--complete"]}>
            Vous avez terminé cette optimisation !
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  module: state.audit.module
});

export default connect(mapStateToProps)(Header);
