import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import arrow from "components/Arrow/arrow.svg";
import { CloseIcon } from "img/icons";
import style from "./ArrowShowCart.module.sass";

const ArrowShowCart = ({ history, isGuidedTourShown, isArrowCartShown }) => {
  const [isShown, setIsShow] = useState(isArrowCartShown);

  useEffect(() => {
    // show component if cart has received an item
    // and has never been show before
    // and when guided tour is not displayed
    if (
      isArrowCartShown &&
      !localStorage.getItem("arrowShowCartHasBeenShow") &&
      !isGuidedTourShown
    ) {
      setIsShow(true);
      localStorage.setItem("arrowShowCartHasBeenShow", "1");
    }

    if (isGuidedTourShown) setIsShow(false);
  }, [isGuidedTourShown, isArrowCartShown]);

  history.listen(() => {
    // called when route changes
    setIsShow(false);
  });

  if (isShown)
    return (
      <Link to="/cart" className={style.container} id="cart">
        <span
          className={style.container__close}
          onClick={e => {
            e.preventDefault();
            setIsShow(false);
          }}
        >
          <CloseIcon />
        </span>
        <img src={arrow} alt="arrow" />
        <p>
          Retrouvez toutes les optimisations <br />
          ajoutées à votre devis !
        </p>
      </Link>
    );
  return null;
};

const mapStateToProps = state => ({
  isGuidedTourShown: state.global.isGuidedTourShown,
  isArrowCartShown: state.global.isArrowCartShown
});

export default withRouter(connect(mapStateToProps)(ArrowShowCart));
