import React from "react";
import style from "./Footer.module.sass";
import axa from "./_icon/axa.svg";
import stripe from "./_icon/stripe.svg";
import quality from "./_icon/quality-control.svg";

const Separator = () => <div className={style.separator} />;

const Footer = () => (
  <footer className={style.wrapper}>
    <div className={style.container}>
      <div className={style.container__elem}>
        <img className={style.container__image} src={stripe} alt="stripe" />
        <span>Paiement 100% sécurisé par Stripe</span>
      </div>
      <Separator />
      <div className={style.container__elem}>
        <img className={style.container__image} src={axa} alt="axa" />
        <span>Prestations couvertes par Axa Assurance</span>
      </div>
      <Separator />
      <div className={style.container__elem}>
        <img className={style.container__image} src={quality} alt="quality" />
        <span>
          Contrôle qualité tout au
          <br />
          long de la prestation
        </span>
      </div>
    </div>
  </footer>
);

export default Footer;
