import React from "react";
import { connect } from "react-redux";
import Profile from "./Profile";
import Security from "./Security";
import style from "./ProfileTab.module.sass";
import Box from "../Box";

const ProfileTab = ({ user }) => (
  <div className={style.wrapper}>
    <Box>
      <Profile />
    </Box>
    {!user.hasGoogleToken && (
      <Box>
        <Security />
      </Box>
    )}
  </div>
);

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(ProfileTab);
