import React from "react";
import keyword from "img/illustrations/octo-analysing.svg";
import netlinking from "img/illustrations/men.svg";
import sceptre from "img/illustrations/sceptre-and-drone.svg";
import money from "img/illustrations/money.svg";
import seolocal from "img/illustrations/seolocal.svg";
import PropTypes from "prop-types";
import style from "./HeaderPage.module.sass";
import dokeyLogo from "../../img/logo-dokey.svg";

const Illustration = ({ type }) => {
  const getClassname = (className = null) =>
    [style.image, className && style[`image--${className}`]].join(" ");

  switch (type) {
    case "content":
      return (
        <img
          className={getClassname("content")}
          src={dokeyLogo}
          alt="dokey logo"
        />
      );
    case "keyword":
      return (
        <img className={getClassname()} src={keyword} alt="illustration" />
      );
    case "ranking":
      return (
        <img className={getClassname()} src={keyword} alt="illustration" />
      );
    case "netlinking":
      return (
        <img
          className={getClassname("netlinking")}
          src={netlinking}
          alt="illustration"
        />
      );
    case "sky":
      return (
        <img className={getClassname()} src={sceptre} alt="illustration" />
      );
    case "sea":
      return (
        <img
          className={getClassname("sea")}
          src={money}
          alt="google ads logo"
        />
      );
    case "seolocal":
      return (
        <img
          className={getClassname("seolocal")}
          src={seolocal}
          alt="seo local"
        />
      );
    default:
      return null;
  }
};

const HeaderPage = ({ title, description, type }) => {
  const isTypeSky = type === "sky";

  return (
    <div
      style={isTypeSky ? { background: "#AFDFE6" } : null}
      className={style.wrapper}
    >
      <div className={style.wrapper__content}>
        <Illustration type={type} />
        <div
          className={
            isTypeSky
              ? style["content__text-container--white"]
              : style["content__text-container"]
          }
        >
          <h3 className={style.content__title}>{title}</h3>
          {description && (
            <p className={style.content__description}>{description}</p>
          )}
        </div>
      </div>
    </div>
  );
};

HeaderPage.propTypes = {
  title: PropTypes.PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  type: PropTypes.string.isRequired
};

export default HeaderPage;
