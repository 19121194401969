import React from "react";
import style from "./ErrorPage.module.sass";
import Button from "../Button";

const ErrorPage = () => (
  <div className={style.container}>
    <h2>Une erreur est survenue :&apos;(</h2>
    <Button cta to="/">
      Revenir à la page d&apos;accueil
    </Button>
  </div>
);

export default ErrorPage;
