import { toast } from "react-toastify";
import { defineMessages } from "react-intl";

export const GET_WEBSITES_SUCCESS = "GET_WEBSITES_SUCCESS";
export const GET_WEBSITES_FAILURE = "GET_WEBSITES_FAILURE";
export const getWebsites = () => (dispatch, getState, axios) => {
  dispatch({ type: "GET_WEBSITES_REQUEST" });
  return axios
    .get("/websites")
    .then(({ data: websites }) =>
      dispatch({
        type: GET_WEBSITES_SUCCESS,
        websites
      })
    )
    .catch(({ response: error }) => {
      dispatch({
        type: GET_WEBSITES_FAILURE,
        error
      });
      throw error;
    });
};

export const ADD_WEBSITE_SUCCESS = "ADD_WEBSITE_SUCCESS";
export const ADD_WEBSITE_FAILURE = "ADD_WEBSITE_FAILURE";
export const addWebsite = url => (dispatch, getState, axios) => {
  const {
    global: {
      intl: { formatMessage }
    }
  } = getState();
  const messages = defineMessages({
    success: {
      id: "action.websites.addWebsites.success"
    },
    alreadyExists: {
      id: "action.websites.addWebsites.error.alreadyExists"
    },
    maxDomains: {
      id: "action.websites.addWebsites.error.maxDomains"
    },
    genericError: {
      id: "global.errors.generic"
    }
  });

  dispatch({ type: "ADD_WEBSITE_REQUEST" });
  return axios
    .post("/websites", { url })
    .then(({ data: website }) => {
      toast.success(formatMessage(messages.success));

      return dispatch({
        type: ADD_WEBSITE_SUCCESS,
        website
      });
    })
    .catch(({ response: error }) => {
      const {
        user: { rights }
      } = getState();

      switch (error.data) {
        case "WebsiteAlreadyExists":
          toast.error(formatMessage(messages.alreadyExists));
          break;
        case "MaxWebsitesReached":
          toast.error(
            formatMessage(messages.maxDomains, {
              num: rights.website.maximumDomains
            })
          );
          break;
        default:
          toast.error(formatMessage(messages.genericError));
          break;
      }

      dispatch({
        type: ADD_WEBSITE_FAILURE,
        error
      });
      throw error;
    });
};

export const SET_CURRENT_WEBSITE = "SET_CURRENT_WEBSITE";
export const setCurrentWebsite = website => dispatch => {
  // store website in localstorage so we can identidy the last selected website on reload
  const websiteToStore = JSON.stringify(website);
  localStorage.setItem("lastSelectedWebsite", websiteToStore);

  return Promise.resolve(dispatch({ type: SET_CURRENT_WEBSITE, website }));
};
