enum OrderStatus {
  Accepted = "ACCEPTED",
  Created = "CREATED",
  Estimated = "ESTIMATED",
  Finalized = "FINALIZED",
  Fulfilled = "FULFILLED",
  Refused = "REFUSED",
  Validated = "VALIDATED"
}

export default OrderStatus;
