import React from "react";
import { Input, Button } from "components";
import { Formik } from "formik";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import style from "./Security.module.sass";
import { updatePassword } from "../../../../actions/user";
import { createLoadingSelector } from "../../../../api/selectors";

const Profile = ({ dispatch, loading }) => {
  function validateForm(values) {
    const errors = {};

    // -----Check new passwords are the same-----
    if (!(values.password1 === values.password2)) {
      errors.password2 = "Mots de passe non identiques";
    }

    // -----OLD PASSWORD-----
    if (!values.oldPassword) {
      errors.oldPassword = "Champ requis";
    }

    // -----PASSWORD1-----
    if (values.password1) {
      const pwdRegexp = /^(?=.*[0-9])(?=.*[a-z]).{8,}$/; // 8 characters + at least one digit
      if (!pwdRegexp.test(values.password1)) {
        errors.password1 = "Au moins 8 caractères et 1 chiffre";
      }
    } else {
      errors.password1 = "Champ requis";
    }

    // -----PASSWORD2-----
    if (!values.password2) {
      errors.password2 = "Champ requis";
    }

    return errors;
  }

  return (
    <>
      <h3>Sécurité</h3>
      <Formik
        initialValues={{
          oldPassword: "",
          password1: "",
          password2: ""
        }}
        validate={values => validateForm(values)}
        onSubmit={({ oldPassword, password1 }, formikActions) => {
          dispatch(updatePassword(oldPassword, password1))
            .then(() => {
              toast.success("Votre mot de passe a été mis à jour");
              formikActions.resetForm();
            })
            .catch(() =>
              toast.error(
                "Une erreur est survenue. Vérifiez que votre mot de passe soit correct."
              )
            );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <div className={style["form__input-container"]}>
              <Input
                placeholder="Mot de passe actuel"
                type="password"
                name="oldPassword"
                id="oldPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.oldPassword}
                error={
                  errors.oldPassword && touched.oldPassword
                    ? errors.oldPassword
                    : null
                }
              />
              <Input
                placeholder="Nouveau mot de passe"
                type="password"
                name="password1"
                id="password1"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password1}
                error={
                  errors.password1 && touched.password1
                    ? errors.password1
                    : null
                }
              />
              <Input
                placeholder="Nouveau mot de passse"
                type="password"
                name="password2"
                id="password2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password2}
                error={
                  errors.password2 && touched.password2
                    ? errors.password2
                    : null
                }
              />
            </div>
            <Button className={style.form__button} submit disabled={loading}>
              Mettre à jour
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

const loadingSelector = createLoadingSelector(["UPDATE_PASSWORD"]);

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  user: state.user
});

export default connect(mapStateToProps)(Profile);
