import React, { useContext, useState } from "react";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "./InputUrl.module.sass";
import { testUrl } from "../../../../../actions/auth";
import { createLoadingSelector } from "../../../../../api/selectors";
import {
  addWebsite as addWebsiteAction,
  setCurrentWebsite
} from "../../../../../actions/websites";
import SelectWebsitesContext from "../SelectWebsitesContext";
import { launchAudit } from "../../../../../actions/audit";
import { isUrlAllowed } from "../../../../../utils";

const messages = defineMessages({
  windowConfirmation: {
    id: "sidebar.newWebsite.windowMessage"
  }
});

const InputUrl = ({ history, modal, dispatch, loadingTestUrl, user }) => {
  const intl = useIntl();
  const { addValueToDropdown } = useContext(SelectWebsitesContext);
  const [showUrlNotReachable, setShowUrlNotReachable] = useState(false);
  const {
    Account: { subscription }
  } = user;
  const isFree = subscription === "FREE";

  const addWebsite = async url => {
    const message = intl.formatMessage(messages.windowConfirmation, { url });

    if (window.confirm(message)) {
      // add new website to database
      const { website } = await dispatch(addWebsiteAction(url));
      // set the new website as current website
      await dispatch(setCurrentWebsite(website));
      // launch audit for the new website
      await dispatch(launchAudit(website.id));

      modal.current.closeModal();
      history.push("/audit");
      addValueToDropdown(website);
    }
  };

  return (
    <div className={styles.container}>
      <Formik
        initialValues={{ url: "" }}
        validate={({ url }) => {
          const regexUrl = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+/g;
          const errors = {};

          if (!url)
            errors.url = (
              <FormattedMessage id="sidebar.newWebsite.error.noUrl" />
            );
          else if (!regexUrl.test(url.trim()))
            errors.url = (
              <FormattedMessage id="sidebar.newWebsite.error.notCorrect" />
            );
          else if (isUrlAllowed(url.trim(), ["octopulse.io"]))
            errors.url = "Vous ne pouvez pas analyser cette URL";

          return errors;
        }}
        onSubmit={({ url }) => {
          const trimmedUrl = url.trim();
          setShowUrlNotReachable(false);

          if (!isFree) {
            dispatch(testUrl(trimmedUrl))
              .then(({ res }) => {
                if (!res) setShowUrlNotReachable(true);
                else addWebsite(trimmedUrl);
              })
              .catch(() => setShowUrlNotReachable(true));
          }
        }}
        render={({ handleSubmit }) => (
          <Form className={styles.form} onSubmit={handleSubmit}>
            <label htmlFor="url">
              <FormattedMessage id="sidebar.newWebsite.label" />
            </label>
            <div className={styles["form__input-container"]}>
              <Field
                type="text"
                placeholder="www.my-site.com"
                name="url"
                id="url"
              />
              <button type="submit" disabled={loadingTestUrl}>
                <FormattedMessage id="sidebar.newWebsite.button" />
              </button>
            </div>
            <ErrorMessage name="url">
              {err => <p className={styles.form__error}>{err}</p>}
            </ErrorMessage>
            {showUrlNotReachable && (
              <p className={styles.form__error}>
                <FormattedMessage id="sidebar.newWebsite.error.notReachable" />
              </p>
            )}
          </Form>
        )}
      />
    </div>
  );
};

const loadingSelectorTestUrl = createLoadingSelector(["TEST_URL"]);

const mapStateToProps = state => ({
  loadingTestUrl: loadingSelectorTestUrl(state),
  user: state.user
});

export default withRouter(connect(mapStateToProps)(InputUrl));
