import React, { FC, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import { getAudits, getModules, launchAudit } from "../../actions/audit";
import Box from "./Box";
import style from "./Dashboard.module.sass";
import chartSkeleton from "../../img/illustrations/chart-skeleton.svg";
import checklist from "../../img/illustrations/checklist.svg";
import {
  getLastAuditByStatus,
  getModulesNotDone,
  getScoreLetter
} from "../../utils";
import { Button } from "../../components";

interface SeoBoxProps {
  websites?: any;
}

const SeoBox: FC<SeoBoxProps> = ({ websites }) => {
  const [loading, setLoading] = useState(true);
  const [crawling, setCrawling] = useState(false);
  const [noAudit, setNoAudit] = useState(false);
  const dispatch: any = useDispatch();
  const [kpis, setKpis] = useState({
    score: null,
    numOptimizations: null
  });
  const { current: currentWebsite } = websites;

  useEffect(() => {
    dispatch(getAudits(currentWebsite.id)).then(({ audits }: any) => {
      if (!_.isEmpty(audits)) {
        const lastFinishedAudit: any = getLastAuditByStatus("FINISHED");

        if (lastFinishedAudit) {
          const { id: auditId, websiteId, score } = lastFinishedAudit;

          dispatch(getModules(websiteId, auditId)).then(({ modules }: any) => {
            setKpis(prevState => ({
              ...prevState,
              score: getScoreLetter(score),
              numOptimizations: getModulesNotDone(modules).length
            }));
            setLoading(false);
          });
        } else {
          setLoading(false);
          setCrawling(true);
        }
      } else {
        setNoAudit(true);
        setCrawling(true);
        setLoading(false);
      }
    });
  }, [currentWebsite]);

  const showFooter = () => {
    if (loading) {
      return (
        <div className={[style.footer, style["footer--text-only"]].join(" ")}>
          Chargement des données ...
        </div>
      );
    }
    if (noAudit) {
      return (
        <div
          className={[style.footer, style["footer--text-with-button"]].join(
            " "
          )}
        >
          Votre audit est obsolète
          <Button
            cta
            onClick={() => {
              dispatch(launchAudit(currentWebsite.id))
                .then(() =>
                  toast.success("Votre audit a été lancé avec succès !")
                )
                .catch(() => toast.error("Une erreur est survenue"))
                .finally(() => {
                  setLoading(false);
                  setCrawling(true);
                  setNoAudit(false);
                });
            }}
          >
            Réanalyser
          </Button>
        </div>
      );
    }
    if (crawling) {
      return (
        <div className={[style.footer, style["footer--text-only"]].join(" ")}>
          Nous sommes en train d’analyser{" "}
          <a
            href={currentWebsite.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {currentWebsite.domain}
          </a>
          . Cela ne devrait pas prendre plus de 5 minutes !
        </div>
      );
    }
    return (
      <div
        className={[style.footer, style["footer--text-with-button"]].join(" ")}
      >
        Effectuez vos optimisations pour améliorer votre référencement.
        <Button cta to="/audit">
          Optimiser
        </Button>
      </div>
    );
  };

  return (
    <Box title="Mes optimisations SEO">
      {loading || crawling ? (
        <div className={[style.main, style["main--empty"]].join(" ")}>
          <img src={chartSkeleton} alt="chart skeleton" />
        </div>
      ) : (
        <div className={[style.main, style["main--seo"]].join(" ")}>
          <img className={style.main__illu} src={checklist} alt="checklist" />
          <div className={style.main__kpis}>
            <div className={style.kpis__line}>
              <div className={style.kpis__title}>Score</div>
              <div className={style.kpis__num}>{kpis.score}</div>
            </div>
            <div className={style.kpis__line}>
              <div className={style.kpis__title}>Optimisations à faire</div>
              <div className={style.kpis__num}>{kpis.numOptimizations}</div>
            </div>
          </div>
        </div>
      )}
      {showFooter()}
    </Box>
  );
};

const mapStateToSeoBoxProps = (state: any) => ({
  websites: state.websites
});

export default connect(mapStateToSeoBoxProps)(SeoBox);
