import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useValidationModalProps } from "./useValidationModal";
import NetlinkingSelectionPage from "../../NetlinkingSelection/NetlinkingSelectionPage";
import styles from "./NewCampaignModal.module.scss";
import { orderNetlinking } from "../../../../actions/checkout";

const NewCampaignModal: FC<useValidationModalProps> = ({ showValidation }) => {
  const dispatch: any = useDispatch();
  const {
    loading: { ORDER_NETLINKING: loading }
  } = useSelector((state: any) => state);

  return (
    <div className={styles.container}>
      <h1>Nouvelle campagne de Netlinking</h1>
      <NetlinkingSelectionPage
        loading={loading}
        onQuoteValidation={async ({ articles, duration }) => {
          dispatch(orderNetlinking(articles, duration)).then(async () => {
            showValidation();
          });
        }}
      />
    </div>
  );
};

export default NewCampaignModal;
