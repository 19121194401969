import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "img/logo.svg";
import { Button, Input } from "../../components";
import style from "./Impersonate.module.sass";
import { setJwt } from "../../actions/auth";

const ValidateSchema = Yup.object().shape({
  token: Yup.string().required("Champs requis")
});

const Impersonate = ({ dispatch, history }) => {
  const [clipboard, setClipboard] = useState("");
  const [loadingClipBoard, setLoadingClipboard] = useState(true);

  useEffect(() => {
    try {
      navigator.clipboard
        .readText()
        .then(text => {
          setClipboard(text);
          setLoadingClipboard(false);
        })
        .catch(() => {
          setLoadingClipboard(false);
        });
    } catch (e) {
      setLoadingClipboard(false);
    }
  }, []);

  return (
    <div className={style.container}>
      <img src={logo} alt="logo" />
      {loadingClipBoard || (
        <Formik
          initialValues={{
            token: clipboard
          }}
          validationSchema={ValidateSchema}
          onSubmit={({ token }) => {
            dispatch(setJwt(token));
            history.push("/");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <form className={style.container} onSubmit={handleSubmit}>
              <h3>Enter your token</h3>
              <Input
                placeholder="Token"
                type="token"
                name="token"
                id="token"
                onClick={e => e.target.select()} // select all text on click
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.token}
                error={errors.token && touched.token ? errors.token : null}
              />
              <Button cta submit>
                Login
              </Button>
              <Link to="/" className={style.container__forgot}>
                Go back home
              </Link>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default connect()(Impersonate);
