import React, { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./Netlinking.module.sass";
import { HeaderPage } from "../../../components/index";
import NetlinkingContext from "./NetlinkingContext";
import ArticlesSelection from "./ArticlesSelection";
import Footer from "./Footer";
import DurationSelection from "./DurationSelection";

export type NetlinkingQuote = { articles: number; duration: number };

interface NetlinkingSelectionPageProps {
  withHeader?: boolean;
  loading?: boolean;
  onQuoteValidation: (obj: NetlinkingQuote) => void;
}

const NetlinkingSelectionPage: FC<NetlinkingSelectionPageProps> = ({
  withHeader,
  onQuoteValidation,
  loading
}) => {
  const [articles, setArticles] = useState(5);
  const [duration, setDuration] = useState(6);

  return (
    <NetlinkingContext.Provider
      value={{ articles, setArticles, duration, setDuration }}
    >
      {withHeader && (
        <HeaderPage
          type="netlinking"
          title={<FormattedMessage id="netlinking.header.title" />}
          description={<FormattedMessage id="netlinking.header.desc" />}
        />
      )}
      <div className={styles.container}>
        <section className={styles.step}>
          <h3>Sélectionnez votre nombre d&apos;articles mensuels</h3>
          <p>
            Nos rédacteurs expérimentés rédigent des articles optimisés (
            <a
              href="https://internetbusiness.fr/piloter-referencement-petite-entreprise/"
              target="_blank"
              rel="noopener noreferrer"
            >
              voir un exemple
            </a>
            ) qui seront présents sur notre réseau de 5,000 sites. Les sites
            choisis auront une thématique en liaison avec votre domaine
            d’activité.{" "}
          </p>
          <ArticlesSelection />
        </section>
        <section className={styles.step}>
          <h3>Choisissez la durée de votre campagne</h3>
          <p>
            Pour qu’une campagne de netlinking fonctionne correctement, il est
            nécessaire qu’elle se fasse sur minimum 3 mois. Nous vous
            recommandons cependant une campagne d’au moins 6 mois.
          </p>
          <DurationSelection />
        </section>
      </div>
      <Footer
        loading={loading}
        onClick={() =>
          onQuoteValidation({ articles, duration: Number(duration) })
        }
      />
    </NetlinkingContext.Provider>
  );
};

export default NetlinkingSelectionPage;
