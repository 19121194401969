import React from "react";
import screenshot from "./screenshot3.png";
import Template from "../_stepTemplate";

const Step2 = () => (
  <Template
    img={screenshot}
    text="Octopulse vous aide à déterminer sur quels mots-clés et requêtes
    d'utilisateurs vous devriez vous positionner. Suivez l'évolution de votre
    positionnement actualisé quotidiennement."
    title="Ma position"
  />
);

export default Step2;
