import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ErrorPage, LoadingPage } from "components";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import style from "./Results.module.sass";
import Header from "./Header";
import Kpis from "./Kpis";
import Level from "./Level";
import { downloadAuditReport, getModules } from "../../../actions/audit";
import AuditContext from "./AuditContext";
import {
  getAuditById,
  getLastAuditByStatus,
  getWebsiteById
} from "../../../utils";
import { setCurrentWebsite } from "../../../actions/websites";
import { usePrevious } from "../../../hooks";
import WarningOldAudit from "../WarningOldAudit";
import { Download } from "../../../img/icons";

const Results = ({ dispatch, match, currentWebsite, user }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [loadingReport, setLoadingReport] = useState(false);
  const [error, setError] = useState(false);
  const [audit, setAudit] = useState(null);

  const { id: websiteId } = currentWebsite;
  const { id: auditId } = match.params;
  const prevAuditId = usePrevious(auditId);
  const lastFinishedAudit = getLastAuditByStatus("FINISHED");
  const isAuditOld =
    lastFinishedAudit && lastFinishedAudit.id !== Number(auditId);
  const isFree = user.Account.subscription === "FREE";

  const fetchData = () => {
    setLoading(true);
    setAudit(getAuditById(auditId));

    dispatch(getModules(websiteId, auditId))
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    dispatch(setCurrentWebsite(getWebsiteById(websiteId)));
    fetchData();
  }, []);

  // pass when audit changed (so auditId changed)
  useEffect(() => {
    if (prevAuditId && prevAuditId !== auditId) fetchData();
  }, [auditId]);

  const downloadReport = (idWebsite, idAudit) => {
    if (!isFree) {
      setLoadingReport(true);

      dispatch(downloadAuditReport(idWebsite, idAudit))
        .then(() =>
          toast.success(
            intl.formatMessage({
              id: "myOptimisations.results.downloadReport.success"
            })
          )
        )
        .catch(() =>
          toast.error(
            intl.formatMessage({
              id: "myOptimisations.results.downloadReport.error"
            })
          )
        )
        .finally(() => setLoadingReport(false));
    }
  };

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  return (
    <AuditContext.Provider value={{ audit, isAuditOld }}>
      <div className={style.container}>
        <WarningOldAudit />
        <Header />
        <Kpis />
        <Level number={1} title="myOptimisations.results.level1.title" />
        <Level number={2} title="myOptimisations.results.level2.title" />
        <Level number={3} title="myOptimisations.results.level3.title" />
        <div className={style.downloadReport}>
          <button
            className={[
              style.downloadReport__button,
              loadingReport && style["downloadReport__button--loading"],
              isFree && style["downloadReport__button--disabled"]
            ].join(" ")}
            onClick={() => downloadReport(websiteId, auditId)}
            data-tip={
              isFree
                ? intl.formatMessage({
                    id: "myOptimisations.results.downloadReport.forbidden"
                  })
                : null
            }
          >
            <Download />
            <FormattedMessage id="myOptimisations.results.downloadReport" />
          </button>
        </div>
        {/*
          Only one react tooltip component per page
          https://www.npmjs.com/package/react-tooltip#using-react-component-as-tooltip
      */}
        <ReactTooltip type="dark" effect="solid" html />
      </div>
    </AuditContext.Provider>
  );
};

const mapStateToProps = state => ({
  currentWebsite: state.websites.current,
  user: state.user
});

export default connect(mapStateToProps)(Results);
