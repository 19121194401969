import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAudits, launchAudit } from "actions/audit";
import _ from "lodash";
import { LoadingPage, CrawlingPage } from "components";
import { toast } from "react-toastify";
import style from "./Optimizations.module.sass";
import { getLastAuditByStatus } from "../../utils";

const mapStateToProps = state => ({
  websites: state.websites
});

const AuditObsolete = ({ dispatch, currentWebsite, onReloadAudit }) => (
  <div className={style.loading}>
    <span className={style.loading__discover}>
      Découvrez nos autres <br />
      pages en attendant !
    </span>
    <CrawlingPage
      title="Votre audit Octopulse est obselète"
      description="Veuillez relancer une analyse"
    />
    <p
      className={[style.loading__button].join(" ")}
      onClick={() => {
        dispatch(launchAudit(currentWebsite.id))
          .then(() => toast.success("Votre audit a été lancé avec succès !"))
          .catch(() => toast.error("Une erreur est survenue"))
          .finally(() => onReloadAudit());
      }}
    >
      Réanalyser {currentWebsite.domain}
    </p>
  </div>
);

const AuditInProgress = connect(mapStateToProps)(
  ({ dispatch, websites, history }) => {
    const { current: currentWebsite } = websites;
    const [loading, setLoading] = useState(true);

    const fetchAudits = () => {
      setLoading(true);
      dispatch(getAudits(currentWebsite.id))
        .then(({ audits }) => {
          if (!_.isEmpty(audits)) {
            const lastFinishedAudit = getLastAuditByStatus("FINISHED");

            if (lastFinishedAudit) {
              history.push(`/audit/${lastFinishedAudit.id}`);
            }
          }
        })
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      fetchAudits();
    }, []);

    return (
      <div className={style.loading}>
        <span className={style.loading__discover}>
          Découvrez nos autres <br />
          pages en attendant !
        </span>
        <CrawlingPage
          title="Octopulse est en train d'analyser votre site !"
          description="Un email vous sera envoyé lorsque nous aurons terminé."
        />
        <p
          className={[
            style.loading__button,
            loading && style["loading__button--disabled"]
          ].join(" ")}
          onClick={() => fetchAudits()}
        >
          Recharger
        </p>
      </div>
    );
  }
);

const Optimizations = ({ dispatch, history, websites }) => {
  const [onEnterLoading, setOnEnterLoading] = useState(true);
  const [auditObsolete, setAuditObsolete] = useState(false);
  const { current: currentWebsite } = websites;

  // const [hasFailed, setHasFailed] = useState(false);

  useEffect(() => {
    dispatch(getAudits(currentWebsite.id)).then(({ audits }) => {
      if (!_.isEmpty(audits)) {
        const lastFinishedAudit = getLastAuditByStatus("FINISHED");

        if (lastFinishedAudit) {
          history.push(`/audit/${lastFinishedAudit.id}`);
        } else {
          setOnEnterLoading(false);
        }
      } else {
        setAuditObsolete(true);
      }
    });
  }, [currentWebsite]);

  if (auditObsolete)
    return (
      <AuditObsolete
        dispatch={dispatch}
        currentWebsite={currentWebsite}
        onReloadAudit={() => {
          setAuditObsolete(false);
          setOnEnterLoading(false);
        }}
      />
    );

  if (onEnterLoading) return <LoadingPage />;
  // if (hasFailed)
  //   return (
  //     <div className={style.failed}>
  //       <h3>Une erreur est survenue durant l&apos;analyse de votre site.</h3>
  //       <p>Nous faisons tout notre possible pour la résoudre au plus vite !</p>
  //       <span className={style.failed__arrow}>
  //         N&apos;hésitez pas à nous contacter <br />
  //         si vous avez la moindre question !
  //       </span>
  //     </div>
  //   );
  return <AuditInProgress history={history} />;
};

export default connect(mapStateToProps)(Optimizations);
