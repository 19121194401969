import React, { ButtonHTMLAttributes, FunctionComponent } from "react";

// Assets
import styles from "./Button.module.css";

interface OwnProps {
  emphasized?: boolean;
  icon?: FunctionComponent;
}

type Props = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FunctionComponent<Props> = ({
  children,
  emphasized,
  icon: Icon,
  ...buttonProps
}) => {
  const className =
    (emphasized && styles.emphasized) ||
    (buttonProps.disabled && styles.disabled) ||
    styles.regular;

  return (
    <button
      {...buttonProps}
      className={[styles.button, className, buttonProps.className].join(" ")}
    >
      {children}
      {Icon && (
        <span className={styles.icon}>
          <Icon />
        </span>
      )}
    </button>
  );
};

export default Button;
