import React, {
  FC,
  MutableRefObject,
  useContext,
  useEffect,
  useState
} from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Articles.module.scss";
import { Table } from "../../../components";
import { urlWithoutProtocol } from "../../../utils";
import ArticleModificationModal from "./Modals/ArticleModificationModal";
import { getNetlinkingPublications } from "../../../actions/netlinking";
import NetlinkingContext from "./NetlinkingContext";
import useValidationModal from "./Modals/useValidationModal";

const publicationTypeText = {
  GENERIC: "Générique",
  THEMATIC: "Thématique",
  DEDICATED_WEBSITE: "Site dédié",
  PREMIUM: "⭐️ Premium"
};

interface DateProps {
  modalRef: MutableRefObject<any>;
  onClick: (string) => void;
  publication: { date: moment.Moment; url: string };
}

const Date: FC<DateProps> = ({ modalRef, onClick, publication }) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const { date } = publication;

  useEffect(() => {
    // wait a bit so the animation is not triggered on render
    setTimeout(() => {
      setShowAnimation(true);
    }, 500);
  }, []);

  return (
    <div className={styles.date}>
      <p>{moment(date).format("D MMM YYYY")}</p>
      <div
        className={[
          styles["date__button-container"],
          showAnimation && styles["date__button-container--animate"]
        ].join(" ")}
      >
        <button
          onClick={() => {
            onClick(publication);
            modalRef.current.openModal();
          }}
        >
          Suggérer une modification
        </button>
      </div>
    </div>
  );
};

interface UrlProps {
  article: string;
  target: string;
}

const Url: FC<UrlProps> = ({ article, target }) => (
  <div className={styles.url}>
    <a href={article} target="_blank" rel="noopener noreferrer">
      {urlWithoutProtocol(article)}
    </a>
    <a href={target} target="_blank" rel="noopener noreferrer">
      {urlWithoutProtocol(target)}
    </a>
  </div>
);

const Articles: FC = () => {
  const dispatch: any = useDispatch();
  const [publicationToChange, setPublicationToChange] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { currentCampaign }: any = useContext(NetlinkingContext);
  const {
    netlinking: { publications },
    loading: { GET_NETLINKING_PUBLICATIONS }
  } = useSelector((state: any) => state);
  const [
    articleModificationWithValidationModal,
    articleModificationModal
  ] = useValidationModal(ArticleModificationModal, {
    publication: publicationToChange,
    currentCampaign
  });

  useEffect(() => {
    dispatch(getNetlinkingPublications(currentCampaign.id)).then(() => {
      setLoading(false);
    });
  }, [currentCampaign]);

  useEffect(() => {
    if (publications) {
      setData(
        publications.map(publication => ({
          url: (
            <Url
              article={publication.url}
              target={publication.netlinkingConfiguration.targetUrl}
            />
          ),
          tf: publication.trustFlowScore,
          cf: publication.citationFlow,
          type: publicationTypeText[publication.type],
          date: (
            <Date
              modalRef={articleModificationModal}
              publication={publication}
              onClick={pub => setPublicationToChange(pub)}
            />
          )
        }))
      );
    }
  }, [publications]);

  return (
    <section className={styles.container}>
      <h2>
        Articles publiés<span>{publications && publications.length}</span>
      </h2>
      <div className={styles.table}>
        <Table
          loading={loading || GET_NETLINKING_PUBLICATIONS}
          columns={[
            { Header: "URL", accessor: "url" },
            { Header: "Trust Flow", accessor: "tf" },
            { Header: "Citation Flow", accessor: "cf" },
            { Header: "Type de publication", accessor: "type" },
            { Header: "Date de publication", accessor: "date" }
          ]}
          data={data}
          noDataText="Aucun article pour le moment"
        />
      </div>
      {articleModificationWithValidationModal}
    </section>
  );
};

export default Articles;
