import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import style from "./KpiTile.module.sass";
import ProgressionIcon from "../ProgressionIcon";
import { Arrow } from "../Button/_icons";

const KpiTile = ({
  icon,
  kpi,
  kpiText,
  description,
  disabled,
  progress,
  loading,
  onClick
}) => {
  if (loading)
    return (
      <div className={style.skeleton}>
        <div className={style.skeleton__icon} />
        <div className={style.skeleton__kpi}>
          <div />
          <div />
        </div>
        <div className={style.skeleton__desc} />
      </div>
    );
  return (
    <div
      className={
        disabled
          ? style["kpiCard--disabled"]
          : [style.kpiCard, onClick && style["kpiCard--clickable"]].join(" ")
      }
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {onClick && <Arrow />}
      <div className={style.kpiCard__icon}>
        <img src={icon} alt="icon" />
      </div>
      <div className={style.kpiCard__kpi}>
        <div className={style.kpiCard__number}>
          {kpi}
          {!!progress && <ProgressionIcon num={progress} />}
        </div>
        <p className={style.kpiCard__text}>
          <FormattedMessage id={kpiText} values={{ kpi }} />
        </p>
      </div>
      <div className={style.kpiCard__desc}>
        <FormattedMessage id={description} values={{ kpi }} />
      </div>
    </div>
  );
};

KpiTile.propTypes = {
  loading: PropTypes.bool,
  icon: PropTypes.string,
  kpi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  kpiText: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  progress: PropTypes.number,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any])
};

export default KpiTile;
