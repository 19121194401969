import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./SeaDashboard.module.sass";
import { HeaderPage } from "../../../components";
import Header from "./Header";
import { getAdsCampaign } from "../../../actions/sea";

const SeaDashbord: FC = () => {
  const { list } = useSelector((state: any) => state.adsCampaigns);
  const { datastudioId } = useSelector(
    (state: any) => state.adsCampaigns.list[0]
  );
  const { startDate, endDate } = useSelector(
    (state: any) => state.adsCampaigns
  );
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(getAdsCampaign(list[0].id, startDate, endDate));
    }
  }, [startDate, endDate]);

  return (
    <>
      <HeaderPage
        type="sea"
        title="Suivez les performances de votre campagne"
        description="Votre campagne Google Ads est actuellement géré par notre équipe. Vous pouvez suivre les performances de celle-ci grâce au dashboard ci-dessous."
      />
      <div className={style["main-container"]}>
        <Header />
        {/*<DateRange />*/}
        <div className={style.dataStudio}>
          <div className={style.dataStudio__inner}>
            <iframe
              title="google ads data studio"
              // width="100%"
              // height="1000px"
              src={`https://datastudio.google.com/embed/reporting/${datastudioId}/`}
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            />
          </div>
        </div>
        {/*<Kpis />*/}
        {/*<Chart />*/}
      </div>
      {/*<div className={style["container-table"]}>*/}
      {/*  <div className={style["container-table__wrapper"]}>*/}
      {/*    <Campaigns />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default SeaDashbord;
