import React from "react";
import PropTypes from "prop-types";
import style from "./Step.module.sass";

const Template = ({ title, img, text }) => (
  <div className={style.container}>
    <h2 className={style.container__title}>{title}</h2>
    <img className={style.container__img} src={img} alt="screenshot" />
    <p className={style.container__text}>{text}</p>
  </div>
);

Template.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default Template;
