import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { signup } from "actions/auth";
import { Button, Input } from "components";
import { withRouter, Link } from "react-router-dom";
import { Formik } from "formik";
import {
  createErrorMessageSelector,
  createLoadingSelector
} from "api/selectors";
import style from "./Form.module.sass";
import Tile from "../../../../components/Tile";

const ServerErrorMessage = ({ error }) => {
  if (!error) return null;
  if (error.data === "EmailAlreadyUsed")
    return <div className={style.error}>Ce compte existe déjà</div>;
  return <div className={style.error}>Une erreur est survenue</div>;
};

const Form = ({ location, history, loadingSignup, dispatch, error }) => {
  const [success] = useState(false);
  // get params in url
  const params = new URLSearchParams(location.search);
  const url = params.get("url") || "";

  useEffect(() => {
    if (!url) history.push("/signup/url");
  }, []);

  function validateForm(values) {
    const errors = {};

    // -----EMAIL-----
    const mailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!values.email) {
      errors.email = "Entrez un email";
    } else if (!mailRegexp.test(values.email)) {
      errors.email = "Entrez une adresse email valide";
    }

    // -----PHONE-----
    const phoneRegexp = /((?:\+|00)[17](?: -)?|(?:\+|00)[1-9]\d{0,2}(?: -)?|(?:\+|00)1-\d{3}(?: -)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: -)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: -)[0-9]{3}(?: -)[0-9]{4})|([0-9]{7}))/g;
    if (!values.phone) {
      errors.phone = "Entrez un numéro de téléphone";
    } else if (values.phone && !phoneRegexp.test(values.phone)) {
      errors.phone = "Entrez un numéro de téléphone valide";
    }

    // -----PASSWORD-----
    const pwdRegexp = /^(?=.*[0-9])(?=.*[a-z]).{8,}$/; // 8 characters with at least one digit
    if (!values.password) {
      errors.password = "Entrez un mot de passe";
    } else if (!pwdRegexp.test(values.password)) {
      errors.password = "Au moins 8 caractères dont 1 chiffre";
    }

    // -----REPASSWORD-----
    if (values.password && !values.repassword) {
      errors.repassword = "Entrez le même mot de passe";
    }
    // check if two passwords are identical
    if (
      values.password &&
      pwdRegexp.test(values.password) &&
      values.repassword &&
      values.password !== values.repassword
    ) {
      errors.repassword = "Le mot de passe doit être identique";
    }

    return errors;
  }

  if (success)
    return (
      <div className={style.success}>
        <h3>
          Félicitations, vous avez débloqué
          <br />
          votre première optimisation !
        </h3>
        <div className={style.success__container}>
          <div className={style.container__module}>
            <Tile id={1} tag="basic_tags" progress={0} disabled />
          </div>
          <div className={style.container__description}>
            <p>
              Nous vous avons envoyé un mail pour valider votre inscription.
            </p>
            <p className={style.spam}>
              Veuillez vérifier vos spams si vous ne le recevez pas.
            </p>
            <Button to="/login">Se connecter</Button>
          </div>
        </div>
      </div>
    );
  return (
    <div className={style.container}>
      <h3>Inscription</h3>
      <Formik
        initialValues={{
          url,
          email: "",
          phone: "",
          password: "",
          repassword: ""
        }}
        validate={values => validateForm(values)}
        onSubmit={({ email, password, phone }) => {
          dispatch(signup(url, email, password, phone)).then(() => {
            history.push("/");
            // setSuccess(true);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form className={style.form} onSubmit={handleSubmit}>
            <span className={style.form__url}>
              <Input
                placeholder="Url de votre site"
                type="url"
                name="url"
                id="url"
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                error={errors.url && touched.url ? errors.url : null}
              />
              <span
                onClick={() => history.push(`/signup/url?url=${url}`)}
                className={style.url__change}
              >
                Modifier
              </span>
            </span>
            <Input
              placeholder="Email"
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email ? errors.email : null}
            />
            <Input
              placeholder="Téléphone"
              type="phone"
              name="phone"
              id="phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              error={errors.phone && touched.phone ? errors.phone : null}
            />
            <Input
              placeholder="Mot de passe"
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={
                errors.password && touched.password ? errors.password : null
              }
            />
            <Input
              placeholder="Confirmation mot de passe"
              type="password"
              name="repassword"
              id="repassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.repassword}
              error={
                errors.repassword && touched.repassword
                  ? errors.repassword
                  : null
              }
            />
            <ServerErrorMessage error={error} />
            <div className={style.form__buttons}>
              <Button cta submit disabled={loadingSignup} id="signup-button">
                S&apos;inscrire
              </Button>
              {/* <TextLineSeparator className={style.buttons__or}> */}
              {/*  ou */}
              {/* </TextLineSeparator> */}
              {/* <GoogleButton */}
              {/*  onClick={() => dispatch(googleSignin(url))} */}
              {/*  id="signup-google-button" */}
              {/* > */}
              {/*  S&apos;inscrire avec Google */}
              {/* </GoogleButton> */}
              <Link to="/login" className={style.buttons__login}>
                Déjà un compte ?
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

const loadingSelectorSignup = createLoadingSelector(["SIGNUP"]);
const errorSelector = createErrorMessageSelector(["SIGNUP"]);

const mapStateToProps = state => ({
  loadingSignup: loadingSelectorSignup(state),
  error: errorSelector(state)
});

export default withRouter(connect(mapStateToProps)(Form));
