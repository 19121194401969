import React, { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { HeaderPage, LoadingPage } from "../../../components";
import styles from "./NetlinkingDashboard.module.scss";
import Header from "./Header";
import Articles from "./Articles";
import Configuration from "./Configuration";
import {
  getNetlinkingCampaigns,
  getNetlinkingCampaign
} from "../../../actions/netlinking";
import NetlinkingContext from "./NetlinkingContext";
import { setCurrentWebsite } from "../../../actions/websites";

const NetlinkingDashboard: FC = () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(true);
  const [currentCampaign, setCurrentCampaign] = useState<any>();
  const { id: netlinkingCampaignId } = useParams();
  const history = useHistory();
  const {
    websites: { list: websitesList, current: currentWebsite },
    netlinking: { campaigns }
  } = useSelector((state: any) => state);

  const getCurrentCampaign = () =>
    campaigns.find(
      campaign => Number(campaign.id) === Number(netlinkingCampaignId)
    );

  useEffect(() => {
    if (campaigns) {
      setCurrentCampaign(getCurrentCampaign());
    }
  }, [campaigns, netlinkingCampaignId]);

  useEffect(() => {
    (async () => {
      try {
        // fetch campaign with id from url param
        const { netlinkingCampaign } = await dispatch(
          getNetlinkingCampaign(netlinkingCampaignId)
        );

        // check if campaign is link to the current website
        if (Number(netlinkingCampaign.websiteId) === currentWebsite.id) {
          await dispatch(getNetlinkingCampaigns());
          setLoading(true);
        } else {
          // get website linked to the campaign
          const websiteToSet = websitesList.find(
            website =>
              Number(website.id) === Number(netlinkingCampaign.websiteId)
          );

          if (websiteToSet) {
            await dispatch(setCurrentWebsite(websiteToSet));
            await dispatch(getNetlinkingCampaigns());
            setLoading(true);
          }
        }
      } catch (e) {
        history.push("/netlinking");
      }
    })();
  }, []);

  if (loading && !currentCampaign) return <LoadingPage />;
  return (
    <NetlinkingContext.Provider value={{ currentCampaign }}>
      <HeaderPage
        type="netlinking"
        title={<FormattedMessage id="Votre dashboard netlinking" />}
        description={
          <FormattedMessage id="Suivez votre campagne de netlinking à l'aide de votre dashboard. Vous pouvez la modifier à tout moment." />
        }
      />
      <div className={styles.container}>
        <Header />
      </div>
      <div className={styles["container--white"]}>
        <div className={styles.container}>
          <Articles />
          <Configuration />
        </div>
      </div>
    </NetlinkingContext.Provider>
  );
};

export default NetlinkingDashboard;
