import React from "react";
import style from "./Information.module.sass";
import shop from "./shop.svg";

const Information = () => (
  <div className={style.wrapper}>
    <div className={style.container}>
      <img className={style.container__image} src={shop} alt="shop" />
      <div className={style.container__desc}>
        <h3 className={style.desc__title}>Pourquoi Octopulse ?</h3>
        <ul className={style.desc__list}>
          <li>
            <b>Clé en main :</b> prestation rapide et sécurisée
          </li>
          <li>
            <b>À la page :</b> nous veillons aux moindres mises à jour de Google
          </li>
          <li>
            <b>Esprit tranquille :</b> prestation couverte par Axa Assurance
          </li>
        </ul>
        <span className={style.desc__sentence}>
          Modifier un site est délicat, laissez faire nos experts !
        </span>
      </div>
    </div>
  </div>
);

export default Information;
