import React, { FC, useEffect, useRef, useState } from "react";
import NetlinkingSelectionPage, {
  NetlinkingQuote
} from "./NetlinkingSelectionPage";
import { Modal } from "../../../components";
import Contact from "../../Contact";

const NetlinkingSelection: FC = () => {
  const [quote, setQuote] = useState<NetlinkingQuote>();
  const modalContact = useRef(null);

  useEffect(() => {
    if (quote) {
      // @ts-ignore
      modalContact.current.openModal();
    }
  }, [quote]);

  return (
    <>
      <NetlinkingSelectionPage
        withHeader
        onQuoteValidation={obj => setQuote(obj)}
      />
      <Modal ref={modalContact}>
        <Contact type="Netlinking" modal={modalContact} />
      </Modal>
    </>
  );
};

export default NetlinkingSelection;
