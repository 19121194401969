import React from "react";
import PropTypes from "prop-types";
import style from "./HelpButton.module.sass";

const HelpButton = ({ children, className, id }) => (
  <span
    className={[style.button, className].join(" ")}
    onClick={() => window.$crisp.push(["do", "chat:open"])}
    id={id}
  >
    {children}
  </span>
);

HelpButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string
};

export default HelpButton;
