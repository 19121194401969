import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import axios from "api/axios";
import LogRocket from "logrocket";
import rootReducer from "./rootReducer";

// Enable Redux dev tool (only in development)
const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(axios), LogRocket.reduxMiddleware())
  )
);

export default store;
