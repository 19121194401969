import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import NetlinkingSelection from "./NetlinkingSelection";
import { LoadingPage } from "../../components";
import { getNetlinkingCampaigns } from "../../actions/netlinking";

const Netlinking: FC = () => {
  const history = useHistory();
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getNetlinkingCampaigns()).then(({ netlinkingCampaigns }) => {
      if (netlinkingCampaigns.length) {
        const { id } = netlinkingCampaigns[0]; // last netlinking campaign

        history.push(`/netlinking/${id}`);
      }
      setLoading(false);
    });
  }, []);

  return loading ? <LoadingPage /> : <NetlinkingSelection />;
};

export default Netlinking;
