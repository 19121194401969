import React from "react";
import Loading from "./Loading";
import style from "./LoadingPage.module.sass";

const LoadingPage = () => (
  <div className={style.container}>
    <Loading />
  </div>
);

export default LoadingPage;
