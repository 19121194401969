import React from "react";
import style from "./Box.module.sass";

const Box = ({ children }) => (
  <div className={style.container}>
    <div className={style.container__content}>{children}</div>
  </div>
);

export default Box;
