import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Formik } from "formik";

import { Button, Input, GoogleButton, TextLineSeparator } from "components";
import { googleSignin, login, setJwt, verifyEmail } from "actions/auth";
import {
  createLoadingSelector,
  createErrorMessageSelector
} from "api/selectors";
import style from "./Login.module.sass";
import Template from "../_template";

const ServerErrorMessage = ({ error }) => {
  if (!error) return null;
  if (error.data === "UnverifiedEmail")
    return (
      <div className={style.container__error}>
        <FormattedMessage id="login.errors.unverifiedEmail" />
      </div>
    );
  if (error.data === "WrongPassword" || error.data === "UnknownEmail")
    return (
      <div className={style.container__error}>
        <FormattedMessage id="login.errors.wrongCredentials" />
      </div>
    );
  return (
    <div className={style.container__error}>
      <FormattedMessage id="login.errors.generic" />
    </div>
  );
};

const Login = ({ dispatch, history, auth, error, loading, location }) => {
  const intl = useIntl();

  // get params in url
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const jwt = params.get("jwt");

  useEffect(() => {
    if (auth && auth.jsonWebToken) history.push("/");
    if (token) dispatch(verifyEmail(token)).then(() => history.push("/"));
    if (jwt) {
      dispatch(setJwt(jwt));
      history.push("/");
    }
  }, []);

  function validateForm(values) {
    const errors = {};

    // -----EMAIL-----
    if (!values.email) {
      errors.email = intl.formatMessage({ id: "login.errors.missingEmail" });
    }

    // -----PASSWORD-----
    if (!values.password) {
      errors.password = intl.formatMessage({
        id: "login.errors.missingPassword"
      });
    }

    return errors;
  }

  return (
    <Template title={intl.formatMessage({ id: "login.title" })}>
      <Formik
        initialValues={{
          email: "",
          password: ""
        }}
        validate={values => validateForm(values)}
        onSubmit={values => {
          dispatch(login(values.email, values.password)).then(() => {
            history.push("/");
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form className={style.container} onSubmit={handleSubmit}>
            <Input
              placeholder={intl.formatMessage({
                id: "login.placeholders.email"
              })}
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email ? errors.email : null}
            />
            <Input
              placeholder={intl.formatMessage({
                id: "login.placeholders.password"
              })}
              type="password"
              name="password"
              id="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={
                errors.password && touched.password ? errors.password : null
              }
            />
            <Link to="/forget" className={style.container__forgot}>
              <FormattedMessage id="login.links.forgottenPassword" />
            </Link>
            <ServerErrorMessage error={error} />
            <div className={style.container__bottom}>
              <div className={style.bottom__buttons}>
                <Link to="/signup" className={style.bottom__signup}>
                  <FormattedMessage id="login.links.signUp" />
                </Link>
                <Button submit disabled={loading}>
                  <FormattedMessage id="login.links.signIn" />
                </Button>
              </div>
              <TextLineSeparator className={style.bottom__or}>
                <FormattedMessage id="global.separator.or" />
              </TextLineSeparator>
              <GoogleButton onClick={() => dispatch(googleSignin())}>
                <FormattedMessage id="login.links.googleSignIn" />
              </GoogleButton>
            </div>
          </form>
        )}
      </Formik>
    </Template>
  );
};

const loadingSelector = createLoadingSelector(["LOGIN"]);
const errorSelector = createErrorMessageSelector(["LOGIN"]);

const mapStateToProps = state => ({
  auth: state.auth,
  loading: loadingSelector(state),
  error: errorSelector(state)
});

export default withRouter(connect(mapStateToProps)(Login));
