import React from "react";

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.question-circle,.b{fill:none;stroke-width:1.5px;}.question-circle{stroke-linecap:round;stroke-linejoin:round;}.b{stroke-miterlimit:10;}`}</style>
    </defs>
    <path
      className="question-circle"
      d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
    />
    <path
      className="question-circle"
      d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
    />
    <circle className="b" cx="12" cy="12" r="11.25" />
  </svg>
);
