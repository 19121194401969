import React from "react";

export default () => (
  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  //   <path d="M16.187,3.511a.514.514,0,0,0-.707,0L4.067,14.924a.5.5,0,0,0,0,.707l4.28,4.28a.5.5,0,0,0,.707,0L20.467,8.5a.5.5,0,0,0,0-.707Z" />
  //   <path d="M3,16.71a.5.5,0,0,0-.828.2l-2.14,6.42a.5.5,0,0,0,.12.512.508.508,0,0,0,.512.121l6.42-2.139a.5.5,0,0,0,.2-.829Z" />
  //   <path d="M22.968,1.017a3.613,3.613,0,0,0-4.988,0l-.712.713a.5.5,0,0,0,0,.707l4.279,4.28a.5.5,0,0,0,.707,0L22.968,6A3.53,3.53,0,0,0,22.968,1.017Z" />
  // </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <rect
      className="a"
      x="9.268"
      y="1.09"
      width="6"
      height="21.284"
      transform="translate(11.889 -5.238) rotate(45)"
    />
    <polygon
      className="a"
      points="2.621 17.136 0.5 23.5 6.864 21.379 2.621 17.136"
    />
    <path
      className="a"
      d="M21.914,6.328,17.672,2.086l.707-.707a3,3,0,0,1,4.242,4.242Z"
    />
  </svg>
);
