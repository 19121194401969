import React from "react";

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs>
      <style>{`.a{fill:none;stroke-linecap:round;stroke-linejoin:round;}`}</style>
    </defs>
    <polyline className="a" points="18.5 7.497 23.5 12.497 18.5 17.497" />
    <line className="a" x1="23.5" y1="12.497" x2="0.5" y2="12.497" />
  </svg>
);
