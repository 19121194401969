import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "../../../components";
import style from "./WarningPage.module.sass";

const WarningPage = () => (
  <div className={style.page}>
    <span className={style.message}>
      Cliquez sur <b>Ma position</b> <br />
      et ajoutez vos premiers mots-clés !
    </span>
    <h3>
      Vous devez d&rsquo;abord créer un projet de suivi de position pour accéder
      à cette page.
    </h3>
    <Button cta to="/ranking">
      Créer mon premier projet
    </Button>
  </div>
);

export default withRouter(WarningPage);
