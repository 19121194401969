import React, { useEffect, useState } from "react";
import { Breadcrumb, ErrorPage } from "components";
import { connect } from "react-redux";
import { getLastAuditByStatus, getModuleFromJson } from "utils";
import Header from "./Header";
import Kpis from "./Kpis";
import List from "./List";
import MobileFooterPrice from "./MobileFooterPrice";
import Information from "./Information";
import style from "./Module.module.sass";
import { getModule } from "../../../actions/audit";
import LoadingPage from "../../../components/Loading/LoadingPage";
import WarningOldAudit from "../WarningOldAudit";
import AuditContext from "./AuditContext";

const Module = ({ match, dispatch, audit, currentWebsite }) => {
  const [onEnterLoading, setOnEnterLoading] = useState(true);
  const [error, setError] = useState(false);
  const { id: auditId } = match.params;
  const lastFinishedAudit = getLastAuditByStatus("FINISHED");
  const isAuditOld = lastFinishedAudit.id !== Number(auditId);

  useEffect(() => {
    const { id, module } = match.params;
    const { id: websiteId } = currentWebsite;

    dispatch(getModule(websiteId, id, module))
      .then(() => setOnEnterLoading(false))
      .catch(() => {
        setError(true);
        setOnEnterLoading(false);
      });
  }, []);

  if (onEnterLoading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  return (
    <AuditContext.Provider value={{ isAuditOld }}>
      <div className={style.container}>
        <WarningOldAudit />
        <Breadcrumb title={getModuleFromJson(audit.module.name).title} />
        <Header />
        <Kpis />
        <List />
        <Information />
      </div>
      {/* not show if module 100% complete and is not last audit */}
      {audit.module.progress < 1 && !isAuditOld && <MobileFooterPrice />}
    </AuditContext.Provider>
  );
};

const mapStateToProps = state => ({
  audit: state.audit,
  currentWebsite: state.websites.current
});

export default connect(mapStateToProps)(Module);
