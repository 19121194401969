import React, { forwardRef } from "react";
import styles from "./TextArea.module.scss";

export type Ref = HTMLTextAreaElement;

interface OwnProps {
  error?: string;
  label?: string;
}

type Props = OwnProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = forwardRef<Ref, Props>(
  ({ children, error, label, ...textAreaProps }, ref) => (
    <label htmlFor="message" className={styles.container}>
      {label && <p className={styles.label}>{label}</p>}

      <textarea
        {...textAreaProps}
        className={[styles.textarea, error && styles["textarea--error"]].join(
          " "
        )}
        ref={ref}
      />

      {error && <p className={styles.error}>{error}</p>}
    </label>
  )
);

export default TextArea;
