import React, { FC, ReactNode } from "react";
import style from "./Box.module.sass";

interface BoxProps {
  title: string;
  children: ReactNode;
}

const Box: FC<BoxProps> = ({ title, children }) => (
  <div className={style.container}>
    <div className={style.header}>{title}</div>
    {children}
  </div>
);

export default Box;
