import React from "react";
import PropTypes from "prop-types";
import style from "./TextLineSeparator.module.sass";

const TextLineSeparator = ({ children, className }) => (
  <div className={[className, style.container].join(" ")}>
    <span>{children}</span>
  </div>
);

TextLineSeparator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default TextLineSeparator;
