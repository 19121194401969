// See more : https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6

import _ from "lodash";

export const createLoadingSelector = actions => state =>
  // returns true only when all actions are not loading
  _(actions).some(action => _.get(state, `loading.${action}`));

// ----------USAGE EXAMPLE----------
// ==== Show loading when any of GET_PROJECT_REQUEST, GET_USER_REQUEST is active (can also pass only one parameter) ====
//
// import { createLoadingSelector } from '../../api/selectors';
// const loadingSelector = createLoadingSelector(['GET_PROJECT', 'GET_USER']);
// function mapStateToProps(state) {
//     return {
//         isFetching: loadingSelector(state)
//     };
// }

export const createErrorMessageSelector = actions => state =>
  // returns the first errors messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first errors
  _(actions)
    .map(action => _.get(state, `errors.${action}`))
    .compact()
    .first() || "";

// ----------USAGE EXAMPLE----------
// ==== Show first errors when any of GET_PROJECT_REQUEST, GET_USER_REQUEST has failed ====
//
// import { createErrorMessageSelector } from '../../api/selectors';
// const errorSelector = createErrorMessageSelector(['GET_PROJECT_PROJECT', 'GET_USER']);
// function mapStateToProps(state) {
//     return {
//         isError: errorSelector(state)
//     };
// }
