import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import apollo from "../api/apollo";

export const GET_NETLINKING_CAMPAIGNS_SUCCESS =
  "GET_NETLINKING_CAMPAIGNS_SUCCESS";
export const GET_NETLINKING_CAMPAIGNS_FAILURE =
  "GET_NETLINKING_CAMPAIGNS_FAILURE";
export const getNetlinkingCampaigns = () => async (dispatch, getState) => {
  const { current } = getState().websites;

  dispatch({ type: "GET_NETLINKING_CAMPAIGNS_REQUEST" });

  try {
    const {
      data: { netlinkingCampaigns }
    } = await apollo.query({
      query: gql`
        query GetNetlinkingCampaigns($input: NetlinkingCampaignInput) {
          netlinkingCampaigns(input: $input) {
            id
            name
            monthlyArticlesNumber
            status
            duration
            createdAt
          }
        }
      `,
      variables: {
        input: { websiteId: current.id }
      },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_NETLINKING_CAMPAIGNS_SUCCESS,
      netlinkingCampaigns
    });
  } catch (error) {
    dispatch({
      type: GET_NETLINKING_CAMPAIGNS_FAILURE,
      error
    });
    throw error;
  }
};

export const GET_NETLINKING_CAMPAIGN_SUCCESS =
  "GET_NETLINKING_CAMPAIGN_SUCCESS";
export const GET_NETLINKING_CAMPAIGN_FAILURE =
  "GET_NETLINKING_CAMPAIGN_FAILURE";
export const getNetlinkingCampaign = id => async dispatch => {
  dispatch({ type: "GET_NETLINKING_CAMPAIGN_REQUEST" });

  try {
    const {
      data: { netlinkingCampaign }
    } = await apollo.query({
      query: gql`
        query GetNetlinkingCampaign($id: ID!) {
          netlinkingCampaign(id: $id) {
            id
            name
            monthlyArticlesNumber
            status
            duration
            createdAt
            websiteId
          }
        }
      `,
      variables: { id },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_NETLINKING_CAMPAIGN_SUCCESS,
      netlinkingCampaign
    });
  } catch (error) {
    dispatch({
      type: GET_NETLINKING_CAMPAIGN_FAILURE,
      error
    });
    throw error;
  }
};

export const GET_NETLINKING_CONFIGURATIONS_SUCCESS =
  "GET_NETLINKING_CONFIGURATIONS_SUCCESS";
export const GET_NETLINKING_CONFIGURATIONS_FAILURE =
  "GET_NETLINKING_CONFIGURATIONS_FAILURE";
export const getNetlinkingConfigurations = netlinkingCampaignId => async dispatch => {
  dispatch({ type: "GET_NETLINKING_CONFIGURATIONS_REQUEST" });

  try {
    const {
      data: { netlinkingConfigurations }
    } = await apollo.query({
      query: gql`
        query GetNetlinkingConfigurations(
          $input: NetlinkingConfigurationInput
        ) {
          netlinkingConfigurations(input: $input) {
            id
            targetUrl
            ratio
            targetKeywords
          }
        }
      `,
      variables: {
        input: { netlinkingCampaignId }
      },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_NETLINKING_CONFIGURATIONS_SUCCESS,
      netlinkingConfigurations
    });
  } catch (error) {
    dispatch({
      type: GET_NETLINKING_CONFIGURATIONS_FAILURE,
      error
    });
    throw error;
  }
};

export const GET_NETLINKING_PUBLICATIONS_SUCCESS =
  "GET_NETLINKING_PUBLICATIONS_SUCCESS";
export const GET_NETLINKING_PUBLICATIONS_FAILURE =
  "GET_NETLINKING_PUBLICATIONS_FAILURE";
export const getNetlinkingPublications = netlinkingCampaignId => async dispatch => {
  dispatch({ type: "GET_NETLINKING_PUBLICATIONS_REQUEST" });

  try {
    const {
      data: { netlinkingPublications }
    } = await apollo.query({
      query: gql`
        query GetNetlinkingPublications($input: NetlinkingPublicationInput) {
          netlinkingPublications(input: $input) {
            id
            url
            type
            trustFlowScore
            trustFlowTopic
            citationFlow
            date
            netlinkingConfiguration {
              targetUrl
            }
          }
        }
      `,
      variables: {
        input: { netlinkingCampaignId }
      },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_NETLINKING_PUBLICATIONS_SUCCESS,
      netlinkingPublications
    });
  } catch (error) {
    dispatch({
      type: GET_NETLINKING_PUBLICATIONS_FAILURE,
      error
    });
    throw error;
  }
};

export const NOTIFY_CHANGE_NETLINKING_CAMPAIGN_SUCCESS =
  "NOTIFY_CHANGE_NETLINKING_CAMPAIGN_SUCCESS";
export const NOTIFY_CHANGE_NETLINKING_CAMPAIGN_FAILURE =
  "NOTIFY_CHANGE_NETLINKING_CAMPAIGN_FAILURE";
export const notifyChangeNetlinkingCampaign = (
  netlinkingCampaignId,
  campaignDuration,
  comment: string | null = null
) => async (dispatch, getState, axios) => {
  dispatch({ type: "NOTIFY_CHANGE_NETLINKING_CAMPAIGN_REQUEST" });

  try {
    await axios.put(`/netlinking/${netlinkingCampaignId}`, {
      campaignDuration,
      comment
    });

    return dispatch({ type: NOTIFY_CHANGE_NETLINKING_CAMPAIGN_SUCCESS });
  } catch (error) {
    toast.error("Un erreur est survenue");
    dispatch({
      type: NOTIFY_CHANGE_NETLINKING_CAMPAIGN_FAILURE,
      error
    });
    throw error;
  }
};

export const NOTIFY_CHANGE_NETLINKING_PUBLICATION_SUCCESS =
  "NOTIFY_CHANGE_NETLINKING_PUBLICATION_SUCCESS";
export const NOTIFY_CHANGE_NETLINKING_PUBLICATION_FAILURE =
  "NOTIFY_CHANGE_NETLINKING_PUBLICATION_FAILURE";
export const notifyChangeNetlinkingPublication = (
  netlinkingCampaignId,
  netlinkingPublicationId,
  comment: string
) => async (dispatch, getState, axios) => {
  dispatch({ type: "NOTIFY_CHANGE_NETLINKING_PUBLICATION_REQUEST" });

  try {
    await axios.put(
      `/netlinking/${netlinkingCampaignId}/publication/${netlinkingPublicationId}`,
      {
        comment
      }
    );

    return dispatch({ type: NOTIFY_CHANGE_NETLINKING_PUBLICATION_SUCCESS });
  } catch (error) {
    toast.error("Un erreur est survenue");
    dispatch({
      type: NOTIFY_CHANGE_NETLINKING_PUBLICATION_FAILURE,
      error
    });
    throw error;
  }
};

export const NOTIFY_CHANGE_NETLINKING_CONFIGURATION_SUCCESS =
  "NOTIFY_CHANGE_NETLINKING_CONFIGURATION_SUCCESS";
export const NOTIFY_CHANGE_NETLINKING_CONFIGURATION_FAILURE =
  "NOTIFY_CHANGE_NETLINKING_CONFIGURATION_FAILURE";
export const notifyChangeNetlinkingConfiguration = (
  netlinkingCampaignId,
  netlinkingConfigurationId,
  comment: string
) => async (dispatch, getState, axios) => {
  dispatch({ type: "NOTIFY_CHANGE_NETLINKING_CONFIGURATION_REQUEST" });

  try {
    await axios.put(
      `/netlinking/${netlinkingCampaignId}/configuration/${netlinkingConfigurationId}`,
      {
        comment
      }
    );

    return dispatch({ type: NOTIFY_CHANGE_NETLINKING_CONFIGURATION_SUCCESS });
  } catch (error) {
    toast.error("Un erreur est survenue");
    dispatch({
      type: NOTIFY_CHANGE_NETLINKING_CONFIGURATION_FAILURE,
      error
    });
    throw error;
  }
};
