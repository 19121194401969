import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import style from "./List.module.sass";
import { getOptimizationFromJson } from "../../../../utils";
import AddToCartButton from "../_addToCartButton";
import AuditContext from "../AuditContext";

const getImportanceScore = (score, minSuccessScore, maxFailScore) => {
  if (score >= minSuccessScore) return 1;
  if (score < maxFailScore) return 3;
  return 2;
};

const Badge = ({ type, children }) => (
  <div className={[style.badge, style[`badge--${type}`]].join(" ")}>
    <div className={style.badge__circle} />
    <p>{children}</p>
  </div>
);

const Item = ({ tag, score, TechnoOptimisations }) => {
  const { minSuccessScore, maxFailScore } = TechnoOptimisations[0];
  const optimFromJson = getOptimizationFromJson(tag);

  const showBadge = () => {
    const importance = getImportanceScore(score, minSuccessScore, maxFailScore);

    switch (importance) {
      case 1:
        return <Badge type="green">Correct</Badge>;
      case 2:
        return <Badge type="orange">Perfectible</Badge>;
      default:
        return <Badge type="red">Problématique</Badge>;
    }
  };

  return (
    <div className={style.item}>
      <div className={style.item__left}>
        <span className={style.item__title}>{optimFromJson.title}</span>
        <span className={style.item__subtitle}>
          {optimFromJson.technical_title}
        </span>
      </div>
      {/* TODO: remettre quand "Voir les urls impactés" dispo */}
      {/* <Button>Voir les URLs impactées</Button> */}
      <div className={style.item__number}>{showBadge()}</div>
    </div>
  );
};

const List = ({ module }) => {
  const [sortableOptims, setSortableOptims] = useState(null);
  const { isAuditOld } = useContext(AuditContext);

  // add importance field in order to make optims sortable
  useEffect(() => {
    const newOptims = module.optims;

    newOptims.forEach(optim => {
      const { score, TechnoOptimisations } = optim;
      const { minSuccessScore, maxFailScore } = TechnoOptimisations[0];

      optim.importance = getImportanceScore(
        score,
        minSuccessScore,
        maxFailScore
      );
    });

    setSortableOptims(newOptims);
  }, []);

  if (module.optims.length === 0) return null;
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <h3 className={style.container__title}>
          Cette optimisation contient :
        </h3>
        <div className={style.container__list}>
          {sortableOptims &&
            _.orderBy(sortableOptims, "importance", "desc").map(optim => (
              <Item key={optim.id} {...optim} />
            ))}
        </div>
        {/* enabled for last audit only */}
        {module.progress < 1 && !isAuditOld && (
          <div className={style.container__button}>
            <AddToCartButton />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ module: state.audit.module });

Item.propTypes = {
  tag: PropTypes.string.isRequired
};

export default withRouter(connect(mapStateToProps)(List));
