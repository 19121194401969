import React from "react";
import PropTypes from "prop-types";
import style from "./Box.module.sass";
import { Check } from "../../../../img/icons";

const Box = ({ selected, title, subtitle, children, onSelect, index }) => (
  <div
    className={selected ? style["container--selected"] : style.container}
    onClick={() => onSelect(index)}
  >
    <h3>{title}</h3>
    {subtitle && <span className={style.container__subtitle}>{subtitle}</span>}
    <div className={style.container__content}>{children}</div>
    {selected && (
      <div className={style.selected}>
        <div className={style.selected__check}>
          <Check />
        </div>
        <p>Sélectionné</p>
      </div>
    )}
  </div>
);

Box.propTypes = {
  index: PropTypes.number,
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

export default Box;
