import { gql } from "apollo-boost";
import apollo from "../api/apollo";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const getUser = () => (dispatch, getState, axios) => {
  dispatch({ type: "GET_USER_REQUEST" });
  return axios
    .get("/users/self")
    .then(({ data: user }) => dispatch({ type: GET_USER_SUCCESS, user }))
    .catch(error =>
      dispatch({
        type: GET_USER_FAILURE,
        error
      })
    );
};

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const updateUser = (firstName, lastName, phone) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "UPDATE_USER_REQUEST" });
  return axios
    .put("/users/self", { firstName, lastName, phone })
    .then(({ data: user }) =>
      dispatch({
        type: UPDATE_USER_SUCCESS,
        user
      })
    )
    .catch(error => {
      dispatch({
        type: UPDATE_USER_FAILURE,
        error
      });
      throw error;
    });
};

export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
export const updatePassword = (oldPassword, newPassword) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "UPDATE_PASSWORD_REQUEST" });
  return axios
    .put("/users/self/password", { oldPassword, newPassword })
    .then(() =>
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS
      })
    )
    .catch(error => {
      dispatch({
        type: UPDATE_PASSWORD_FAILURE,
        error
      });
      throw error;
    });
};

export const GET_USER_EMAIL_PREFERENCE_SUCCESS =
  "GET_USER_EMAIL_PREFERENCE_SUCCESS";
export const GET_USER_EMAIL_PREFERENCE_FAILURE =
  "GET_USER_EMAIL_PREFERENCE_FAILURE";
export const getUserEmailPreference = () => async (dispatch, getState) => {
  const { user } = getState();
  dispatch({ type: "GET_USER_EMAIL_PREFERENCE_REQUEST" });

  try {
    const {
      data: { userEmailPreference }
    } = await apollo.query({
      query: gql`
        query getUserEmailPreference($id: ID!) {
          userEmailPreference(id: $id) {
            id
            rankingReportingPeriodicity
          }
        }
      `,
      variables: {
        id: user.id
      },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_USER_EMAIL_PREFERENCE_SUCCESS,
      userEmailPreference
    });
  } catch (error) {
    dispatch({ type: GET_USER_EMAIL_PREFERENCE_FAILURE, error });
    throw error;
  }
};

export const UPDATE_USER_EMAIL_PREFERENCE_SUCCESS =
  "UPDATE_USER_EMAIL_PREFERENCE_SUCCESS";
export const UPDATE_USER_EMAIL_PREFERENCE_FAILURE =
  "UPDATE_USER_EMAIL_PREFERENCE_FAILURE";
export const updateUserEmailPreference = input => async (
  dispatch,
  getState
) => {
  const { user } = getState();
  dispatch({ type: "UPDATE_USER_EMAIL_PREFERENCE_REQUEST" });

  try {
    const {
      data: { updateUserEmailPreference: userEmailPreference }
    } = await apollo.mutate({
      mutation: gql`
        mutation UpdateUserEmailPreference(
          $id: ID
          $input: UserEmailPreferenceInput
        ) {
          updateUserEmailPreference(id: $id, input: $input) {
            id
          }
        }
      `,
      variables: {
        id: user.id,
        input
      },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: UPDATE_USER_EMAIL_PREFERENCE_SUCCESS,
      userEmailPreference
    });
  } catch (error) {
    dispatch({ type: UPDATE_USER_EMAIL_PREFERENCE_FAILURE, error });
    throw error;
  }
};
