import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { getAuditById, getLastAuditByStatus } from "../../utils";
import style from "./WarningOldAudit.module.sass";

const WarningOldAudit = ({ match }) => {
  const { id: auditId } = match.params;
  const lastFinishedAudit = getLastAuditByStatus("FINISHED");
  const isAuditOld = lastFinishedAudit.id !== Number(auditId);
  const currentAudit = getAuditById(auditId);

  if (isAuditOld)
    return (
      <div className={style.container}>
        Vous naviguez sur une ancienne analyse datant du{" "}
        {moment(currentAudit.createdAt).format("DD/MM/YYYY")}.
      </div>
    );
  return null;
};

export default withRouter(WarningOldAudit);
