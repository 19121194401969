import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import apollo from "../api/apollo";

export const ESTIMATE_CPC_SUCCESS = "ESTIMATE_CPC_SUCCESS";
export const ESTIMATE_CPC_FAILURE = "ESTIMATE_CPC_FAILURE";
export const estimateCpc = () => (dispatch, getState, axios) => {
  dispatch({ type: "ESTIMATE_CPC_REQUEST" });
  return axios
    .get("/sea/estimation")
    .then(estimation => dispatch({ type: ESTIMATE_CPC_SUCCESS, estimation }))
    .catch(({ response: error }) => {
      dispatch({
        type: ESTIMATE_CPC_FAILURE,
        error
      });
      throw error;
    });
};

export const CHANGE_SEA_BUDGET_SUCCESS = "CHANGE_SEA_BUDGET_SUCCESS";
export const CHANGE_SEA_BUDGET_FAILURE = "CHANGE_SEA_BUDGET_FAILURE";
export const changeSeaBudget = (adsCampaignId, budget) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "CHANGE_SEA_BUDGET_REQUEST" });
  return axios
    .put(`/sea/${adsCampaignId}`, { budget })
    .then(() => {
      toast.success(
        "Votre nouveau budget a bien été pris en compte, nous allons revenir vers vous rapidement"
      );
      return dispatch({ type: CHANGE_SEA_BUDGET_SUCCESS });
    })
    .catch(({ response: error }) => {
      dispatch({
        type: CHANGE_SEA_BUDGET_FAILURE,
        error
      });
      toast.error("Une erreur est survenue");
      throw error;
    });
};

export const GET_ADS_CAMPAIGNS_SUCCESS = "GET_ADS_CAMPAIGNS_SUCCESS";
export const GET_ADS_CAMPAIGNS_FAILURE = "GET_ADS_CAMPAIGNS_FAILURE";
export const getAdsCampaigns = () => async (dispatch, getState) => {
  const {
    websites: { current }
  } = getState();
  dispatch({ type: "GET_ADS_CAMPAIGNS_REQUEST" });

  try {
    const {
      data: { website }
    } = await apollo.query({
      query: gql`
        query getAdsCampaigns($id: ID!) {
          website(id: $id) {
            adsCampaigns {
              id
              budget
              datastudioId
              createdAt
            }
          }
        }
      `,
      variables: {
        id: current.id
      },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_ADS_CAMPAIGNS_SUCCESS,
      adsCampaigns: website.adsCampaigns
    });
  } catch (error) {
    dispatch({ type: GET_ADS_CAMPAIGNS_FAILURE, error });
    throw error;
  }
};

export const GET_ADS_CAMPAIGN_SUCCESS = "GET_ADS_CAMPAIGN_SUCCESS";
export const GET_ADS_CAMPAIGN_FAILURE = "GET_ADS_CAMPAIGN_FAILURE";
export const getAdsCampaign = (id, startDate, endDate) => async dispatch => {
  dispatch({ type: "GET_ADS_CAMPAIGN_REQUEST" });

  try {
    const {
      data: { adsCampaign }
    } = await apollo.query({
      query: gql`
        query getAdsCampaign(
          $id: ID!
          $fromDate: DateTime!
          $toDate: DateTime!
        ) {
          adsCampaign(id: $id, fromDate: $fromDate, toDate: $toDate) {
            id
            websiteId
            budget
            datastudioId
            customer
            campaigns
          }
        }
      `,
      variables: {
        id,
        fromDate: startDate.format("YYYY-MM-DD"),
        toDate: endDate.format("YYYY-MM-DD")
      },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_ADS_CAMPAIGN_SUCCESS,
      adsCampaign
    });
  } catch (error) {
    dispatch({ type: GET_ADS_CAMPAIGN_FAILURE, error });
    throw error;
  }
};

export const GET_AD_GROUPS_SUCCESS = "GET_AD_GROUPS_SUCCESS";
export const GET_AD_GROUPS_FAILURE = "GET_AD_GROUPS_FAILURE";
export const getAdGroups = (
  id,
  campaignId,
  startDate,
  endDate
) => async dispatch => {
  dispatch({ type: "GET_AD_GROUPS_REQUEST" });

  try {
    const {
      data: {
        adsCampaign: { adGroups }
      }
    } = await apollo.query({
      query: gql`
        query getAdsCampaign(
          $id: ID!
          $campaignId: ID!
          $fromDate: DateTime!
          $toDate: DateTime!
        ) {
          adsCampaign(id: $id, fromDate: $fromDate, toDate: $toDate) {
            adGroups(campaignId: $campaignId)
          }
        }
      `,
      variables: {
        id,
        campaignId,
        fromDate: startDate.format("YYYY-MM-DD"),
        toDate: endDate.format("YYYY-MM-DD")
      },
      fetchPolicy: "no-cache"
    });

    return dispatch({
      type: GET_AD_GROUPS_SUCCESS,
      adGroups
    });
  } catch (error) {
    dispatch({ type: GET_AD_GROUPS_FAILURE, error });
    throw error;
  }
};

export const CHANGE_CAMPAIGN_DATES = "CHANGE_CAMPAIGN_DATES";
export const changeCampaignDates = (startDate, endDate) => ({
  type: CHANGE_CAMPAIGN_DATES,
  startDate,
  endDate
});

export const RESET_ADS_CAMPAIGNS = "RESET_ADS_CAMPAIGNS";
export const resetAdsCampaigns = () => dispatch => {
  Promise.resolve(dispatch({ type: RESET_ADS_CAMPAIGNS }));
};
