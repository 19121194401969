import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { useRef } from "react";
import isModuleInCart from "../../../../utils/isModuleInCart";
import style from "./AddToCartButton.module.sass";
import { removeItemFromCart } from "../../../../actions/cart";
import { Button, Modal } from "../../../../components";
import Contact from "../../../Contact";

const AddToCartButton = ({ dispatch, match }) => {
  const moduleId = Number(match.params.module);
  const cartItem = isModuleInCart(moduleId);
  const modalContact = useRef(null);

  if (cartItem)
    return (
      <div
        className={style.container}
        onClick={() => dispatch(removeItemFromCart(cartItem.id))}
      >
        <span className={style.added}>Ajouté</span>
        <span className={style.remove}>Retirer</span>
      </div>
    );
  return (
    <>
      <Modal ref={modalContact}>
        <Contact type="SEO" modal={modalContact} />
      </Modal>
      <Button
        onClick={() => modalContact.current.openModal()}
        cta
        id="add-to-cart"
      >
        Optimiser mon site
      </Button>
    </>
  );
};

/**
 * /!\ DO NOT REMOVE /!\
 * Cart props is used to rerender the component when
 * the module is removed from the shopping cart
 */
const mapStateToProps = state => ({
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(AddToCartButton));
