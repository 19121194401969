import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import moment from "moment";
import "./styles.sass";
import { toast } from "react-toastify";
import { Link, withRouter } from "react-router-dom";
import style from "./SelectAudit.module.sass";
import { AddCircle } from "../../../../../img/icons";
import { launchAudit } from "../../../../../actions/audit";
import { getLastAuditByStatus } from "../../../../../utils";

const mapStateToPropsMenu = state => ({
  user: state.user,
  audits: state.audit.audits,
  currentWebsite: state.websites.current,
  subscriptions: state.global.subscriptions
});

const Menu = connect(mapStateToPropsMenu)(props => {
  const {
    audits,
    currentWebsite,
    dispatch,
    user: {
      Account: { subscription },
      rights: {
        audit: { restartDelay }
      }
    },
    subscriptions
  } = props;

  const [loadingLaunch, setLoadingLaunch] = useState(false);

  const lastCrawlingAudit = getLastAuditByStatus("CRAWLING");
  const lastLaunchedAudit = audits[0];
  const daysSinceLastAudit = moment().diff(
    moment(lastLaunchedAudit.createdAt),
    "days"
  );
  const daysBeforeNextAudit = restartDelay - daysSinceLastAudit;
  // allow if user waits X days based on his subscription or when a user just take a subscription and his last audit is a "free" audit
  const isAllowToLaunchAudit =
    daysBeforeNextAudit <= 0 ||
    (subscription !== "FREE" &&
      lastLaunchedAudit.maxURL === subscriptions.FREE.audit.maxURL);

  const createAudit = () => {
    if (isAllowToLaunchAudit) {
      let message =
        "En cliquant sur OK, une nouvelle analyse de votre site sera lancée.";

      if (subscription === "FREE")
        message +=
          " Votre abonnement actuel ne vous permet pas de revenir sur votre ancienne analyse.";

      if (window.confirm(message)) {
        setLoadingLaunch(true);

        dispatch(launchAudit(currentWebsite.id))
          .then(() => toast.success("Votre audit a été lancé avec succès !"))
          .catch(() => toast.error("Une erreur est survenue"))
          .finally(() => setLoadingLaunch(false));
      }
    }
  };

  return (
    <components.Menu {...props}>
      {lastCrawlingAudit ? (
        // an audit is in progress
        <div className={style.inProgress}>
          Une nouvelle analyse de votre site est en cours...
        </div>
      ) : (
        <>
          {/* show button to launch a new audit */}
          {isAllowToLaunchAudit && (
            <div
              onClick={() => createAudit()}
              className={[
                style.button,
                loadingLaunch && style["button--disabled"]
              ].join(" ")}
            >
              <div>
                <AddCircle />
                Lancer un nouvel audit
              </div>
            </div>
          )}
          {/* show message indicates that user needs to wait before launching a new audit */}
          {!isAllowToLaunchAudit && (
            <div className={style.waitNextAudit}>
              <span>
                Vous pourrez relancer un audit dans {daysBeforeNextAudit} jours.
              </span>
              {subscription === "FREE" && (
                <Link to="/settings/subscription">
                  Passez PRO dès maintenant !
                </Link>
              )}
            </div>
          )}
        </>
      )}
      {props.children}
    </components.Menu>
  );
});

const SelectAudit = ({ audits, user, history, match }) => {
  const { id: auditId } = match.params;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const formatedOptions = audits
      .filter(({ status }) => status === "FINISHED")
      .map(audit => ({
        ...audit,
        value: audit.id,
        label: moment(audit.createdAt).format("DD/MM/YYYY"),
        isDisabled: !user.rights.audit.accessPreviousAudits
      }));

    setOptions(formatedOptions);
  }, [audits]);

  if (options.length)
    return (
      <Select
        noOptionsMessage={() => "Aucun résultat"}
        defaultValue={options.find(option => option.value === Number(auditId))}
        onChange={audit => history.push(`/audit/${audit.id}`)}
        className="select-audit-container"
        classNamePrefix="select-audit"
        options={options}
        components={{ Menu }}
      />
    );
  return null;
};

const mapStateToProps = state => ({
  user: state.user,
  audits: state.audit.audits
});

export default withRouter(connect(mapStateToProps)(SelectAudit));
