import React, { FC, ReactNode } from "react";
import styles from "./CtaBanner.module.scss";
import Button from "../Button";

interface CtaBannerProps {
  children: ReactNode;
  textButton: string;
  disabled?: boolean;
  onClick: () => void;
}

const CtaBanner: FC<CtaBannerProps> = ({
  children,
  textButton,
  onClick,
  disabled
}) => (
  <div className={styles.container}>
    <p>{children}</p>
    <Button cta onClick={onClick} disabled={disabled}>
      {textButton}
    </Button>
  </div>
);

export default CtaBanner;
