import React, { FC, useContext } from "react";
import NetlinkingContext from "./NetlinkingContext";
import styles from "./ArticlesSelection.module.sass";

const articlesType = [
  { number: 3 },
  { number: 4 },
  { number: 5, highlighted: true },
  { number: 6 },
  { number: 7 }
];

const ArticlesSelection: FC = () => {
  const { articles, setArticles } = useContext(NetlinkingContext);

  return (
    <div className={styles.articles}>
      {articlesType.map(({ number, highlighted }, index) => (
        <div className={styles.articles__radio} key={index}>
          {highlighted && (
            <span className={styles["articles__radio--highlighted"]}>
              Recommandé
            </span>
          )}
          <input
            id={`${number}`}
            type="radio"
            value={number}
            onChange={() => setArticles(number)}
            checked={articles === number}
          />
          <label htmlFor={`${number}`}>
            {index === articlesType.length - 1 && "+"}
            {number} articles
          </label>
        </div>
      ))}
    </div>
  );
};

export default ArticlesSelection;
