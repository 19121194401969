import React from "react";
import styles from "./Confetti.module.scss";

const Confetti = () => (
  <div className={styles.container}>
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />

    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />

    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />

    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
    <div className={styles.confetti} />
  </div>
);

export default Confetti;
