import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import "./styles.sass";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "../../../../components";
import styles from "./SelectCampaign.module.sass";
import NewCampaignModal from "../Modals/NewCampaignModal";
import useValidationModal from "../Modals/useValidationModal";

const Menu = props => {
  const { children, selectProps } = props;
  const { newCampaignModal } = selectProps;

  return (
    <components.Menu {...props}>
      {children}
      <div className={styles.menu__top}>
        <Button
          cta
          type="add"
          onClick={() => newCampaignModal.current.openModal()}
        >
          Nouvelle campagne
        </Button>
      </div>
    </components.Menu>
  );
};

const SelectCampaign = () => {
  const [options, setOptions] = useState([]);
  const { id: netlinkingCampaignId } = useParams();
  const { campaigns } = useSelector((state: any) => state.netlinking);
  const history = useHistory();
  const [newCampaignWithValidationModal, newCampaignModal] = useValidationModal(
    NewCampaignModal
  );

  useEffect(() => {
    if (campaigns) {
      setOptions(
        campaigns.map(({ id, name }) => ({
          value: id,
          label: name
        }))
      );
    }
  }, [campaigns]);

  return (
    <>
      {!!options.length && (
        <Select
          noOptionsMessage={() => "Aucun résultat"}
          defaultValue={options.find(
            ({ value }) => Number(value) === Number(netlinkingCampaignId)
          )}
          onChange={(campaign: any) => {
            history.push(`/netlinking/${campaign.value}`);
          }}
          className="select-campaign-container"
          classNamePrefix="select-campaign"
          options={options}
          isSearchable={false}
          components={{ Menu }}
          newCampaignModal={newCampaignModal} // pass in props (can be found in selectProps) so modal can work correctly
        />
      )}
      {newCampaignWithValidationModal}
    </>
  );
};

export default SelectCampaign;
