import React, { FunctionComponent, useRef } from "react";
import { FormattedMessage } from "react-intl";

// Assets
import moment from "moment";
import styles from "./Landing.module.scss";
import gmbOctopulse from "./gmb-octopulse.png";

// Components
import { CtaBanner, HeaderPage, Modal } from "../../../components/index";
import Button from "../../../components/Button/index";
import AskEstimate from "../Tabs/StoresList/AskEstimate";

const SeoLocal: FunctionComponent = () => {
  const modal = useRef(null);

  const handleClick = async () => {
    // @ts-ignore
    window.Calendly.initPopupWidget({ url: "https://go.dokey.io/partoo" });
    return false;
  };

  const isPromoActive = moment(Date.now()).isBefore(
    moment("01-02-2022", "DD-MM-YYYY")
  );

  return (
    <>
      <HeaderPage
        type="seolocal"
        title={<FormattedMessage id="seolocal.header.title" />}
        description={<FormattedMessage id="seolocal.header.desc" />}
      />
      <div className={styles.container}>
        {isPromoActive && (
          <section className={styles.promo}>
            <h3>-35% sur votre campagne de référencement local !</h3>
            <p>
              Seulement <b>25€ / mois</b> (au lieu de 35€) ou <b>250€ / an</b>{" "}
              (au lieu de 385€)
            </p>
          </section>
        )}
        <section>
          <h3>Qu&apos;est-ce que le référencement local ?</h3>
          <div className={styles.intro}>
            <div className={styles.intro__img}>
              <img src={gmbOctopulse} alt="google my business octopulse" />
              <span>Fiche Google My Business d&apos;Octopulse</span>
            </div>
            <div className={styles.intro__text}>
              <p>
                Le référencement local est un axe du référencement permettant d’
                <span className={styles.highlight}>
                  améliorer la visibilité de lieux physiques sur les moteurs de
                  recherche
                </span>
                . Il existe de nombreuses plateformes sur lesquelles votre store
                peut être présent : Google Maps, Apple Plan, Waze, Pages Jaunes,
                etc.
              </p>
              <div className={styles.cta}>
                <Button
                  cta
                  onClick={handleClick}
                  // disabled={disabled}
                >
                  Commencer
                </Button>
                <div className={styles.cta__text}>
                  <p>
                    Découvrez notre solution
                    <br />
                    dès maintenant !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.citation}>
          <blockquote>
            97 % des utilisateurs recherchant un commerce vont d’abord regarder
            sur Internet
          </blockquote>
        </section>
        <section>
          <h3>
            Un outil pour être à jour en permanence sur les principaux annuaires
            !
          </h3>
          <p>
            Notre{" "}
            <span className={styles.highlight}>
              outil de Presence Management
            </span>{" "}
            vous permet de gérer votre visibilité sur les différents annuaires,
            tel que Google My Business. Gérez vos avis, tenez à jour les
            informations sur vos différents points de ventes, et bien plus
            encore !
          </p>
          <p>
            Grâce à notre solution, vous allez pouvoir{" "}
            <span className={styles.highlight}>
              consolider la visibilité de vos points de ventes
            </span>{" "}
            pour assurer la venue de clients !
          </p>
        </section>
        <section>
          <CtaBanner
            textButton="Commencer"
            onClick={handleClick}
            // disabled={loading}
          >
            Découvrez notre outil de Presence Management dès maintenant !
          </CtaBanner>
        </section>
      </div>
      <Modal ref={modal}>
        <AskEstimate modal={modal} />
      </Modal>
    </>
  );
};

export default SeoLocal;
