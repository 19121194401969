import React, { useContext, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { useParams, withRouter } from "react-router-dom";

import { Arrow, Button, Modal, Tile } from "components";
import style from "./Level.module.sass";
import isModuleInCart from "../../../../utils/isModuleInCart";
import { getModulesNotDone, RNG } from "../../../../utils";
import AuditContext from "../AuditContext";
import Contact from "../../../Contact";

const Title = ({ title }) => (
  <div className={style.title}>
    <span className={style.title__text}>
      <FormattedMessage id={title} />
    </span>
  </div>
);

const Level = ({ number, title, audit, cart, match }) => {
  const productModules =
    cart[0] && cart[0].products[0] ? cart[0].products[0].productModules : [];
  const intl = useIntl();
  const { isAuditOld } = useContext(AuditContext);
  const modalContact = useRef(null);
  const { id } = useParams();

  const nbModulesNotDone = getModulesNotDone(audit.modules);
  const nbModulesInCartFromAudit = productModules.filter(
    ({ audit: { id: auditId } }) => +auditId === +match.params.id
  );
  const areAllModulesInCart =
    nbModulesInCartFromAudit.length === nbModulesNotDone.length;

  const randomContentProgress = new RNG(id);

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        {number === 0 && (
          <div className={style.container__arrow}>
            <Arrow isRed text="Boostez votre trafic !" width={85} />
          </div>
        )}
        <div className={style.container__header}>
          <Title title={title} />
          {number === 1 && (
            // React tooltip component in Results/index.jsx
            <div
              data-tip={
                areAllModulesInCart
                  ? intl.formatMessage({
                      id: "myOptimisations.results.levels.addToCart.tooltip"
                    })
                  : null
              }
              className={style.header__buttonWrapper}
            >
              {/* TODO : remettre s'il y a les prix */}
              {/* <span className={style.header__price}> */}
              {/*  /!* don't show if all modules are already in cart *!/ */}
              {/*  {!(addToCartLoading || areAllModulesInCart) && ( */}
              {/*    <> */}
              {/*      <span>Total : </span> */}
              {/*      <b> */}
              {/*        {numbro(getTotalModulesPrice() / 100).formatCurrency({ */}
              {/*          thousandSeparated: true, */}
              {/*          optionalMantissa: true, */}
              {/*          mantissa: 2 */}
              {/*        })} */}
              {/*      </b> */}
              {/*    </> */}
              {/*  )} */}
              {/* </span> */}
              <Modal ref={modalContact}>
                <Contact type="SEO" modal={modalContact} />
              </Modal>

              {/* enable for last audit only */}
              {!isAuditOld && (
                <Button
                  // disabled={addToCartLoading || areAllModulesInCart}
                  onClick={() => modalContact.current.openModal()}
                  // type="cart"
                  cta
                  id="add-to-cart-all"
                >
                  Optimiser mon site
                </Button>
              )}
            </div>
          )}
        </div>
        <div className={style.container__list}>
          {number === 1 && (
            <Tile
              tag="content"
              progress={randomContentProgress.nextRange(25, 45) / 100}
              isHighlight
              to="/content"
            />
          )}
          {_.orderBy(audit.modules, ["progress"], ["asc"]).map(
            ({ name, progress, level, id: moduleId }) => {
              if (level === number)
                return (
                  <Tile
                    key={moduleId}
                    id={moduleId}
                    tag={name}
                    progress={progress}
                    // isHighlight={getModuleFromJson(name).highlight}
                    // price={price}

                    inCart={!isAuditOld && isModuleInCart(moduleId)} // enabled for last audit only
                    to={`/audit/${match.params.id}/${moduleId}`}
                  />
                );
              return null;
            }
          )}
        </div>
      </div>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired
};

Level.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  audit: state.audit,
  cart: state.cart
});

export default withRouter(connect(mapStateToProps)(Level));
