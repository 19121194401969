import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import ReactModal from "react-modal";
import numbro from "numbro";
import numbroLanguages from "numbro/dist/languages.min";
import { Provider } from "react-redux";
import * as moment from "moment";
import localization from "moment/locale/fr";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import { StripeProvider } from "react-stripe-elements";
import ApolloClient from "./api/apollo";
import App from "./App";
import store from "./store";
import "./style/index.sass";
// set up class names on react-dates
import "react-dates/initialize";

// Error tracker
if (process.env.NODE_ENV === "production") {
  // --- SENTRY ---
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY
  });

  window.$crisp.push(["safe", true]); // disable crisp warning (because of LogRocket)

  // --- LOGROCKET ---
  LogRocket.init(process.env.REACT_APP_LOGROCKET);
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

// -----MOMENT-----
// set moment locale to french
moment.updateLocale("fr", localization);

// -----NUMBRO-----
Object.values(numbroLanguages).forEach(l => numbro.registerLanguage(l));
// set numbro to french
numbro.setLanguage("fr-FR");

ReactModal.setAppElement("#root");

ReactDOM.render(
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC}>
    <Provider store={store}>
      <ApolloProvider client={ApolloClient}>
        <App />
      </ApolloProvider>
    </Provider>
  </StripeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// import * as serviceWorker from "./serviceWorker";
// serviceWorker.unregister();
