import React from "react";
import PropTypes from "prop-types";
import style from "./GoogleButton.module.sass";
import logo from "./google_g_logo.svg";

const GoogleButton = ({ onClick, children, id }) => (
  <button type="button" onClick={onClick} className={style.button} id={id}>
    <img src={logo} alt="google g logo" />
    {children}
  </button>
);

GoogleButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  id: PropTypes.string
};

export default GoogleButton;
