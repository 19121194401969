import { toast } from "react-toastify";
import { gql } from "apollo-boost";
import apollo from "../api/apollo";

export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";
export const updateOrder = (orderId, status) => async dispatch => {
  dispatch({ type: "UPDATE_ORDER_REQUEST" });

  try {
    await apollo.mutate({
      mutation: gql`
        mutation UpdateOrder($id: ID!, $input: OrderInput!) {
          updateOrder(id: $id, input: $input)
        }
      `,
      variables: { id: +orderId, input: { status } }
    });

    return dispatch({ type: UPDATE_ORDER_SUCCESS });
  } catch (error) {
    dispatch({
      type: UPDATE_ORDER_FAILURE,
      error
    });
    throw error;
  }
};

export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAILURE = "ORDER_FAILURE";
export const order = (orderId, auditId, modules) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "ORDER_REQUEST" });

  return axios
    .post("/orders/seo", {
      orderId,
      auditId,
      modules
    })
    .then(() => dispatch({ type: ORDER_SUCCESS }))
    .catch(({ response: error }) => {
      dispatch({ type: ORDER_FAILURE, error });
      toast.error("Une erreur est survenue");
    });
};

export const ORDER_LOCAL_SEO_SUCCESS = "ORDER_LOCAL_SEO_SUCCESS";
export const ORDER_LOCAL_SEO_FAILURE = "ORDER_LOCAL_SEO_FAILURE";
export const orderLocalSEO = () => (dispatch, getState, axios) => {
  const {
    websites: {
      current: { id: websiteId }
    }
  } = getState();

  dispatch({ type: "ORDER_LOCAL_SEO_REQUEST" });

  return axios
    .post("/orders/local-seo", { websiteId })
    .then(() => {
      toast.success("Votre demande a bien été prise en compte !");
      return dispatch({ type: ORDER_LOCAL_SEO_SUCCESS });
    })
    .catch(({ response: error }) => {
      dispatch({ type: ORDER_LOCAL_SEO_FAILURE, error });
      toast.error("Une erreur est survenue");
      throw error;
    });
};

export const ORDER_SEA_SUCCESS = "ORDER_SEA_SUCCESS";
export const ORDER_SEA_FAILURE = "ORDER_SEA_FAILURE";
export const orderSEA = (budget, url, formula) => (
  dispatch,
  getState,
  axios
) => {
  const {
    websites: {
      current: { id: websiteId }
    }
  } = getState();

  dispatch({ type: "ORDER_SEA_REQUEST" });

  return axios
    .post("/orders/sea", { budget, websiteId, formula })
    .then(() => {
      toast.success("Votre demande a bien été prise en compte !");
      return dispatch({ type: ORDER_SEA_SUCCESS });
    })
    .catch(({ response: error }) => {
      dispatch({ type: ORDER_SEA_FAILURE, error });
      toast.error("Une erreur est survenue");
      throw error;
    });
};

export const ORDER_RANKING_SUCCESS = "ORDER_RANKING_SUCCESS";
export const ORDER_RANKING_FAILURE = "ORDER_RANKING_FAILURE";
export const orderRanking = rankingId => (dispatch, getState, axios) => {
  dispatch({ type: "ORDER_RANKING_REQUEST" });
  return axios
    .post("/orders/ranking", { rankingId })
    .then(() => {
      toast.success("Votre demande a bien été prise en compte !");
      return dispatch({ type: ORDER_RANKING_SUCCESS });
    })
    .catch(({ response: error }) => {
      dispatch({ type: ORDER_RANKING_FAILURE, error });
      toast.error("Une erreur est survenue");
      throw error;
    });
};

export const ORDER_CONTACT_SUCCESS = "ORDER_CONTACT_SUCCESS";
export const ORDER_CONTACT_FAILURE = "ORDER_CONTACT_FAILURE";
export const orderContact = from => (dispatch, getState, axios) => {
  dispatch({ type: "ORDER_CONTACT_REQUEST" });
  return axios
    .post("/orders/contact", { from })
    .then(() => {
      toast.success("Votre demande a bien été prise en compte !");
      return dispatch({ type: ORDER_CONTACT_SUCCESS });
    })
    .catch(({ response: error }) => {
      dispatch({ type: ORDER_CONTACT_FAILURE, error });
      toast.error("Une erreur est survenue");
      throw error;
    });
};

export const ORDER_NETLINKING_SUCCESS = "ORDER_NETLINKING_SUCCESS";
export const ORDER_NETLINKING_FAILURE = "ORDER_NETLINKING_FAILURE";
export const orderNetlinking = (numArticles, duration) => (
  dispatch,
  getState,
  axios
) => {
  const {
    websites: {
      current: { id: websiteId }
    }
  } = getState();

  dispatch({ type: "ORDER_NETLINKING_REQUEST" });
  return axios
    .post("/orders/netlinking", { websiteId, numArticles, duration })
    .then(() => dispatch({ type: ORDER_NETLINKING_SUCCESS }))
    .catch(({ response: error }) => {
      dispatch({ type: ORDER_NETLINKING_FAILURE, error });
      toast.error("Une erreur est survenue");
      throw error;
    });
};

export const ORDER_SUBSCRIPTION_SUCCESS = "ORDER_SUBSCRIPTION_SUCCESS";
export const ORDER_SUBSCRIPTION_FAILURE = "ORDER_SUBSCRIPTION_FAILURE";
export const orderSubscription = (plan, coupon = null) => (
  dispatch,
  getState,
  axios
) => {
  dispatch({ type: "ORDER_SUBSCRIPTION_REQUEST" });
  return axios
    .post("/checkout/subscription", { plan, coupon })
    .then(({ data: session }) =>
      dispatch({ type: ORDER_SUBSCRIPTION_SUCCESS, session })
    )
    .catch(({ response: error }) => {
      dispatch({ type: ORDER_SUBSCRIPTION_FAILURE, error });
      toast.error("Une erreur est survenue");
      throw error;
    });
};

export const STOP_SUBSCRIPTION_SUCCESS = "STOP_SUBSCRIPTION_SUCCESS";
export const STOP_SUBSCRIPTION_FAILURE = "STOP_SUBSCRIPTION_FAILURE";
export const stopSubscription = () => (dispatch, getState, axios) => {
  dispatch({ type: "STOP_SUBSCRIPTION_REQUEST" });
  return axios
    .delete("/checkout/subscription")
    .then(() => dispatch({ type: STOP_SUBSCRIPTION_SUCCESS }))
    .catch(({ response: error }) => {
      dispatch({ type: STOP_SUBSCRIPTION_FAILURE, error });
      toast.error("Une erreur est survenue");
      throw error;
    });
};
